import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { updateUserData } from 'redux/authSlice';
import { useLazyGetUserProfileQuery } from 'services/auth/authApi';
import { IUser } from 'services/auth/authTypes';

interface IUseFetchProfileReturnType {
  fetchProfile: () => Promise<IUser | undefined>;
  isLoading: boolean;
}

export const useFetchProfile = (): IUseFetchProfileReturnType => {
  const dispatch = useDispatch();
  const [getUserProfile, { isLoading }] = useLazyGetUserProfileQuery();

  const fetchProfile = useCallback(async (): Promise<IUser | undefined> => {
    const data = await getUserProfile({}).unwrap();
    if (data) dispatch(updateUserData(data));
    return data;
  }, [dispatch]);

  return { fetchProfile, isLoading };
};
