import { CloseOutlined } from '@ant-design/icons';
import { NiceModalHandler } from '@ebay/nice-modal-react';
import { Button, Divider, Modal, ModalProps } from 'antd';
import classNames from 'classnames';
import { useCallback, useEffect } from 'react';
import { Prompt } from 'react-router-dom';
import './nice-ant-modal.styles.less';

// USAGE
// There are two ways to manage visible state
// 1. pass `modal` as prop from useModal value. Refer `DiscountsLearnMoreModal` or `AddToQuoteModal` with custom footer
// 2. use as normal as Modal component (`visible`, `onCancel`... props)

type TNiceAntModalProps = ModalProps & {
  modal?: NiceModalHandler | null;
  disabledPrompt?: boolean;
  subTitle?: string;
  hideDivider?: boolean;
};

export const NiceAntModal: React.FC<TNiceAntModalProps> = (props) => {
  const {
    children,
    afterClose,
    onCancel,
    className,
    modal = null,
    title,
    footer,
    destroyOnClose = true,
    bodyStyle,
    style,
    subTitle,
    hideDivider,
    disabledPrompt = false,
    ...restProps
  } = props;

  useEffect(() => {
    if (modal?.visible) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [modal?.visible]);

  useEffect(
    () => () => {
      document.body.style.overflow = 'unset';
    },
    [],
  );

  const handleCancel = useCallback(
    (e: any) => {
      onCancel?.(e);
      modal?.hide();
    },
    [onCancel, modal],
  );

  const onAfterClose = useCallback(() => {
    afterClose?.();
    if (destroyOnClose) modal?.remove();
  }, [afterClose, modal, destroyOnClose]);

  return (
    <Modal
      visible={modal?.visible}
      onCancel={handleCancel}
      afterClose={onAfterClose}
      closeIcon={<Button className="bg-white" shape="circle" icon={<CloseOutlined />} size="middle" />}
      centered
      footer={null}
      className={classNames('nice-ant-modal', className)}
      style={{ maxHeight: 'calc(100vh - 30px)', ...style }}
      bodyStyle={{ overflowY: 'auto', ...bodyStyle }}
      {...restProps}
    >
      <Prompt
        when={!disabledPrompt}
        message={(_location, action) => {
          if (action === 'POP') {
            modal?.remove();
          }
          return true;
        }}
      />
      {title && (
        <>
          <h2 className="title">{title}</h2>
          {subTitle && <div className="mb-2 text-xs">{subTitle}</div>}
          {!hideDivider && <Divider className="divider" />}
        </>
      )}
      {typeof children === 'function' ? (children as any)?.({ modal }) : children}
      {footer && (
        <>
          <Divider className="divider" />
          <div className="footer">{footer}</div>
        </>
      )}
    </Modal>
  );
};
