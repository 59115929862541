import { EManufacturerSubmissionStatuses, ESpecialistSubmissionStatuses } from 'config/constants';

export const renderValue = (value: string | number | undefined) => value || '-';

interface IFormatSaveSTLs {
  url: string;
  type: string;
  name: string;
  uploadedAt: string;
  id: string;
}

export const formatSaveImage = (data: any[] = []) =>
  data.map((item: any) => ({
    url: item.url,
    type: '',
    name: item.name,
  }));

export const SPECIALIST_CASE_STATUSES: Partial<Record<ESpecialistSubmissionStatuses, { color: string; text: string }>> =
  {
    [ESpecialistSubmissionStatuses.NEW_SUBMISSION]: {
      color: '#3F69FF',
      text: 'Awaiting instructions',
    },
    [ESpecialistSubmissionStatuses.APPROVED]: {
      color: '#49DCB2',
      text: 'Approved',
    },
    [ESpecialistSubmissionStatuses.EXPIRED]: {
      color: '#8465FC',
      text: 'Expired',
    },
    [ESpecialistSubmissionStatuses.MODIFICATION_REQUESTED]: {
      color: '#00B1FF',
      text: 'Modification Requested',
    },
    [ESpecialistSubmissionStatuses.ON_HOLD]: {
      color: '#8465FC',
      text: 'On Hold',
    },
    [ESpecialistSubmissionStatuses.IN_PROGRESS]: {
      color: '#3F69FF',
      text: 'Awaiting Design',
    },
    [ESpecialistSubmissionStatuses.NOT_SUITABLE]: {
      color: '#00B1FF',
      text: 'Not Suitable',
    },
    [ESpecialistSubmissionStatuses.NOT_SUITABLE_PENDING]: {
      color: '#D0FF00',
      text: 'Not Suitable Pending',
    },
    [ESpecialistSubmissionStatuses.AWAITING_APPROVAL]: {
      color: '#49DCB2',
      text: 'Awaiting Specialist Advice',
    },
  };

export const MANUFACTURER_STATUS_MAPPING: Partial<
  Record<EManufacturerSubmissionStatuses, { color: string; text: string }>
> = {
  [EManufacturerSubmissionStatuses.IN_PROGRESS]: {
    color: '#3F69FF',
    text: 'In Progress',
  },
  [EManufacturerSubmissionStatuses.NEW_ORDER]: {
    color: '#49DCB2',
    text: 'New Order',
  },
  [EManufacturerSubmissionStatuses.SHIPPED]: {
    color: '#8465FC',
    text: 'Shipped',
  },
};

export const getClickableLink = (link: string) => {
  if (!link) {
    return '#';
  }

  const hasProtocol = link.startsWith('http://') || link.startsWith('https://');
  const href = hasProtocol ? link : `https://${link}`;

  return href;
};

export const formatSaveSTLsImage = (data: any[] = []): IFormatSaveSTLs[] =>
  data.map((item: any) => ({
    type: '',
    ...item,
    url: item.url,
    name: item.name,
  }));

export const sIfPlural = (n: number): string => (n === 1 ? '' : 's');
