import { Popover, Button, Typography } from 'antd';
import { FC, useState } from 'react';
import { DeleteOutlined } from '@ant-design/icons';

type TProps = {
  handleDelete: (item: string) => void;
  item: string;
  red?: boolean;
  size?: number;
};

export const DeleteIcon: FC<TProps> = ({ handleDelete, item, red, size }) => {
  const [hovered, setHovered] = useState(false);
  const [clicked, setClicked] = useState(false);

  const handleHoverChange = (visible: boolean) => {
    setHovered(visible);
    setClicked(false);
  };

  const handleClickChange = (visible: boolean) => {
    setHovered(false);
    setClicked(visible);
  };

  const hide = () => {
    setHovered(false);
    setClicked(false);
  };

  const confirmDelete = () => {
    hide();
    handleDelete(item);
  };

  return (
    <Popover content="Delete" trigger="hover" visible={hovered} onVisibleChange={handleHoverChange}>
      <Popover
        content={
          <div>
            {/* {clickContent} */}
            <Typography.Text className="text-sm"> Please confirm you want to delete?</Typography.Text>
            <div className="mt-2  flex">
              <Button onClick={hide}>Cancel</Button>
              <Button className="ml-2" type="primary" danger onClick={confirmDelete}>
                Delete
              </Button>
            </div>
          </div>
        }
        title="Delete"
        trigger="click"
        open={clicked}
        onOpenChange={handleClickChange}
      >
        <DeleteOutlined
          style={{ color: red ? 'red' : 'lightgrey', ...(size ? { fontSize: size } : {}), marginLeft: 10 }}
        />
      </Popover>
    </Popover>
  );
};
