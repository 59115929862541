import { FC, useEffect } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { useAuth } from 'hooks/useAuth';
import IRoute from 'interface/IRoute';
import { useFetchProfile } from 'hooks/useFetchProfile';

const PrivateRoute: FC<IRoute & RouteProps> = ({ component: Component, ...rest }) => {
  const { user } = useAuth();
  const { fetchProfile } = useFetchProfile();

  useEffect(() => {
    const fetchUserProfile = async () => {
      if (user) {
        await fetchProfile();
      }
    };
    fetchUserProfile();
  }, [user?.id]);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!user) {
          // not logged in so redirect to login page with the return url
          return <Redirect to={{ pathname: '/signin', state: { from: props.location } }} />;
        }

        // authorised so return component
        return <Component {...props} />;
      }}
    />
  );
};

export default PrivateRoute;
