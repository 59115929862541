import { useState } from 'react';
import { Modal, Input, Image, Form, Button, Skeleton, message, Typography } from 'antd';
import { EditOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { useGetUserQuery, useUpdateUserDataMutation } from 'services/user/userApi';
import { renderValue } from 'helpers';
import { checkURL } from 'utils';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { notificationApiError } from 'utils/ui';
import { TUserTypeLowerCase } from 'config/constants';
import { PermissionCheck } from 'components/permission-check/permission-check';
import { TDentistTypeItem } from 'services/user/userTypes';

const { Text } = Typography;

interface IPayload {
  fee: number;
  firstRefinementFee?: number;
  furtherRefinementFee?: number;
}

interface IFeeSectionProps {
  label: string;
  userData: TDentistTypeItem | undefined;
  currentUser: string;
  editMode: boolean;
  setEditMode: (editMode: boolean) => void;
  fieldName: keyof TDentistTypeItem;
}

const FeeSection: React.FC<IFeeSectionProps> = ({ label, userData, currentUser, editMode, setEditMode, fieldName }) => {
  const handleEditClick = () => {
    setEditMode(!editMode);
  };

  return (
    <div className="grid grid-cols-12 gap-4">
      <div className="col-span-12 md:col-span-8">
        <div className="flex flex-col">
          <Text className="text-head">{label}</Text>
          <div className="flex">
            <Text className="text-body">
              {editMode ? (
                <Form.Item initialValue={userData?.[fieldName] || 0} name={fieldName}>
                  <Input className="w-[80px]" id={`user-detail_${currentUser}-${fieldName}-input`} />
                </Form.Item>
              ) : (
                <Text className="text-body">{userData?.[fieldName] || 0}</Text>
              )}
            </Text>
            {editMode && (
              <div className="flex flex-col">
                <Button
                  type="text"
                  htmlType="submit"
                  size="small"
                  id={`user-detail_${currentUser}-edit-${fieldName}-submit-button`}
                >
                  <CheckOutlined />
                </Button>
                <Button
                  type="text"
                  onClick={handleEditClick}
                  size="small"
                  id={`user-detail_${currentUser}-edit-${fieldName}-cancel-button`}
                >
                  <CloseOutlined />
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="col-span-12 mt-6 md:col-span-4">
        <PermissionCheck
          resourceKey={userData?.role === 'DESIGNER' ? 'third_party_designer' : 'third_party_specialist'}
          actionKey="edit"
        >
          {!editMode && (
            <EditOutlined onClick={handleEditClick} id={`user-detail_${currentUser}-edit-${fieldName}-button`} />
          )}
        </PermissionCheck>
      </div>
    </div>
  );
};

export const UserDetailsModal = NiceModal.create(
  ({ userId, currentUser }: { userId: string; currentUser: TUserTypeLowerCase }) => {
    const modal = useModal();
    const [form] = Form.useForm();

    const [editMode, setEditMode] = useState(false);
    const [updateLoading, setUpdateLoading] = useState(false);

    const {
      data: userData,
      isLoading,
      isFetching,
    } = useGetUserQuery({
      userId: userId !== undefined ? userId : '',
    });

    const handleCancel = () => {
      modal.hide();
      modal.remove();
    };

    const [updateUserData] = useUpdateUserDataMutation();

    const onFinish = async (values: any) => {
      const { firstRefinementFee, furtherRefinementFee, fee } = values;

      const payload: IPayload = {
        fee: Number(fee || 0),
      };

      if (currentUser === 'specialist') {
        payload.firstRefinementFee = Number(firstRefinementFee || 0);
        payload.furtherRefinementFee = Number(furtherRefinementFee || 0);
      }

      if (currentUser === 'designer' || currentUser === 'specialist') {
        setUpdateLoading(true);
        try {
          await updateUserData({ userId, payload });
          message.success('Fee Updated');
          setEditMode(false);
        } catch (error) {
          notificationApiError(error);
        }
        setUpdateLoading(false);
      }
    };

    return (
      <Modal
        width="700px"
        open={modal.visible}
        onCancel={handleCancel}
        closeIcon={<CloseOutlined id={`user-detail_${currentUser}-close-button`} className="mt-6" />}
      >
        <Skeleton active loading={updateLoading || isLoading || isFetching}>
          <div>
            <div className="flex items-center justify-center">
              <span className="mt-4 text-xl font-medium capitalize">{currentUser?.toLowerCase()} Info</span>
            </div>

            <div className="flex gap-6 p-6">
              <div className="col-span-3 mt-6">
                <Image
                  className="rounded-full"
                  preview={false}
                  width="100px"
                  height="100px"
                  src={
                    checkURL(userData?.profilePic || '') ? userData!.profilePic : 'https://joeschmoe.io/api/v1/random'
                  }
                />
              </div>
              <div className="col">
                <div className="mt-4 grid grid-cols-12 gap-4">
                  <div className="col-span-12 md:col-span-6">
                    <div className="flex flex-col">
                      <Text className="text-head">Name</Text>
                      <Text className="text-body">{renderValue(userData?.fullName)}</Text>
                    </div>
                  </div>
                  <div className="col-span-12 md:col-span-6">
                    <div className="flex flex-col">
                      <Text className="text-head">Email</Text>
                      <Text className="text-body">{renderValue(userData?.email)}</Text>
                    </div>
                  </div>
                  <div className="col-span-12 md:col-span-6">
                    <div className="flex flex-col">
                      <Text className="text-head">Address</Text>
                      <Text className="text-body">{renderValue(userData?.business?.address?.address1)}</Text>
                    </div>
                  </div>
                  <Form className="p-d-flex col-span-12" form={form} name="register" onFinish={onFinish}>
                    <div className="grid grid-cols-12 gap-4">
                      <div className="col-span-12 md:col-span-6">
                        <FeeSection
                          label="Fee - Initial Case"
                          userData={userData}
                          currentUser={currentUser}
                          editMode={editMode}
                          setEditMode={setEditMode}
                          fieldName="fee"
                        />
                      </div>
                      {currentUser === 'specialist' && (
                        <>
                          <div className="col-span-12 ml-1 md:col-span-6">
                            <FeeSection
                              label="Fee - 1st Refinement"
                              userData={userData}
                              currentUser={currentUser}
                              editMode={editMode}
                              setEditMode={setEditMode}
                              fieldName="firstRefinementFee"
                            />
                          </div>
                          <div className="col-span-8 mt-4">
                            <FeeSection
                              label="Fee - Any further refinements"
                              userData={userData}
                              currentUser={currentUser}
                              editMode={editMode}
                              setEditMode={setEditMode}
                              fieldName="furtherRefinementFee"
                            />
                          </div>
                        </>
                      )}
                    </div>
                  </Form>

                  <div className="col-span-12">
                    <hr className="w-full" style={{ color: '#e2e2e2' }} />
                  </div>

                  <div className="col-span-12 md:col-span-6">
                    <Text className="text-sub-head">LIVE CASES</Text>
                  </div>
                  <div className="col-span-12 md:col-span-6">
                    <Text className="text-sub-head">{userData?.totalLiveSubmissions}</Text>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Skeleton>
      </Modal>
    );
  },
);
