import { duration } from 'utils/index';
import { ESubmissionStatus, ESpecialistSubmissionStatuses } from 'config/constants';
import {
  ICompletedTreatmentHowItWent,
  TDiagnosis,
  TTreatment,
} from 'services/completed-treatment/completedTreatmentTypes';

export const getStatusName = (status?: string): string => {
  const STATUS_MAP: { [key: string]: string } = {
    all: 'All',
    [ESubmissionStatus.ARCHIVED]: 'Archived',
    [ESubmissionStatus.DRAFT]: 'Draft',
    [ESubmissionStatus.AWAITING_FILES]: 'Awaiting Files',
    [ESubmissionStatus.SUBMITTED]: 'New Cases',
    [ESubmissionStatus.AWAITING_DESIGNER_ACCEPTANCE]: 'Awaiting Designer Acceptance',
    [ESubmissionStatus.AWAITING_STL_VERIFICATION]: 'Awaiting STL Verification',
    [ESubmissionStatus.AWAITING_SPECIALIST_ACCEPTANCE]: 'Awaiting Specialist Acceptance',
    [ESubmissionStatus.AWAITING_INSTRUCTION]: 'Awaiting Instruction',
    [ESubmissionStatus.ON_HOLD]: 'On Hold',
    [ESubmissionStatus.NOT_SUITABLE_PENDING]: 'Not Suitable Pending',
    [ESubmissionStatus.AWAITING_DESIGN]: 'Awaiting Design',
    [ESubmissionStatus.AWAITING_ADVICE]: 'Awaiting Advice',
    [ESubmissionStatus.REVIEW_DESIGN]: 'Review Design',
    [ESubmissionStatus.AWAITING_DENTIST_APPROVAL]: 'Awaiting Dentist Approval',
    [ESubmissionStatus.APPROVED]: 'Approved',
    [ESubmissionStatus.REVIEW_STL_FILES]: 'Review STL Files',
    [ESubmissionStatus.WITH_MANUFACTURER]: 'With Manufacturer',
    [ESubmissionStatus.WITH_32CO]: 'With 32Co',
    [ESubmissionStatus.IN_TREATMENT]: 'In Treatment',
    [ESubmissionStatus.COMPLETED]: 'Completed',
    [ESubmissionStatus.NOT_SUITABLE]: 'Not Suitable',
    [ESubmissionStatus.EXPIRED]: 'Expired',
  };

  return status ? STATUS_MAP[status] || '' : '';
};

export const getSpecialistStatusName = (status?: string): string => {
  const STATUS_MAP: { [key: string]: string } = {
    [ESpecialistSubmissionStatuses.ON_HOLD]: 'On Hold',
    [ESpecialistSubmissionStatuses.APPROVED]: 'Approved',
    [ESpecialistSubmissionStatuses.NEW_SUBMISSION]: 'Awaiting Instructions',
    [ESpecialistSubmissionStatuses.AWAITING_APPROVAL]: 'Awaiting Specialist Advice',
  };

  return status ? STATUS_MAP[status] || '' : '';
};

export const CASE_STATUS_COLOR_MAP: any = {
  [ESubmissionStatus.SUBMITTED]: 'green',
  [ESubmissionStatus.DRAFT]: 'orange',
  [ESubmissionStatus.AWAITING_DESIGNER_ACCEPTANCE]: 'geekblue',
  [ESubmissionStatus.AWAITING_SPECIALIST_ACCEPTANCE]: 'geekblue',
  [ESubmissionStatus.AWAITING_DENTIST_APPROVAL]: 'cyan',
  [ESubmissionStatus.REVIEW_DESIGN]: 'purple',
  [ESubmissionStatus.AWAITING_DESIGN]: 'gray',
  [ESubmissionStatus.APPROVED]: 'blue',
  [ESubmissionStatus.WITH_MANUFACTURER]: 'yellow',
  [ESubmissionStatus.WITH_32CO]: 'red',
  [ESubmissionStatus.NOT_SUITABLE]: 'gold',
  [ESubmissionStatus.NOT_SUITABLE_PENDING]: 'gold',
  [ESubmissionStatus.EXPIRED]: 'red',
  [ESubmissionStatus.AWAITING_FILES]: 'pink',
  [ESubmissionStatus.ARCHIVED]: 'lime',
  [ESubmissionStatus.COMPLETED]: 'magenta',
  [ESubmissionStatus.AWAITING_STL_VERIFICATION]: 'red',
};

export const SUBMISSION_STATUS_MAPPING: Record<string, { bgColor: string; text: string }> = {
  [ESubmissionStatus.DRAFT]: {
    bgColor: '#9e9e9e',
    text: 'Draft Submission',
  },
  [ESubmissionStatus.SUBMITTED]: {
    bgColor: '#004bff',
    text: 'Submitted',
  },
  [ESubmissionStatus.REVIEW_DESIGN]: {
    bgColor: '#3F69FF',
    text: 'Awaiting Approval',
  },
  [ESubmissionStatus.APPROVED]: {
    bgColor: '#49DCB2',
    text: 'Approved',
  },
  [ESubmissionStatus.IN_TREATMENT]: {
    bgColor: '#06b73c',
    text: 'In Treatment',
  },
  [ESubmissionStatus.COMPLETED]: {
    bgColor: 'black',
    text: 'Completed',
  },
  [ESubmissionStatus.EXPIRED]: {
    bgColor: '#8465FC',
    text: 'Expired',
  },
  [ESubmissionStatus.NOT_SUITABLE]: {
    bgColor: '#00B1FF',
    text: 'Not Suitable',
  },
  [ESubmissionStatus.ARCHIVED]: {
    bgColor: '#f4a125',
    text: 'Archived',
  },
  ON_HOLD: {
    bgColor: '#8465FC',
    text: 'On Hold',
  },
  AWAITING_INSTRUCTIONS: {
    bgColor: '#9e9e9e',
    text: 'Awaiting Instructions',
  },
  [ESubmissionStatus.WITH_32CO]: {
    bgColor: 'red',
    text: 'With 32Co',
  },
};

export const SPECIALIST_CASE_STATUS_COLOR_MAP: any = {
  onHold: 'orangered',
  approved: 'lightseagreen',
  newCase: 'chocolate',
  waitingApproval: 'rosybrown',
};

export const dateToDuration = (date: string) => {
  const d: any = duration(date);

  if (d.days) return `${d.days} day${d.days > 1 ? 's' : ''} ago`;
  if (d.hours) return `${d.hours} hour${d.hours > 1 ? 's' : ''} ago`;
  if (d.minutes) return `${d.minutes} min${d.minutes > 1 ? 's' : ''} ago`;
  if (d.seconds) return `${d.seconds} secs ago`;

  return '';
};

/**
 * Return plural unit
 * @param {number} value - number - The number to be used to determine the singular or plural form of
 * the unit.
 * @param {string} unit - The singular form of the unit.
 * @param {string} units - The plural form of the unit
 */
export const getPlural = (value: number, unit: string, units: string): string => `${value} ${value > 1 ? units : unit}`;

/**
 * Return `xx days xx hours` format
 * @param {number} dayCount - number - The number of days to format
 * @param {number} hourCount - number - The number of hours to format
 */
export const formatToDaysHours = (dayCount: number, hourCount: number): string =>
  `${dayCount > 0 ? `${getPlural(dayCount, 'day', 'days')}` : ''}${
    hourCount > 0 ? ` ${getPlural(hourCount, 'hour', 'hours')}` : ''
  }`;

/**
 * "Convert hours to days and hours"
 * @param {number} hours - number - the number of hours to convert
 * @returns A string in `DD days HH hours` format
 */
export const convertHoursToDayHours = (hours: number): string => {
  const dayCount = Math.floor(hours / 24);
  const hourCount = hours % 24;

  return formatToDaysHours(dayCount, hourCount);
};

/**
 * "Convert days to days and hours"
 * e.g 10.2 => 10 days 5 hours
 * The function takes a number of days as an argument and returns a string
 * @param {number} days - number - the number of days to convert
 * @returns A string
 */
export const convertDaysToDayHour = (days: number): string => {
  const dayCount = parseInt(days.toString(), 10);
  const hourCount = Math.round((days % 1) * 24);

  return formatToDaysHours(dayCount, hourCount);
};

export const CASE_STUDY_TAG_COLOR_MAPPING: Record<string, string> = {
  diagnosisCrowding: '#1890FF',
  diagnosisOcclusion: '#1890FF',
  diagnosisOther: '#1890FF',
  diagnosisRotations: '#1890FF',
  treatmentArches: '#722ED1',
  treatmentCosmetic: '#722ED1',
  treatmentOrthodontic: '#722ED1',
  result: '#039F61',
  totalStages: '#EB2F96',
  treatmentLength: '#EB2F96',
  totalRefinements: '#EB2F96',
  material: '#555770',
  trimLine: '#555770',
};

type TParentMapping = { label: string; value: keyof TTreatment | keyof TDiagnosis }[];

export const mapResponseToCascaderValue = (
  parentMapping: TParentMapping,
  data?: Pick<
    ICompletedTreatmentHowItWent,
    | 'diagnosisCrowding'
    | 'diagnosisOcclusion'
    | 'diagnosisOther'
    | 'diagnosisRotations'
    | 'treatmentArches'
    | 'treatmentCosmetic'
    | 'treatmentOrthodontic'
  >,
): [string, string][] =>
  parentMapping
    .map(({ value }) => {
      const diagnosis = data?.[value] as string[];
      if (Array.isArray(diagnosis)) {
        return diagnosis.map((diag) => (diag ? [value, diag] : undefined)).filter(Boolean);
      }
      return undefined;
    })
    .filter(Boolean)
    .flat() as [string, string][];

export const getCascaderFiltersValue = (
  parentMapping: TParentMapping,
  values: [string, string][],
): Record<string, string[]> =>
  parentMapping.reduce<Record<string, string[]>>((acc, datum) => {
    const key = datum.value;
    const value = values.filter(([k]) => k === key).map(([, v]) => v);

    acc[key] = value;

    return acc;
  }, {});
