import { useState } from 'react';
import { Modal, Form, Input, Button, notification } from 'antd';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { notificationApiError } from 'utils/ui';
import {
  useResetPasswordCoAdminMutation,
  useResetPasswordProfileCoAdminMutation,
} from 'services/co-admin/co-admin-api';
import { SigninFormItem } from 'pages/siginin/components/signin-form-item/signin-form-item';
import { PasswordField } from 'components/password-field/password-field';
import { useAuth } from 'hooks/useAuth';

interface IResetModal {
  visible: boolean;
  roleId: string;
  isProfileResetPassword?: boolean;
}

export const ResetPasswordModal = NiceModal.create<IResetModal>(({ roleId, visible, isProfileResetPassword }) => {
  const [form] = Form.useForm();
  const modal = useModal();
  const { userLogout } = useAuth();
  const [loading, setLoading] = useState(false);
  const [resetPasswordCoAdmin] = useResetPasswordCoAdminMutation();
  const [resetProfilePassword] = useResetPasswordProfileCoAdminMutation();

  const handleCancel = () => {
    modal.resolve(false);
    modal.hide();
    modal.remove();
  };

  const handleOk = () => {
    form.validateFields().then(async (values) => {
      setLoading(true);
      const { password, currentPassword } = values;

      const payload = {
        password,
      };
      try {
        if (isProfileResetPassword) {
          const reqBody = {
            currentPassword,
            newPassword: password,
          };
          await resetProfilePassword({ id: roleId, payload: reqBody }).unwrap();
        } else {
          await resetPasswordCoAdmin({ id: roleId, payload }).unwrap();
        }
        notification.success({
          message: 'Password reset',
          description: 'Password updated successfully!',
          placement: 'topRight',
        });
        await userLogout();
      } catch (e: any) {
        if (e?.data?.error === 'Bad Request') {
          notification.error({ message: 'Incorrect password!' });
        } else {
          notificationApiError(e);
        }
      }
      setTimeout(() => {
        setLoading(false);
        form.resetFields();
        handleCancel();
      }, 2000);
    });
  };

  return (
    <Modal
      open={visible}
      title="Reset Password"
      onCancel={handleCancel}
      footer={[
        <Button key="cancel" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button key="reset" type="primary" loading={loading} onClick={handleOk}>
          Reset
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical">
        {isProfileResetPassword && (
          <Form.Item
            name="currentPassword"
            label="Current Password"
            rules={[
              { required: isProfileResetPassword, message: 'Please enter current password' },
              { min: 6, message: 'Password must be at least 6 characters' },
            ]}
          >
            <Input.Password placeholder="Enter current password" />
          </Form.Item>
        )}
        <PasswordField
          form={form}
          placeholder={isProfileResetPassword ? 'Enter new password' : 'Enter password'}
          isSigninField
          className="mb-5"
          name="password"
        />
        <SigninFormItem
          form={form}
          formName="confirmPassword"
          className="mb-5"
          label="Confirm Password"
          formDependencies={['password']}
          formRules={[
            {
              required: true,
              message: 'Please input confirm password',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('The two passwords that you entered do not match!'));
              },
            }),
          ]}
        >
          <Input.Password />
        </SigninFormItem>
      </Form>
    </Modal>
  );
});
