import { api, VERSION } from 'services/api';
import { ILoginRequest, IUser, IValidateTwoFactorRequest, IResendOTPRequest, ITwoFactorResponse } from './authTypes';

export const authApi = api.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<ITwoFactorResponse, ILoginRequest>({
      query: (credentials) => ({
        url: `/${VERSION}/admin/auth/signin`,
        method: 'POST',
        body: credentials,
      }),
    }),
    validateTwoFactor: builder.mutation<IUser, IValidateTwoFactorRequest>({
      query: (credentials) => ({
        url: `/${VERSION}/admin/auth/otp`,
        method: 'POST',
        body: credentials,
      }),
    }),
    resendOtp: builder.mutation<ITwoFactorResponse, IResendOTPRequest>({
      query: (credentials) => ({
        url: `/${VERSION}/admin/auth/otp-resend`,
        method: 'POST',
        body: credentials,
      }),
    }),
    getUserProfile: builder.query<IUser, any>({
      query: () => `/${VERSION}/admin/auth/profile`,
    }),
    signOut: builder.mutation<any, void>({
      query: () => ({
        url: `/${VERSION}/admin/auth/signout`,
        method: 'POST',
      }),
    }),
    resetPassword: builder.mutation<void, { resetToken: string; password: string }>({
      query: (credentials: any) => ({
        url: `/${VERSION}/admin/auth/password/reset`,
        method: 'POST',
        body: credentials,
      }),
    }),
    verifyResetPasswordToken: builder.mutation<void, { resetToken: string }>({
      query: (credentials: any) => ({
        url: `/${VERSION}/admin/auth/password/reset/verify`,
        method: 'POST',
        body: credentials,
      }),
    }),
    forgotPassword: builder.mutation<void, any>({
      query: (credentials: any) => ({
        url: `/${VERSION}/admin/auth/password/reset/request`,
        method: 'POST',
        body: credentials,
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useValidateTwoFactorMutation,
  useResendOtpMutation,
  useLazyGetUserProfileQuery,
  useSignOutMutation,
  useResetPasswordMutation,
  useVerifyResetPasswordTokenMutation,
  useForgotPasswordMutation,
} = authApi;
