import { IPracticeType, IScanner } from 'interface/practices';
import { buildQueryString } from 'services/utils';
import { api, VERSION } from 'services/api';
import { IListRequest, IAssignPracticeRequest } from './practicesTypes';

const PRACTICE_API = `/${VERSION}/admin/practices`;

export const practicesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    listPractices: builder.query<{ docs: IPracticeType[]; total: number }, IListRequest>({
      query: (params) => {
        const paramsString = buildQueryString(params);

        return `${PRACTICE_API}?${paramsString}`;
      },
      providesTags: (result, _error, _page) =>
        result
          ? [
              ...result.docs.map(({ id }: any) => ({ type: 'Practices' as const, id, key: id })),

              { type: 'Practices', id: 'PARTIAL-LIST' },
            ]
          : [{ type: 'Practices', id: 'PARTIAL-LIST' }],
    }),
    assignPractice: builder.mutation<void, IAssignPracticeRequest>({
      query(payload) {
        return {
          url: `${PRACTICE_API}/assign`,
          method: 'POST',
          body: payload,
        };
      },
    }),
    createPractice: builder.mutation<any, { body: Omit<IPracticeType, 'createdAt' | 'updatedAt' | 'id'> }>({
      query({ body }) {
        return {
          url: PRACTICE_API,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: [{ type: 'Practices', id: 'PARTIAL-LIST' }],
    }),
    updatePractice: builder.mutation<any, { id: string; body: Partial<IPracticeType> }>({
      query({ id, body }) {
        return {
          url: `${PRACTICE_API}/${id}`,
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: (_result, _error, params) => [{ type: 'Practices', id: params.id }],
    }),
    deletePractice: builder.mutation<any, { id: string }>({
      query({ id }) {
        return {
          url: `${PRACTICE_API}/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: (_result, _error, params) => [{ type: 'Practices', id: params.id }],
    }),
    getPracticeById: builder.query<IPracticeType, { id: string }>({
      query: ({ id }) => `${PRACTICE_API}/${id}`,
      providesTags: (_result, _error, params) => [{ type: 'Practices', id: params.id }],
    }),
    getScanners: builder.query<{ label: string; value: string }[], any>({
      query: () => `${PRACTICE_API}/scanners`,
      transformResponse: (data: IScanner[]) => data.map((datum) => ({ label: datum.name, value: datum.id })),
    }),
  }),
});

export const {
  useListPracticesQuery,
  useLazyListPracticesQuery,
  useAssignPracticeMutation,
  useCreatePracticeMutation,
  useUpdatePracticeMutation,
  useGetPracticeByIdQuery,
  useGetScannersQuery,
  useDeletePracticeMutation,
} = practicesApi;
