import { Avatar, Image, Spin } from 'antd';
import classNames from 'classnames';
import { FC, useState } from 'react';

interface IProps {
  url?: string;
  name?: string;
  size?: number;
}

export const NiceAvatar: FC<IProps> = ({ url, name, size = 50 }) => {
  const [isImageFetched, setIsImageFetched] = useState(false);

  return url ? (
    <div className="relative">
      <Image
        src={url}
        width={size}
        height={size}
        className={classNames('rounded-full object-cover', isImageFetched ? 'visible' : 'hidden')}
        onLoad={() => setIsImageFetched(true)}
      />
      {!isImageFetched && <Spin size="small" className="absolute left-1/4 top-1/4" />}
    </div>
  ) : (
    <Avatar size={size} className="bg-[#87d068] uppercase">
      {name?.charAt(0)}
    </Avatar>
  );
};
