// refer to https://en.wikipedia.org/wiki/List_of_mobile_telephone_prefixes_by_country
const NUMBER_VALID_LENGTH_MAP: { [key in string]: number } = {
  '+44': 10, // UK
  '+91': 10, // India
  '+1': 10, // USA
  '+84': 9, // Vietnam
};

const PHONE_NUMER_MIN_LENGTH = 4;

export interface IMobileNumberInputValue {
  ext?: string;
  number?: string | number;
}

export const requireMobileNumber = (value?: IMobileNumberInputValue) => {
  const selectedRegion: string | undefined = value?.ext;
  const phoneNumber: string | undefined = value?.number !== undefined ? value.number?.toString() : undefined;

  if (!selectedRegion || !phoneNumber) {
    return Promise.reject(new Error('We need your phone number in case we need to reach you'));
  }

  return Promise.resolve();
};

export const validMobileNumber = (value?: IMobileNumberInputValue) => {
  const selectedRegion: string | undefined = value?.ext;
  const phoneNumber: string | undefined = value?.number === undefined ? undefined : value.number?.toString();

  if (!!selectedRegion && !!phoneNumber) {
    const numberValidLength = NUMBER_VALID_LENGTH_MAP[selectedRegion!] || PHONE_NUMER_MIN_LENGTH;
    if (phoneNumber.length < numberValidLength) {
      return Promise.reject(new Error('We need your full phone number in case we need to reach you'));
    }
  }

  return Promise.resolve();
};
