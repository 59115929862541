import { useCallback } from 'react';
import { useGetMetaOptionsQuery } from 'services/api';

const optionByIdCache: Record<string, { id: string; value: string }> = {};

export enum EFormName {
  treatmentDesign = 'treatmentDesign',
  discount = 'discount',
  advice = 'advice',
  duo = 'duo',
  solo = 'solo',
  refinementDuo = 'refinementDuo',
  stlIssuesReport = 'stlIssuesReport',
  dentistRegistration = 'dentistRegistration',
  replacement = 'replacement',
  retainer = 'retainer',
  caseStudy = 'caseStudy',
  completeTreatment = 'completeTreatment',
}

export const useMetaOption = (formKey: EFormName) => {
  const { data, isLoading, refetch } = useGetMetaOptionsQuery(formKey);

  const getOptions = useCallback(
    (optionKey: string) => data?.find((item) => item.fieldKey === optionKey)?.options,
    [data],
  );

  const getOptionById = useCallback(
    (optionKey: string, id: string) => {
      if (!optionByIdCache[id]) optionByIdCache[id] = getOptions(optionKey)?.find((item) => item.id === id)!;
      return optionByIdCache[id];
    },
    [data],
  );

  return {
    data,
    getOptions,
    getOptionById,
    isLoading,
    refetch,
  };
};
