import React, { Suspense } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { Loading } from 'components/loading';
import { PermissionCheck } from 'components/permission-check/permission-check';

const LazyManufacturerList = React.lazy(() => import('./manufacturer-list/manufacturer-list'));
const LazyManufacturerSkuList = React.lazy(() => import('./manufacturer-sku-list/manufacturer-sku-list'));
const LazySkuDetails = React.lazy(() => import('./sku-details/sku-details'));

export const Manufacturer = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <PermissionCheck resourceKey="third_party_manufacturer" actionKey="read" restrictionType="restrictionMessage">
          <Suspense fallback={<Loading />}>
            <LazyManufacturerList />
          </Suspense>
        </PermissionCheck>
      </Route>

      <Route exact path={`${path}/:manufacturerId`}>
        <PermissionCheck
          resourceKey="third_party_manufacturer_sku"
          actionKey="read"
          restrictionType="restrictionMessage"
        >
          <Suspense fallback={<Loading />}>
            <LazyManufacturerSkuList />
          </Suspense>
        </PermissionCheck>
      </Route>

      <Route exact path={`${path}/:manufacturerId/sku`}>
        <PermissionCheck
          resourceKey="third_party_manufacturer_sku"
          actionKey="create"
          restrictionType="restrictionMessage"
        >
          <Suspense fallback={<Loading />}>
            <LazySkuDetails />
          </Suspense>
        </PermissionCheck>
      </Route>

      <Route exact path={`${path}/:manufacturerId/sku/:skuId`}>
        <PermissionCheck
          resourceKey="third_party_manufacturer_sku"
          actionKey="read"
          restrictionType="restrictionMessage"
        >
          <Suspense fallback={<Loading />}>
            <LazySkuDetails />
          </Suspense>
        </PermissionCheck>
      </Route>
    </Switch>
  );
};
