/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TCountryObject } from 'services/apiTypes';

type TAppState = {
  countries?: TCountryObject[];
};

const appSlice = createSlice({
  name: 'app',
  initialState: { countries: undefined } as TAppState,
  reducers: {
    setCountries: (state, { payload }: PayloadAction<TCountryObject[]>) => {
      state.countries = payload;
    },
  },
});

export const { setCountries } = appSlice.actions;
export default appSlice.reducer;
