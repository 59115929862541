import { FC, useEffect, useRef } from 'react';
import { Accordion } from 'components/accordion/accordion';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { DownloadProgressItem } from './download-progress-item/download-progress-item';
import { Button, Tooltip } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { updateDisplay } from 'redux/downloadProgressSlice';

export const DownloadProgress: FC = () => {
  const listRef = useRef<HTMLDivElement>(null);
  const progressList = useAppSelector((state) => state.downloadProgress.progressList);
  const dispatch = useAppDispatch();

  const onClose = () => {
    dispatch(updateDisplay(false));
  };

  useEffect(() => {
    listRef?.current?.scrollTo({ top: -1000, behavior: 'smooth' });
  }, [progressList.length]);

  return (
    <div className="download-stls fixed bottom-0 z-[100] w-80">
      <Accordion title="Preparing Download" open>
        <div ref={listRef} className="mb-3 flex max-h-[150px] flex-col-reverse overflow-auto">
          {progressList.map((item: { downloadCode: string; backUrl: string; payload: any }) => (
            <DownloadProgressItem
              key={item.downloadCode}
              downloadCode={item.downloadCode}
              backUrl={item.backUrl}
              payload={item.payload}
            />
          ))}
        </div>
      </Accordion>
      <div className="absolute right-2 top-4">
        <Tooltip title="Close">
          <Button
            onMouseEnter={(event) => event.stopPropagation()}
            onClick={onClose}
            type="text"
            className="h-[24px] p-1 pt-[2px]"
          >
            <CloseOutlined className="text-sm" />
          </Button>
        </Tooltip>
      </div>
    </div>
  );
};
