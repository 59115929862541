import { useMemo } from 'react';
import { useAuth } from './useAuth';
import { IRoleOption, TPermissionResourceKey } from 'pages/users/utils';
import { IAllowPermissions } from 'services/auth/authTypes';

interface IProps {
  resourceKey: TPermissionResourceKey;
  actionKeys: (keyof IRoleOption)[];
  userWhiteList?: string[];
}

export const useAllowPermission = ({ resourceKey, actionKeys, userWhiteList }: IProps): boolean[] => {
  const { user, isSuperAdmin } = useAuth();

  return useMemo(() => {
    if (isSuperAdmin) {
      return actionKeys.map(() => true);
    }

    if (userWhiteList && !userWhiteList.includes(user?.id || '')) {
      return [];
    }

    let actionList: string[] = [];

    if (user?.permissions?.allow) {
      const allowedPermissions = user.permissions.allow as ('admin' | IAllowPermissions)[];

      const filteredPermissions = allowedPermissions.filter(
        (item: any) => typeof item !== 'string' && item.resource === resourceKey,
      );

      if (filteredPermissions.length > 0) {
        const permission: any = filteredPermissions[0];
        if (Array.isArray(permission?.actions)) {
          actionList = permission.actions;
        }
      }
    }

    const returnValues = actionKeys.map((actionKey) => actionList.includes(actionKey));
    return returnValues;
  }, [user?.permissions?.allow, resourceKey, actionKeys, userWhiteList]);
};
