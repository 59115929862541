import { DownOutlined, EyeOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Button, Col, Collapse, Image, message, Modal, Row, Typography, Upload, Popover, Tooltip } from 'antd';
import { FC, useState } from 'react';
import { ReactComponent as UploadSVG } from 'assets/images/Upload.svg';
import { formatSaveImage } from 'helpers';
import { useGetSignedUrlMutation } from 'services/api';
import { useUpdateSubmissionMutation } from 'services/operations/operationsApi';
import { uploadFile } from 'services/s3-api/endpoints';
import { beforeFileUploadImg } from 'utils';
import { DeleteIcon } from '../delete/DeleteIcon';
import { UploadStlModal } from 'pages/operations/components/upload-stl-modal/upload-stl-modal';
import NiceModal from '@ebay/nice-modal-react';
import './accordion.styles.less';
import { ESubmissionStatus } from 'config/constants';
import { PermissionCheck } from 'components/permission-check/permission-check';
import classNames from 'classnames';

const { Panel } = Collapse;
const { Dragger } = Upload;

interface IAccordion {
  children: any;
  title: any;
  extra?: boolean;
  open?: boolean;
  submissionInfo?: any;
  objectKey?: any;
  fetchCase?: any;
  isDisableStlAddMore?: boolean;
}

export const Accordion: FC<IAccordion> = ({
  children,
  title,
  extra,
  open,
  submissionInfo,
  objectKey,
  fetchCase,
  isDisableStlAddMore,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [isShowImgOptions, setIsShowImgOptions] = useState(false);
  const [visible, setShowPreviewImage] = useState(false);
  const [initialValues, setInitialValues] = useState<any>([]);
  const [currentPreview, setCurrentPreviewImage] = useState<any>(0);
  const [showSaveBtn, setShowSaveBtn] = useState(false);
  const [getSignedUrl] = useGetSignedUrlMutation();
  const [updatePatient, { isLoading }] = useUpdateSubmissionMutation();

  const showUploadModal = () => {
    if (submissionInfo) {
      NiceModal.show(UploadStlModal, {
        refetch: fetchCase,
        submissionInfo,
      });
    }
  };

  const addMoreFiles = (event: any) => {
    event.stopPropagation();
    if (objectKey === 'imprImages') {
      showUploadModal();
    } else {
      if (submissionInfo) setInitialValues(submissionInfo[objectKey]);
      setShowModal(true);
    }
  };

  const addMoreFilesWithStatusUpdate = (event: any) => {
    event.stopPropagation();
    showUploadModal();
  };

  const onDrop = () => {
    // console.log('Dropped files', e.dataTransfer.files);
  };

  const handleFileChange = (info: any) => {
    const { status, response, uid } = info.file;

    if (status !== 'uploading') {
      // console.log(info.file, info.fileList);
    }

    if (status === 'done') {
      const { imageUrl } = response;

      const copyPrevVal = [...initialValues];

      const updateFileStatus = copyPrevVal.map((item: any) => ({
        ...item,
        ...(item.uid === uid
          ? {
              status: 'done',
              url: imageUrl,
            }
          : {}),
      }));

      message.success(`${info.file.name} file uploaded successfully.`);
      setInitialValues(updateFileStatus);
    } else if (status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  const uploadImageRequest = async ({ file, onSuccess, filename }: any) => {
    const { name, uid, type } = file;
    const newImage = {
      uid,
      name,
      filename,
      url: null,
      status: 'uploading',
    };
    if (type !== '') setInitialValues([...initialValues, newImage]);
    // if (objectKey !== 'imprImages' && type === '') {
    //   setInitialValues([]);
    // }
    if (file) {
      setShowSaveBtn(true);
      try {
        const fileData = {
          fileName: name,
          mimeType: objectKey === 'imprImages' ? 'application/sla' : type,
          folderName: filename,
        };
        if (fileData?.mimeType !== '') {
          const signedUrl = await getSignedUrl(fileData).unwrap();
          const options: any = {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          };
          if (signedUrl?.url) {
            await uploadFile(signedUrl?.url, file, options).then((_res: any) => {
              setShowSaveBtn(false);
            });
          }

          const imageUrl = signedUrl && signedUrl?.url?.split('?')[0];
          onSuccess({ imageUrl, filename });
        }
      } catch (e) {
        throw new Error(`Upload profile pic error: ${e}`);
      }
    }
  };

  const saveUploadedImages = async () => {
    if (objectKey) {
      const { id } = submissionInfo;

      const updateData: any = {
        [objectKey]: formatSaveImage([...initialValues]),
      };
      await updatePatient({ id, payload: updateData }).unwrap();
      fetchCase();
      setShowModal(false);
      setInitialValues([]);
    }
  };

  const onCancel = () => {
    setShowModal(false);
    setInitialValues([]);
  };

  const genExtra = () => (
    <div className="z-5 mr-6">
      <PermissionCheck resourceKey="operation_submission" actionKey="edit">
        <Tooltip title={isDisableStlAddMore ? '🚫Upload files in the report' : ''}>
          <button
            className={classNames('add-more-btn', isDisableStlAddMore ? '!cursor-not-allowed opacity-50' : '')}
            type="button"
            onClick={(e) => (isDisableStlAddMore ? null : addMoreFiles(e))}
          >
            <span className="mr-2">Add more</span>
            <PlusCircleOutlined />
          </button>
        </Tooltip>
      </PermissionCheck>
    </div>
  );

  const genExtra2 = () => (
    <PermissionCheck resourceKey="operation_submission" actionKey="edit">
      <div className="z-5 mr-6" onClick={(e) => addMoreFilesWithStatusUpdate(e)}>
        <button className="add-more-btn" type="button">
          <span className="mr-2">Add more</span>
          <PlusCircleOutlined />
        </button>
      </div>
    </PermissionCheck>
  );

  const previewImage = (index: number) => {
    setCurrentPreviewImage(index);
    setShowPreviewImage(true);
  };

  const deleteImage = (url: string) => {
    const filterImages = initialValues.filter((item: any) => item.url !== url);
    setInitialValues(filterImages);
  };

  let res: any = 0;
  if (objectKey !== 'imprImages' && extra) {
    res = 1;
  } else if (objectKey === 'imprImages' && extra && submissionInfo?.status === ESubmissionStatus.AWAITING_FILES) {
    res = 2;
  } else if (objectKey === 'imprImages' && extra && submissionInfo?.status !== ESubmissionStatus.AWAITING_FILES) {
    res = 3;
  } else res = 4;

  const expandIcon = ({ isActive }: { isActive?: boolean }) => (
    <Button
      className="collapse-btn flex items-center justify-center"
      shape="circle"
      size="small"
      icon={<DownOutlined className="!text-xs" rotate={isActive ? 180 : 0} />}
    />
  );

  return (
    <div className="custom-accordion custom-shadow">
      <Collapse expandIconPosition="end" defaultActiveKey={open ? ['1'] : []} expandIcon={expandIcon}>
        <Panel
          header={title}
          extra={res === 1 ? genExtra() : res === 2 ? genExtra2() : res === 3 ? genExtra() : ''}
          key="1"
        >
          {children}
        </Panel>
      </Collapse>
      {/* TODO: let move all [Modal] to [NiceAntModal] refer: https://frontierco.atlassian.net/browse/FRON-4828 */}
      <Modal width={500} centered open={showModal} onCancel={onCancel} footer={null} maskClosable={false} closable>
        <>
          <div className="mb-6 flex justify-center">
            <Typography.Title level={4} className="m-0">
              Upload {title}
            </Typography.Title>
          </div>
          <div className="flex justify-center">
            <Dragger
              name="images"
              multiple
              showUploadList={false}
              beforeUpload={beforeFileUploadImg}
              customRequest={uploadImageRequest}
              onDrop={onDrop}
              onChange={handleFileChange}
            >
              <div className="flex p-2">
                <div className="ml-2 mr-4">
                  <p className="ant-upload-drag-icon m-0">
                    <UploadSVG />
                  </p>
                </div>
                <div className="flex items-center">
                  <p className="upload-text text-left">Click or drag file to this area to upload</p>
                </div>
              </div>
            </Dragger>
          </div>
          {objectKey !== 'imprImages' && (
            <div className="uploaded-image-container hide-preview-title mt-4">
              <div className="preview-container">
                <Row gutter={16}>
                  {initialValues.map((item: any, index: number) => {
                    const { url, status, uid } = item;
                    return (
                      <Col className="gutter-row" span={12} key={uid || url}>
                        {status && status !== 'done' ? (
                          <div
                            className="mb-2 flex min-w-full items-center justify-center"
                            style={{ minHeight: 140, backgroundColor: '#f5f5f5' }}
                          >
                            <Typography.Text>Uploading... </Typography.Text>
                          </div>
                        ) : (
                          <div
                            className="small-image-container mb-2 w-full cursor-pointer"
                            style={{ minHeight: 140 }}
                            onMouseOver={() => setIsShowImgOptions(!isShowImgOptions)}
                            onMouseLeave={() => setIsShowImgOptions(false)}
                            onFocus={() => undefined}
                            onBlur={() => undefined}
                          >
                            <img alt="" src={url} className="h-full" />
                            <div className="overlay">
                              <div className="image-backdrop" />
                              <div className="image-preview-btn flex h-full w-full flex-row items-center justify-center">
                                <Popover content="Preview">
                                  <EyeOutlined
                                    style={{ color: 'lightgrey', fontSize: 26 }}
                                    onClick={() => previewImage(index)}
                                  />
                                </Popover>
                                <DeleteIcon handleDelete={() => deleteImage(url)} item={item} size={20} />
                              </div>
                            </div>
                          </div>
                        )}
                      </Col>
                    );
                  })}
                </Row>
                <div style={{ display: 'none' }}>
                  <Image.PreviewGroup
                    preview={{ visible, onVisibleChange: (vis) => setShowPreviewImage(vis), current: currentPreview }}
                  >
                    {initialValues.map(({ url }: any) => (
                      <Image key={url} src={url} />
                    ))}
                  </Image.PreviewGroup>
                </div>
              </div>
            </div>
          )}
          <div className="mt-12 flex justify-center">
            <Button className="mr-4" style={{ minWidth: 160 }} onClick={onCancel}>
              Cancel
            </Button>
            <Button
              type="primary"
              style={{ minWidth: 160 }}
              loading={isLoading}
              disabled={showSaveBtn || initialValues.find((item: any) => !item.url)}
              onClick={saveUploadedImages}
            >
              Save
            </Button>
          </div>
        </>
      </Modal>
    </div>
  );
};
