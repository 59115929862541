/* eslint-disable no-param-reassign */
import { Button, message, Typography, Upload } from 'antd';
import { useEffect, useState } from 'react';
import { ReactComponent as UploadSVG } from 'assets/images/Upload.svg';
import { useUploadStlFilesMutation } from 'services/operations/operationsApi';
import { FileUploadItem } from './file-upload-item/file-upload-item';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { checkIsStlFileSizeValid } from 'components/accordion/utils';
import { FileSizeWarnPopUp } from 'components/accordion/file-size-warn-popup';
import { ISubmission, IAttachmentFile } from 'interface/operations';
import { NiceAntModal } from 'components/nice-components/nice-ant-modal/nice-ant-modal';

const { Dragger } = Upload;
const { Text } = Typography;

interface IUploadStlModalProps {
  refetch: any;
  submissionInfo: ISubmission;
}

export const UploadStlModal = NiceModal.create<IUploadStlModalProps>(({ refetch, submissionInfo }) => {
  const [uploadStlFile, { isLoading: isUploadUpdating }] = useUploadStlFilesMutation();
  const modal = useModal();

  const submissionId: string = submissionInfo?.id !== '' ? submissionInfo?.id : '';
  const imprData: IAttachmentFile[] =
    submissionInfo?.imprImages && submissionInfo?.imprImages.length > 0 ? submissionInfo?.imprImages : [];

  const [uploadSTLFileList, setUploadSTLFileList] = useState<IAttachmentFile[]>(imprData?.length > 0 ? imprData : []);
  const areAllFilesUploaded = uploadSTLFileList.every((file: any) => file.url);

  useEffect(() => {
    if (uploadSTLFileList.filter((file: any) => !file.url).length > 0) return; // uploading not finish yet

    const filesLessThan100kbToCheck = uploadSTLFileList
      // Ignore the files without size, because they are from BE
      .filter((file: any) => file.size > 0)
      .filter((file: any) => !checkIsStlFileSizeValid(file.size) && !file.sizeChecked);

    if (filesLessThan100kbToCheck.length) {
      NiceModal.show(FileSizeWarnPopUp, {
        onDelete: () => {
          setUploadSTLFileList((files: any) => files.filter((file: any) => !filesLessThan100kbToCheck.includes(file)));
        },
        onContinue: () => {
          filesLessThan100kbToCheck.forEach((file: any) => {
            file.sizeChecked = true;
          });
        },
        files: filesLessThan100kbToCheck,
      });
    }
  }, [uploadSTLFileList]);

  const closeSTLModal = () => {
    modal.remove();
    refetch();
  };

  const uploadSTLprops = {
    name: 'stls',
    multiple: true,
    accept: '.stl',
    onChange(info: any) {
      const fileNameSplitArray = typeof info?.file?.name === 'string' ? info?.file.name?.split('.') : [];
      if (!fileNameSplitArray.length || fileNameSplitArray[fileNameSplitArray.length - 1] !== 'stl') return;
      setUploadSTLFileList(info?.fileList);
    },
    onDrop(_e: any) {
      // console.log('Dropped files', e.dataTransfer.files);
    },
  };

  const stlRequest = async ({ onSuccess }: any) => {
    onSuccess('ok');
  };

  const beforeProfileUpload = (file: any) => {
    const fileNameSplitArray = file?.name?.split('.');
    const fileType = fileNameSplitArray?.[fileNameSplitArray.length - 1];
    if (fileType !== 'stl') {
      message.warn('Upload only .stl files');
      return false;
    }

    return true;
  };

  const confirmSTLFileUpload = async () => {
    if (submissionId) {
      try {
        const STLFileUrls = uploadSTLFileList?.map((file: IAttachmentFile) => ({
          url: file.url,
          name: file.name,
          type: 'application/sla',
          id: file.id,
          isArchived: file.isArchived ?? false,
          ...(file.isDentistDownloadable !== undefined ? { isDentistDownloadable: file.isDentistDownloadable } : {}),
          ...(file.uploadedAt ? { uploadedAt: file.uploadedAt } : {}),
          ...(file.createdAt ? { createdAt: file.createdAt } : {}),
        }));

        await uploadStlFile({ payload: { imprImages: STLFileUrls }, submissionId }).unwrap();
        message.success('Stl files uploaded successfully');

        closeSTLModal();
      } catch (error: any) {
        message.error(
          `Something isn't quite right, this is usually because not all mandatory fields are completed. If this keeps happening chat to us and we'll help!`,
        );
      }
    }
  };

  return (
    <NiceAntModal modal={modal} centered title={uploadSTLFileList?.length === 0 ? `Please Upload one STL files` : ''}>
      <div className="border-0 border-t border-solid border-gray-100 pt-1">
        <div className="my-6">
          <Dragger
            {...uploadSTLprops}
            fileList={uploadSTLFileList as any}
            customRequest={stlRequest}
            beforeUpload={beforeProfileUpload}
            className="custom-shadow border-none !px-12 !py-6"
            showUploadList={false}
          >
            <div className="flex items-center justify-start">
              <UploadSVG />
              <Text className="ml-4">Click or drag file to this area to upload</Text>
            </div>
          </Dragger>
        </div>
        <div className="mb-4 w-full">
          {uploadSTLFileList.map((file: any) => (
            <div key={file.uid}>
              <FileUploadItem
                file={file}
                remove={(fileToRemove: any) => {
                  const latestFileList = [...uploadSTLFileList];
                  setUploadSTLFileList(latestFileList?.filter((f: any) => f?.url !== fileToRemove?.url));
                }}
                setUrl={(selectedFile: any, url: string, fileUploadedAt: string) => {
                  const latestFileList = [...uploadSTLFileList];
                  setUploadSTLFileList(
                    latestFileList?.map((f: any) => {
                      const updatedFile = f;
                      if (updatedFile?.uid === selectedFile?.uid) {
                        updatedFile.url = url;
                        updatedFile.uploadedAt = fileUploadedAt;
                      }
                      return updatedFile;
                    }),
                  );
                }}
              />
            </div>
          ))}
        </div>
        <div className="flex flex-row justify-between">
          <Button
            className="custom-shadow mr-4 w-1/2 rounded-standard uppercase"
            size="large"
            type="default"
            style={{ minWidth: 200 }}
            onClick={modal.remove}
          >
            Cancel
          </Button>
          <Button
            className="custom-shadow w-1/2 rounded-standard uppercase"
            size="large"
            type="primary"
            style={{ minWidth: 200 }}
            onClick={confirmSTLFileUpload}
            loading={isUploadUpdating}
            disabled={!areAllFilesUploaded || !uploadSTLFileList.length}
          >
            Confirm
          </Button>
        </div>
      </div>
    </NiceAntModal>
  );
});
