/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EDiscountStatus } from 'config/constants';
import type { TRootState } from './store';

type TDiscountsState = {
  page: number | undefined;
  perPage: number | undefined;
  status: EDiscountStatus;
  searchStr: string;
};

const slice = createSlice({
  name: 'discounts',
  initialState: { page: 1, perPage: 10, searchStr: '', status: EDiscountStatus.ACTIVE } as TDiscountsState,
  reducers: {
    setPginationData: (
      state,
      { payload: { page, perPage } }: PayloadAction<{ page: number | undefined; perPage: number | undefined }>,
    ) => {
      state.page = page;
      state.perPage = perPage;
    },
    setSearchStr: (state, { payload: { search } }: PayloadAction<{ search: string }>) => {
      state.page = 1;
      state.searchStr = search;
    },
    setStatus: (state, { payload: { status } }: PayloadAction<{ status: EDiscountStatus }>) => {
      state.page = 1;
      state.status = status;
    },
    setInitialParams: (state, { payload }: PayloadAction<TDiscountsState>) => {
      if (payload.page) state.page = payload.page;
      if (payload.perPage) state.perPage = payload.perPage;
      if (payload.status) {
        state.status = payload.status;
      }
      if (payload.searchStr) {
        state.searchStr = payload.searchStr;
      }
    },
  },
});

export const { setPginationData, setSearchStr, setStatus, setInitialParams } = slice.actions;

export default slice.reducer;

export const selectCurrentPage = (state: TRootState) => state.discounts.page;
export const selectPageSize = (state: TRootState) => state.discounts.perPage;
export const selectSearchStr = (state: TRootState) => state.discounts.searchStr;
export const selectStatus = (state: TRootState) => state.discounts.status;
