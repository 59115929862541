import './file-upload-item.styles.less';
import { CheckCircleFilled } from '@ant-design/icons';
import { Popover, Progress } from 'antd';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useGetSignedUrlMutation } from 'services/api';
import { DeleteIcon } from '../../../../../components/delete/DeleteIcon';
import { formatDate } from 'utils';
import { LinkDownload } from 'components/commons/link-download/link-download';

export const FileUploadItem = ({ file, remove, setUrl }: { file: any; remove: any; setUrl?: Function }) => {
  const [percent, setPercent] = useState(0);
  const [getSignedUrl] = useGetSignedUrlMutation();
  const { name } = file;

  useEffect(() => {
    const uploadFile = async () => {
      if (file && !file?.url) {
        try {
          const fileData = {
            fileName: name,
            mimeType: 'application/sla',
            folderName: 'stls',
          };
          const signedUrl = await getSignedUrl(fileData).unwrap();

          const options = {
            onUploadProgress: (progressEvent: ProgressEvent) => {
              const { loaded, total } = progressEvent;
              const p = Math.floor((loaded * 100) / total);
              setPercent(p);
            },
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          };

          axios
            .put(signedUrl?.url, file.originFileObj ? file.originFileObj : file, options)
            .then((_res) => {
              const imageUrl = signedUrl?.url?.split('?')[0];
              setUrl?.(file, imageUrl, signedUrl.uploadedAt);
            })
            .catch((error: Error) => {
              console.log('error', error);
            });
        } catch (e) {
          throw new Error(`Upload stl file error: ${e}`);
        }
      }
    };

    uploadFile();
  }, []);

  return (
    <div className="align-delete-btn mb-2 flex w-full items-center justify-between">
      <div className="upload-file-name format flex flex-nowrap items-center">
        <div className="pr-3">
          {percent === 100 || file.url ? (
            <CheckCircleFilled style={{ color: '#00C878', fontSize: 20 }} />
          ) : (
            <Progress
              strokeColor="#00C878"
              strokeWidth={10}
              type="circle"
              status="success"
              percent={percent}
              width={20}
              showInfo={false}
            />
          )}
        </div>
        <div className="max-w-[90%]">
          <Popover content={name}>
            <div className="format ">{name}</div>
          </Popover>
          {file.createdAt && (
            <div>
              <span className="text-gray-400">Uploaded At:</span>
              <span className="text-blue-500"> {formatDate(file.createdAt)}</span>
            </div>
          )}
        </div>
      </div>
      <div className="flex items-center">
        {(percent === 100 || file.url) && <LinkDownload name={file.name || 'file'} url={file.url} isIcon />}
        <DeleteIcon handleDelete={remove} item={file} red />
      </div>
    </div>
  );
};
