import { FC } from 'react';
import { Space, Tooltip, Popover, Badge } from 'antd';
import moment from 'moment';
import {
  CheckCircleOutlined,
  FieldNumberOutlined,
  InfoOutlined,
  InfoCircleOutlined,
  ExclamationOutlined,
} from '@ant-design/icons';
import { PriorityColumn } from '../components/priority-column';
import { ArchiveSubmissionButton } from '../components/archive-submission-button';
import ColumnUserList from 'components/table-list/list2';
import { MANUFACTURER_STATUS_MAPPING, SPECIALIST_CASE_STATUSES } from 'helpers';
import { NotificationsColumn } from '../components/notification-column';
import { Link } from 'react-router-dom';
import {
  getStatusName,
  dateToDuration,
  convertHoursToDayHours,
  convertDaysToDayHour,
  SUBMISSION_STATUS_MAPPING,
} from '../utils';
import NiceModal from '@ebay/nice-modal-react';
import { UserDetailsModal } from '../../third-parties/components/third-party-user-list/user-details-modal/user-details-modal';
import { ISubmission } from 'interface/operations';

import {
  ESubmissionStatus,
  ESpecialistSubmissionStatuses,
  EDesignerSubmissionStatuses,
  EManufacturerSubmissionStatuses,
} from 'config/constants';
import styled from 'styled-components';
import { SortOrder } from 'antd/lib/table/interface';
import { PracticeForm } from 'pages/practices/components/practice-form';
import { PermissionCheck } from 'components/permission-check/permission-check';
import classNames from 'classnames';
import { NiceAvatar } from 'components/nice-components/nice-avatar/nice-avatar';
import { ITableColumnPropertiesRaw } from 'utils/table';

export const COMMON_SORTING: { sorter: boolean; sortDirections: SortOrder[] } = {
  sorter: true,
  sortDirections: ['descend', 'ascend'],
};

const requireAttentionTooltipTitle = (
  <div className="text-black-alpha-90 mt-2 w-full text-sm">
    <div className="mb-2 ml-2 font-bold">
      <InfoCircleOutlined className="vertical-align-text-bottom mr-1 text-lg" /> Cases Require attention if:
    </div>
    <ol>
      <li>Dentists submits a case and admin has not assigned a designer within 24 hrs</li>
      <li>Designer accepts a case and does not submit a 1st design within 24 hrs</li>
      <li>Specialist has requested a modification and designer has not submitted the design within 24 hrs</li>
      <li>Specialist accepted the case and did not submit first treatment advice within 4 days</li>
      <li>
        Admin received treatment advice from specialist and has not sent the proposal to the dentists within 24 hrs
      </li>
      <li>Dentist has approved the case and designer has not uploaded remaining STL files within 24 hrs</li>
      <li>Dentist has uploaded STL files and admin did not send the STL files to manufacturer with next 24 hrs</li>
      <li>Manufacturer has STL files and has not shipped the aligners within 3 days</li>
    </ol>
  </div>
);

const requireAttentionTitle = (
  <span>
    <Popover
      content={requireAttentionTooltipTitle}
      color="#fff"
      style={{ pointerEvents: 'none', zIndex: 999 }}
      overlayInnerStyle={{ width: 600, pointerEvents: 'none', zIndex: 999 }}
    >
      <InfoOutlined
        style={{ backgroundColor: 'gray', borderRadius: '50%', color: 'white', padding: 3, marginLeft: 10 }}
      />
    </Popover>
  </span>
);

export interface IGetColumnsProps {
  handleAssignClick: (record: ISubmission) => void;
  handleAwaitingFilesStlClick: (record: ISubmission) => void;
}

export const priorityColumn: ITableColumnPropertiesRaw = {
  title: 'Priority',
  dataIndex: 'isPriority',
  fixed: 'left',
  width: 80,
  render(_: void, record: ISubmission) {
    if (record.status === ESubmissionStatus.EXPIRED) {
      return null;
    }

    return <PriorityColumn record={record} />;
  },
  sortable: true,
};

export const requireAttentionColumn: ITableColumnPropertiesRaw = {
  title: requireAttentionTitle,
  titleString: 'Require Attention',
  dataIndex: 'requireAttention',
  fixed: 'left',
  width: 80,
  render: (requireAttention: boolean) =>
    requireAttention === true ? (
      <div className="text-center">
        <CheckCircleOutlined style={{ fontSize: 24, backgroundColor: 'green', borderRadius: '50%', color: 'white' }} />
      </div>
    ) : (
      ''
    ),
};

export const statusTimeColumn: ITableColumnPropertiesRaw = {
  title: 'Status Time',
  dataIndex: 'statusTime',
  width: 120,
  render: (caseStatusTrackingStatusTime: string) => (
    <span className="flex items-center justify-center" style={{ color: '#EB0C19' }}>
      {caseStatusTrackingStatusTime ? moment(caseStatusTrackingStatusTime).format('DD/MM/YYYY') : ''}
    </span>
  ),
  sortable: true,
};

export const daysSinceSubmittedColumn: ITableColumnPropertiesRaw = {
  title: 'Day since submitted',
  dataIndex: 'daysSinceSubmitted',
  width: 176,
  render: (date?: string) => (
    <span className="flex" style={{ color: '#EB0C19' }}>
      {date ?? '-'}
    </span>
  ),
  sortable: true,
};

export const ModificationIndicatorStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14px;
  height: 14px;
  background-color: red;
  border-radius: 50%;
  color: white;
  font-size: 0.75em;
`;

type TPatientColumnItemProps = {
  text: string;
  children: React.ReactNode;
  submission: ISubmission;
  targetParams?: string;
};

export const PatientColumnItem: FC<TPatientColumnItemProps> = ({ text, submission, children, targetParams = '' }) => {
  const BadgeIndicator = [ESubmissionStatus.REVIEW_DESIGN, ESubmissionStatus.REVIEW_STL_FILES].includes(
    submission.status,
  ) ? (
    <ExclamationOutlined style={{ backgroundColor: 'yellow', borderRadius: '50%', color: 'white' }} />
  ) : submission?.latestTreatmentDesignStatus === ESpecialistSubmissionStatuses.MODIFICATION_REQUESTED ||
    submission?.designerStatus === EDesignerSubmissionStatuses.REVISION_REQUESTED ? (
    <ModificationIndicatorStyled>R</ModificationIndicatorStyled>
  ) : null;

  return (
    <PermissionCheck resourceKey="operation_submission" actionKey="read" restrictionType="unActionable">
      <Link to={`/operations/${submission.id}${targetParams}`}>
        <div className="flex h-12 cursor-pointer items-center">
          <div className="mr-2">
            <Badge count={BadgeIndicator}>
              <NiceAvatar name={text} size={32} />
            </Badge>
          </div>
          <div className="format">
            <div className="format mt-1 capitalize underline">
              <Tooltip placement="topLeft" title={text || 'Empty'}>
                {text ? children : 'Empty'}
              </Tooltip>
            </div>
          </div>
        </div>
      </Link>
    </PermissionCheck>
  );
};

export const patientColumn: ITableColumnPropertiesRaw = {
  title: 'Patient',
  dataIndex: ['patient', 'fullName'],
  fixed: 'left',
  ellipsis: true,
  sortable: true,
  searchable: true,
  customSearchRender: ({ text, record, children }) => (
    <PatientColumnItem text={text} submission={record}>
      {children}
    </PatientColumnItem>
  ),
};

export const serviceColumn: ITableColumnPropertiesRaw = {
  title: 'Service',
  dataIndex: 'serviceType',
  width: 100,
  ellipsis: true,
  sortable: true,
  searchable: true,
  customSearchRender: ({ children }) => <div className="capitalize">{children}</div>,
};

export const typeColumn: ITableColumnPropertiesRaw = {
  title: 'Type',
  dataIndex: 'planType',
  ellipsis: true,
  width: 100,
  sortable: true,
  searchable: true,
  customSearchRender: ({ children }) => <div className="capitalize">{children}</div>,
};

export const dentistColumn: ITableColumnPropertiesRaw = {
  title: 'Dentist',
  dataIndex: ['createdBy', 'fullName'],
  ellipsis: true,
  sortable: true,
  width: 200,
  searchable: true,
  customSearchRender: ({ text, record, children }) => (
    <Link to={`/dentists/${record.createdBy?.id}`} target="_blank" className="underline">
      <div className="flex flex-col">
        <div className="flex cursor-pointer capitalize text-primaryColor">
          <Tooltip title={text}>
            <div className="format" style={!record?.createdBy?.isPlacedOrder ? { maxWidth: '9rem' } : undefined}>
              {children}
            </div>
          </Tooltip>
          {!record?.createdBy?.isPlacedOrder && (
            <Tooltip placement="topLeft" title="Not Ordered" className="ml-1 cursor-auto">
              <FieldNumberOutlined
                style={{ backgroundColor: 'gray', borderRadius: '50%', color: 'white', padding: 3 }}
              />
            </Tooltip>
          )}
        </div>
      </div>
    </Link>
  ),
};

export const specialistColumn: ITableColumnPropertiesRaw = {
  title: 'Specialist',
  dataIndex: ['specialist', 'fullName'],
  ellipsis: true,
  searchable: true,
  customSearchRender: ({ text, record, children }) => (
    <PermissionCheck resourceKey="third_party_specialist" actionKey="read" restrictionType="unActionable">
      <Link to={`/specialist/${record.specialist?.id}`} className="underline">
        <Tooltip title={text}>{children || ''}</Tooltip>
      </Link>
    </PermissionCheck>
  ),
  sortable: true,
};

export const manufacturerColumn: ITableColumnPropertiesRaw = {
  title: 'Manufacturer',
  dataIndex: ['manufacturer', 'fullName'],
  searchable: true,
  customSearchRender: ({ text, record, children }) => (
    <Tooltip title={text}>
      <Link to={`/manufacturer/${record.manufacturer?.id}`} className="underline">
        <div className="format cursor-pointer capitalize text-primaryColor">{children}</div>
      </Link>
    </Tooltip>
  ),
  ellipsis: true,
  sortable: true,
};

export const designColumn: ITableColumnPropertiesRaw = {
  title: 'Designer',
  dataIndex: ['designer', 'fullName'],
  ellipsis: true,
  searchable: true,
  customSearchRender: ({ text, record, children }) => (
    <PermissionCheck resourceKey="third_party_designer" actionKey="read" restrictionType="unActionable">
      <div
        className="format cursor-pointer capitalize text-primaryColor underline"
        onClick={() =>
          record.designer?.id
            ? NiceModal.show(UserDetailsModal, {
                userId: record.designer.id,
                currentUser: 'designer',
              })
            : null
        }
      >
        <Tooltip title={text}>{children}</Tooltip>
      </div>
    </PermissionCheck>
  ),
  sortable: true,
};

export const notificationsColumn: ITableColumnPropertiesRaw = {
  title: 'Notifications',
  dataIndex: 'notifications',
  fixed: 'right',
  width: 120,
  ellipsis: true,
  render: (txt: string, record: ISubmission) => <NotificationsColumn record={record} />,
};

export const specialistStatusColumn: ITableColumnPropertiesRaw = {
  title: 'Specialist status',
  dataIndex: 'specialistStatus',
  render: (specialistStatus: ESpecialistSubmissionStatuses) =>
    specialistStatus && (
      <ColumnUserList
        user={SPECIALIST_CASE_STATUSES[specialistStatus]?.text}
        color={SPECIALIST_CASE_STATUSES[specialistStatus]?.color}
        textColor="white"
      />
    ),
  ellipsis: true,
  width: 230,
  sortable: true,
};

export const dateShipmentColumn: ITableColumnPropertiesRaw = {
  title: 'Date shipment',
  dataIndex: ['order', 'shippingLinkUploadedAt'],
  width: 130,
  ellipsis: true,
  // TODO: https://github.com/32S-Dental/admin/pull/880#discussion_r1251917251
  render: (shippingLinkUploadedAt: any) => (
    <div>{shippingLinkUploadedAt ? moment(shippingLinkUploadedAt).format('DD/MM/YYYY') : '-'}</div>
  ),
  sortable: true,
};

export const manufacturerDateShipmentColumn: ITableColumnPropertiesRaw = {
  title: 'Manufacturer date shipment',
  dataIndex: ['order', 'manufacturerShippingLinkUploadedAt'],
  width: 130,
  ellipsis: true,
  sortable: true,
  render: (date: string) => <div>{date ? moment(date).format('DD/MM/YYYY') : '-'}</div>,
};

export const manufacturerShippingLinkColumn: ITableColumnPropertiesRaw = {
  title: 'Manufacturer shipping link',
  dataIndex: ['order', 'manufacturerShippingUrl'],
  width: 200,
  // TODO: https://github.com/32S-Dental/admin/pull/880#discussion_r1251916261
  render: (manufacturerShippingUrl: any) => (
    <div style={{ color: '#0673c9' }}>
      {manufacturerShippingUrl ? (
        <a target="_blank" rel="noreferrer" href={manufacturerShippingUrl}>
          {manufacturerShippingUrl}
        </a>
      ) : (
        '-'
      )}
    </div>
  ),
  sortable: true,
};

export const daysSinceShippedColumn: ITableColumnPropertiesRaw = {
  title: 'Days since shipped',
  dataIndex: ['order', 'manufacturerShippingLinkUploadedAt'],
  ellipsis: true,
  width: 180,
  render: (manufacturerShippingLinkUploadedAt: string) => (
    <span className="flex items-center justify-center">
      {manufacturerShippingLinkUploadedAt ? moment().diff(moment(manufacturerShippingLinkUploadedAt), 'days') : '-'}
    </span>
  ),
  sortable: true,
};

export const shipping32SLinkColumn: ITableColumnPropertiesRaw = {
  title: '32Co shipping link',
  dataIndex: ['order', 'shippingLink'],
  ellipsis: true,
  width: 180,
  render: (shippingLink: any) => <div style={{ color: '#0673c9' }}>{shippingLink || '-'}</div>,
  sortable: true,
};

export const dateImpressionSentColumn: ITableColumnPropertiesRaw = {
  title: 'Date Impression Sent',
  dataIndex: 'postageDate',
  width: 200,
  ellipsis: true,
  render: (
    postageDate: string, // TODO: p1 check when change date --> postageDate, BE this sortable?
  ) => (
    <span
      className="flex items-center justify-center"
      style={{ color: moment().diff(moment(postageDate), 'days') >= 6 ? '#EB0C19' : '#000' }}
    >
      {postageDate ? moment(postageDate).format('DD/MM/YYYY') : '-'}
    </span>
  ),
  sortable: true,
};

export const dateImpressionSubmittedColumn: ITableColumnPropertiesRaw = {
  title: 'Date Submitted',
  dataIndex: 'submittedAt',
  width: 200,
  ellipsis: true,
  render: (date: string) => (
    <span
      className="flex items-center justify-center"
      style={{ color: moment().diff(moment(date), 'hours') >= 24 ? '#EB0C19' : '#000' }}
    >
      {date ? moment(date).format('DD/MM/YYYY') : '-'}
    </span>
  ),
  sortable: true,
};

export const practiceColumn: ITableColumnPropertiesRaw = {
  title: 'Practice',
  dataIndex: ['practice', 'name'],
  sortable: true,
  searchable: true,
  customSearchRender: ({ text, record, children }) => (
    <div
      className="format cursor-pointer capitalize text-primaryColor underline"
      onClick={() => NiceModal.show(PracticeForm, { practiceId: record.practice?.id, disabled: true })}
    >
      <Tooltip title={text}>{children}</Tooltip>
    </div>
  ),
};

export const getAdminStatusColumn = ({
  handleAssignClick,
  handleAwaitingFilesStlClick,
}: IGetColumnsProps): ITableColumnPropertiesRaw => ({
  title: 'Admin Status',
  dataIndex: 'status',
  width: 300,
  sortable: true,
  render: (status: string, record: ISubmission) => {
    if (
      status === ESubmissionStatus.AWAITING_FILES ||
      (status === ESubmissionStatus.SUBMITTED && !(record.imprImages && record.imprImages.length > 0))
    )
      return (
        <PermissionCheck resourceKey="operation_submission" actionKey="edit" restrictionType="unActionable">
          <div
            onClick={() => handleAwaitingFilesStlClick(record)}
            className="flex cursor-pointer items-center justify-center"
            style={{
              minHeight: '2rem',
              color: 'white',
              backgroundColor: record?.impressionScannerType ? '#6557E9' : 'black',
              borderRadius: '2rem',
              paddingLeft: 10,
              paddingRight: 10,
            }}
          >
            {`${
              record?.stlUploadType === 'Send via scanner'
                ? `${record?.impressionScannerType} - `
                : record?.impressionType?.includes('Silicone Impressions')
                ? 'Silicone - '
                : ''
            }`}{' '}
            Upload STLs {record?.stlUploadType ? `- ${record?.stlUploadType}` : ''}
          </div>
        </PermissionCheck>
      );

    if (status === ESubmissionStatus.SUBMITTED)
      return (
        <PermissionCheck resourceKey="operation" actionKey="edit" restrictionType="unActionable">
          <div
            onClick={() => handleAssignClick(record)}
            className="flex cursor-pointer items-center justify-center px-2"
            style={{
              height: '2rem',
              color: 'white',
              backgroundColor: '#004bff',
              borderRadius: '2rem',
            }}
          >
            {record?.isReAssign ? 'RE-ASSIGN' : 'ASSIGN'}
          </div>
        </PermissionCheck>
      );

    const statusName: string = getStatusName(status);
    return (
      <ColumnUserList
        user={statusName}
        color={SUBMISSION_STATUS_MAPPING[status]?.bgColor || '#9e9e9e'}
        textColor="white"
      />
    );
  },
});

export const delayDetailsColumn: ITableColumnPropertiesRaw = {
  title: 'Delay Details',
  dataIndex: 'delay',
  fixed: 'right',
  width: 120,
  render: (delay: string) => <span>{delay}</span>,
  sortable: true,
};

export const utilsColumn: ITableColumnPropertiesRaw = {
  title: 'Archive',
  dataIndex: '',
  fixed: 'right',
  width: 80,
  render(_: void, record: ISubmission) {
    return (
      <Space direction="vertical">
        <ArchiveSubmissionButton submissionId={record.id} />
      </Space>
    );
  },
};

export const dateSubmittedColumn: ITableColumnPropertiesRaw = {
  title: 'Date Submitted',
  dataIndex: 'submittedAt',
  render: (submittedAt: string, record: ISubmission) => (
    <div>
      {submittedAt
        ? moment(submittedAt).format('DD/MM/YYYY')
        : record?.stlUploadType === 'Send via scanner'
        ? 'N/A'
        : 'No date Selected'}
    </div>
  ),
  ellipsis: true,
  width: 150,
  sortable: true,
};

export const archiveReasonColumn: ITableColumnPropertiesRaw = {
  title: 'Archive Reason',
  dataIndex: 'archiveReason',
  fixed: 'right',
  width: 180,
  render(_: any, record: ISubmission) {
    return (
      <div className="format capitalize">
        <Tooltip placement="topLeft" title={record.archiveReason}>
          {record.archiveReason}
        </Tooltip>
      </div>
    );
  },
  sortable: true,
};

export const submittedTimeColumn: ITableColumnPropertiesRaw = {
  title: 'Date submitted',
  dataIndex: 'submittedAt',
  render: (date: string) => (
    <span className="flex items-center justify-center">{date ? moment(date).format('DD/MM/YYYY') : '-'}</span>
  ),
  width: 160,
  sortable: true,
};

export const adviceWithDentistColumn: ITableColumnPropertiesRaw = {
  title: 'Advice with dentist',
  dataIndex: 'adviceCount',
  sortable: true,
  render: (adviceCount: number) => <span className="flex items-center justify-center">{adviceCount}</span>,
};

export const expiredInDaysColumn: ITableColumnPropertiesRaw = {
  title: 'Days to Expiry',
  dataIndex: 'expiredInDays',
  width: 180,
  render: (expiredInDays: number) => (
    <span className="flex items-center justify-center" style={{ color: `${expiredInDays <= 20 ? '#EB0C19' : ''}` }}>
      {expiredInDays}
    </span>
  ),
  sortable: true,
};

export const dateAssignedWithDentistColumn: ITableColumnPropertiesRaw = {
  title: 'Date assigned',
  dataIndex: 'assignedAt',
  render: (assignedAt: string) => (
    <span className="flex items-center justify-center">
      {assignedAt ? moment(assignedAt).format('DD/MM/YYYY') : '-'}
    </span>
  ),
  width: 120,
  sortable: true,
};

export const dateFirstAdviceSubmittedColumn: ITableColumnPropertiesRaw = {
  title: 'Date advice submitted',
  dataIndex: 'firstAdviceAt',
  render: (firstAdviceAt: string) => (
    <span className="flex items-center justify-center">
      {firstAdviceAt ? moment(firstAdviceAt).format('DD/MM/YYYY') : '-'}
    </span>
  ),
  width: 120,
  sortable: true,
};

export const dueAtColumn: ITableColumnPropertiesRaw = {
  title: 'Overdue',
  dataIndex: 'dueAt',
  render: (dueAt: string) => (
    <span className="flex items-center justify-center" style={{ color: '#EB0C19' }}>
      {dueAt ? moment(dueAt).format('DD/MM/YYYY HH:mm') : ''}
    </span>
  ),
  width: 120,
};

export const firstAssignedColumn: ITableColumnPropertiesRaw = {
  title: 'First Assigned',
  dataIndex: 'firstAssigned',
  render: (firstAssigned: string) => (
    <span className="flex items-center justify-center">{firstAssigned ? dateToDuration(firstAssigned) : ''}</span>
  ),
  width: 120,
};

export const timeSinceApprovedColumn: ITableColumnPropertiesRaw = {
  title: 'Time Since Approved',
  dataIndex: 'timeSinceApproved',
  render: (timeSinceApproved: string) => (
    <span className="flex items-center justify-center">{timeSinceApproved || '-'}</span>
  ),
  width: 176,
  sortable: true,
};

export const dateAssignedColumn: ITableColumnPropertiesRaw = {
  title: 'Date assigned',
  dataIndex: 'assignedAt',
  sortable: true,
  render: (_date: string, record: any) => (
    <span className="flex items-center justify-center">
      {record?.withSpecialistAt ? moment(record?.withSpecialistAt).format('DD/MM/YYYY') : ''}
    </span>
  ),
};

export const timeSinceAssignedColumn: ITableColumnPropertiesRaw = {
  title: 'Time Since Assigned',
  dataIndex: 'assignedAt',
  width: 176,
  render: (date?: string) => (
    <span
      className="flex items-center"
      style={{ color: moment().diff(moment(date), 'hours') > 24 ? '#EB0C19' : '#000' }}
    >
      {date ? dateToDuration(date) : '-'}
    </span>
  ),
  sortable: true,
};

export const inprogressTimeSinceAssignedColumn: ITableColumnPropertiesRaw = {
  title: 'Time since assigned',
  dataIndex: 'inprogressTimeAssigned',
  render: (inprogressTimeAssigned?: string) => {
    const diffHours = inprogressTimeAssigned ? moment().diff(moment(inprogressTimeAssigned), 'hours') : undefined;
    return (
      <span
        className="flex items-center justify-center"
        style={{ color: diffHours && diffHours > 24 ? '#EB0C19' : '' }}
      >
        {diffHours ? convertHoursToDayHours(diffHours) : '-'}
      </span>
    );
  },
  width: 120,
  sortable: true,
};

export const onHoldReasonColumn: ITableColumnPropertiesRaw = {
  title: 'On-Hold reason',
  dataIndex: ['onHold', 'reason'],
  sortable: true,
  // TODO: https://github.com/32S-Dental/admin/pull/880#discussion_r1251915226
  render: (onHoldReason: string) =>
    onHoldReason && (
      <div className="format">
        <Tooltip placement="topLeft" title={onHoldReason}>
          {onHoldReason}
        </Tooltip>
      </div>
    ),
};

export const onHoldTimeForOnHoldStatusColumn: ITableColumnPropertiesRaw = {
  title: 'On-hold time',
  dataIndex: ['onHold', 'createdAt'],
  // TODO: https://github.com/32S-Dental/admin/pull/880#discussion_r1251899164
  sortable: true,
  render: (onHoldCreatedAt: any) => (
    <span className="flex items-center justify-center">{onHoldCreatedAt ? dateToDuration(onHoldCreatedAt) : '-'}</span>
  ),
};

export const onHoldTimeColumn: ITableColumnPropertiesRaw = {
  title: 'On-hold time',
  dataIndex: 'onHoldDays',
  sortable: true,
  render: (onHoldDays: number) => (
    <span className="flex items-center justify-center">{onHoldDays ? convertDaysToDayHour(onHoldDays) : '-'}</span>
  ),
};

export const dateMaterialsReceivedColumn: ITableColumnPropertiesRaw = {
  title: 'Date materials received',
  dataIndex: ['order', 'materialReceivedAt'],
  width: 180,
  ellipsis: true,
  render: (materialReceivedAt: any) => (
    <div className="flex items-center justify-center">
      {materialReceivedAt ? moment(materialReceivedAt).format('DD/MM/YYYY') : '-'}
    </div>
  ),
  sortable: true,
};

export const caseCreatedAtColumn: ITableColumnPropertiesRaw = {
  title: 'Case Created Date',
  dataIndex: 'createdAt',
  width: 180,
  ellipsis: true,
  render: (createdAt: string) => (
    <div className="flex items-center justify-center">{createdAt ? moment(createdAt).format('DD/MM/YYYY') : '-'}</div>
  ),
  sortable: true,
};

export const mandatoryCompletionColumn: ITableColumnPropertiesRaw = {
  title: 'Mandatory Completion %',
  dataIndex: 'submissionPercentage',
  width: 180,
  ellipsis: true,
  render: (submissionPercentage: number) => (
    <div className="flex items-center justify-center">{submissionPercentage ? `${submissionPercentage}%` : '-'}</div>
  ),
  sortable: true,
};

export const stlStatusColumn: ITableColumnPropertiesRaw = {
  title: 'STL Status',
  dataIndex: 'impressionType',
  width: 220,
  ellipsis: true,
  render: (impressionType: string, record: ISubmission) => (
    <div className="flex items-center justify-center">
      {impressionType ? (
        <div>
          {impressionType?.includes('Digital') && record?.imprImages && record?.imprImages.length > 0 ? (
            <ColumnUserList user="Digital - Uploaded" color="#06C270" textColor="white" />
          ) : impressionType?.includes('Digital') && record?.imprImages?.length === 0 ? (
            <ColumnUserList
              user={
                record?.stlUploadType === 'Send via scanner'
                  ? `Sending via ${record?.impressionScannerType} - Upload`
                  : 'Digital - Dentist not uploaded'
              }
              color="#979797"
              textColor="#000000"
            />
          ) : impressionType?.includes('Silicone') && record?.imprImages && record?.imprImages.length > 0 ? (
            <ColumnUserList user="Silicone - Uploaded" color="#06C270" textColor="white" />
          ) : impressionType?.includes('Silicone') && record?.imprImages?.length === 0 ? (
            <ColumnUserList user="Silicone - Upload STLs" color="#000000" textColor="white" />
          ) : (
            ''
          )}
        </div>
      ) : (
        <ColumnUserList user="None Selected" color="#F0F0F0" textColor="#000000" />
      )}
    </div>
  ),
  sortable: true,
};

export const manufacturerStatusColumn: ITableColumnPropertiesRaw = {
  title: 'Manufacturer Status',
  dataIndex: 'manufacturerStatus',
  width: 200,
  render: (status: EManufacturerSubmissionStatuses) => {
    const statusValue = MANUFACTURER_STATUS_MAPPING[status];
    return (
      <Badge size="default" className="w-full">
        <div
          className="flex items-center justify-center"
          style={{
            height: '2rem',
            backgroundColor: statusValue?.color,
            color: 'white',
            borderRadius: '2rem',
            textOverflow: 'ellipsis',
          }}
        >
          <Tooltip className="format px-4 capitalize" title={statusValue?.text}>
            {statusValue?.text}
          </Tooltip>
        </div>
      </Badge>
    );
  },
  sortable: true,
};

export const casePutOnHoldBy: ITableColumnPropertiesRaw = {
  title: 'Case put on hold by',
  dataIndex: ['onHold', 'createdBy', 'role'],
  width: 180,
  ellipsis: true,
  render: (onHoldByRole: string) => (
    <div className="format capitalize">
      <Tooltip title={onHoldByRole}>{onHoldByRole}</Tooltip>
    </div>
  ),
  sortable: true,
};

export const previouslyAssignedColumn: ITableColumnPropertiesRaw = {
  title: 'Was case previously assigned',
  dataIndex: 'previouslyAssigned',
  width: 180,
  render: (previouslyAssigned: string) => <span>{previouslyAssigned ? 'Yes' : 'No'}</span>,
};

export const totalLiveCasesColumn: ITableColumnPropertiesRaw = {
  title: 'Total Live Cases',
  width: 180,
  dataIndex: 'inProgressSubmissionCount',
  render: (caseCount: number) => (
    <span>
      {caseCount} {caseCount === 0 || caseCount === 1 ? 'case' : 'cases'}
    </span>
  ),
  sortable: true,
};

export const newCasesColumn: ITableColumnPropertiesRaw = {
  title: 'New Cases',
  width: 180,
  dataIndex: 'newSubmissionCount',
  render: (caseCount: number) => (
    <span>
      {caseCount} {caseCount === 0 || caseCount === 1 ? 'case' : 'cases'}
    </span>
  ),
  sortable: true,
};

export const awaitingAcceptanceColumn: ITableColumnPropertiesRaw = {
  title: 'Awaiting acceptance',
  width: 180,
  dataIndex: 'awaitingAcceptanceCount',
  render: (caseCount: number) => (
    <span>
      {caseCount} {caseCount === 0 || caseCount === 1 ? 'case' : 'cases'}
    </span>
  ),
  sortable: true,
};

export const estimatedDesignerDueDateColumn: ITableColumnPropertiesRaw = {
  title: 'Design Due',
  dataIndex: 'estimatedDesignDate',
  width: 176,
  render: (date: string, submission: ISubmission) => (
    <span
      className="flex items-center"
      style={{
        color:
          date && submission.status !== ESubmissionStatus.ON_HOLD && moment().diff(moment(date), 'days') >= 0
            ? 'red'
            : 'initial',
      }}
    >
      {submission.status !== ESubmissionStatus.ON_HOLD && date ? moment(date).utc().format('dddd, Do MMMM') : 'N/A'}
    </span>
  ),
  sortable: true,
};

export const originalDesignerDueDateColumn: ITableColumnPropertiesRaw = {
  title: 'Previous Design Due',
  dataIndex: 'originalDesignDate',
  width: 176,
  render: (originalDesignDate: string, submission: ISubmission) =>
    originalDesignDate &&
    (originalDesignDate !== submission.estimatedDesignDate || submission.status === ESubmissionStatus.ON_HOLD) ? (
      <span className="flex items-center">
        {originalDesignDate ? moment(originalDesignDate).utc().format('dddd, Do MMMM') : '-'}
      </span>
    ) : (
      '-'
    ),
  sortable: true,
};

export const dateAssignedManufacturer: ITableColumnPropertiesRaw = {
  title: 'Date Assigned Manufacturer',
  dataIndex: 'withManufacturerAt',
  width: 176,
  render: (date?: string) => (
    <span className="flex items-center">{date ? moment(date).utc().format('dddd, Do MMMM') : '-'}</span>
  ),
  sortable: true,
};

export const estimateInboundDeliveryDate: ITableColumnPropertiesRaw = {
  title: 'Estimate Inbound Delivery Date',
  dataIndex: ['order', 'eidd'],
  width: 176,
  render: (date: string, record: ISubmission) => (
    <span className={classNames('flex items-center', { 'text-red-500': record.eiddLessThenSeven })}>
      {date ? moment(date).utc().format('dddd, Do MMMM, YYYY') : '-'}
    </span>
  ),
  sortable: true,
};

export const dayToEidd: ITableColumnPropertiesRaw = {
  title: 'Days to EIDD',
  dataIndex: ['order', 'eidd', 'dayToEidd'],
  width: 176,
  render: (date: string, record: any) => {
    const diffDays = record?.order?.eidd ? moment(record?.order?.eidd).utc().diff(moment().utc(), 'days') : 0;
    return (
      <span className={classNames('flex items-center', { 'text-red-500': record.eiddLessThenSeven })}>
        {diffDays > 0 ? diffDays : 0}
      </span>
    );
  },
};

export const technicianColumn: ITableColumnPropertiesRaw = {
  title: 'Technician',
  dataIndex: ['technician', 'fullName'],
  width: 176,
  sortable: true,
  searchable: true,
};
