import { useEffect, useMemo, useState } from 'react';
import { useDownloadStlsAsZipMutation, useLazyGetDownloadStlsAsZipStatusQuery } from 'services/api';
import { notificationApiError } from 'utils/ui';
import { ESTLDownloadStatues } from 'config/constants';
import { notification } from 'antd';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import {
  removeDownloadProgress,
  setIsIntervalRunning,
  updateDisplay,
  updateDownloadProgress,
} from 'redux/downloadProgressSlice';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

export type TDownloadProgressKey = 'TreatmentDesignStls' | 'Common';
export const DOWNLOAD_KEY_SPLITER = '__';
export const buildDownloadCode = (key: TDownloadProgressKey, uniqueId: string) =>
  `${key}${DOWNLOAD_KEY_SPLITER}${uniqueId}`;

type TDownloadQuery = {
  downloadingPercent: number;
  downloadedData: any;
  downloadKey: TDownloadProgressKey;
  isFinished: boolean | undefined;
};

export const useDownloadQuery = (downloadCode: string, payload: any): TDownloadQuery => {
  const downloadKey = downloadCode.split(DOWNLOAD_KEY_SPLITER)[0] as TDownloadProgressKey;
  const dispatch = useAppDispatch();
  const [downloadingPercent, setDownloadingPercent] = useState<number>(0);
  const [downloadedData, setDownloadedData]: any = useState(null);
  const [downloadStlsAsZip] = useDownloadStlsAsZipMutation();
  const { isIntervalRunning, progressList } = useAppSelector((state) => state.downloadProgress);
  const [getDownloadStlsAsZipStatus] = useLazyGetDownloadStlsAsZipStatusQuery();
  let newIntervalId: NodeJS.Timer;

  const triggerDownloadUrl = (downloadStatus: { downloadUrl: string }) => {
    clearInterval(newIntervalId);
    const anchor = document.createElement('a');
    anchor.href = downloadStatus.downloadUrl;
    anchor.style.display = 'none';
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
    dispatch(setIsIntervalRunning(false));
    dispatch(updateDownloadProgress({ downloadCode, isFinished: true }));
    dispatch(removeDownloadProgress(downloadCode));
    notification.success({ message: 'Download Ready!' });
    setTimeout(() => {
      if (progressList.length === 0) {
        dispatch(updateDisplay(false));
      }
    }, 1000);
  };

  const errorHandling = () => {
    clearInterval(newIntervalId);
    dispatch(setIsIntervalRunning(false));
  };

  const treatmentDesignStlsDownloader = async () => {
    try {
      const result = await downloadStlsAsZip(payload).unwrap();
      if (result.status !== ESTLDownloadStatues.ERROR) {
        dispatch(setIsIntervalRunning(true));
        newIntervalId = setInterval(async () => {
          try {
            const data = await getDownloadStlsAsZipStatus({ treatmentDesign: result.treatmentDesign }).unwrap();
            if (Array.isArray(data) && data.length > 0) {
              const downloadStatus = data[0];
              if (downloadStatus.status === ESTLDownloadStatues.CANCEL || downloadStatus?.isCancelled) {
                clearInterval(newIntervalId);
              }
              const completedDownload = downloadStatus.downloadUrl;
              const calcPercentage =
                downloadStatus.totalFiles !== 0
                  ? (downloadStatus.downloadedFiles / downloadStatus.totalFiles) * 100
                  : 0;
              setDownloadingPercent(calcPercentage > 95 && !completedDownload ? 95 : Math.floor(calcPercentage));
              setDownloadedData(downloadStatus);
              if (completedDownload) {
                triggerDownloadUrl(downloadStatus);
              }
            } else {
              notification.warn({ message: 'Something went wrong!' });
              errorHandling();
            }
          } catch (error) {
            notificationApiError(error);
            errorHandling();
          }
        }, 10000);
      } else {
        notification.error({ message: 'Download Error!' });
        errorHandling();
        dispatch(updateDownloadProgress({ downloadCode, isFinished: true }));
        dispatch(updateDisplay(false));
      }
    } catch (error) {
      notificationApiError(error);
    }
  };

  const commonDownloader = async () => {
    const zip = new JSZip();

    const fetchPromises = payload.files.map(async (file: any) => {
      try {
        const response = await fetch(file.url);
        if (!response.ok) {
          throw new Error(`Failed to download file: ${file.name}`);
        }
        const content = await response.blob();
        setDownloadingPercent((v) => Math.floor(v + 100 / payload.files.length));
        zip.file(file.name, content);
      } catch (error: any) {
        notification.warn({ message: error.message });
      }
    });

    await Promise.all(fetchPromises);
    const zipBlob: any = await zip.generateAsync({ type: 'blob' });
    saveAs(zipBlob, `${payload.fileName}.zip`);
    dispatch(updateDownloadProgress({ downloadCode, isFinished: true }));
  };

  useEffect(() => {
    if (!isIntervalRunning) {
      clearInterval(newIntervalId);
    }
  }, [isIntervalRunning]);

  const MAP: Record<TDownloadProgressKey, any> = {
    TreatmentDesignStls: treatmentDesignStlsDownloader,
    Common: commonDownloader,
  };

  const isFinished: boolean | undefined = useMemo(
    () => progressList.find((v) => v.downloadCode === downloadCode)?.isFinished,
    [progressList, downloadCode],
  );

  useEffect(() => {
    if (!isFinished) MAP[downloadKey]();
  }, [isFinished]);

  return {
    downloadingPercent,
    downloadedData,
    downloadKey,
    isFinished,
  };
};
