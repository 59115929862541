import './practices.styles.less';
import { FC, useState } from 'react';
import { Button, Input, Form, Select, Upload, message, Skeleton } from 'antd';
import UploadImg from 'assets/images/admin/upload.png';
import { beforeFileUploadImg5mb, checkURL, getBase64 } from 'utils';
import {
  useCreatePracticeMutation,
  useGetPracticeByIdQuery,
  useGetScannersQuery,
  useUpdatePracticeMutation,
} from 'services/practices/practicesApi';
import { useGetSignedUrlMutation } from 'services/api';
import { YESNO } from 'config/constants';
import { MobileNumberInput } from 'components/mobile-number-input/mobile-number-input';
import { validMobileNumber } from 'components/mobile-number-input/utils';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { ILogo, IPracticeType } from 'interface/practices';
import { AddressField } from 'components/address-field/address-field';
import { uploadLogo } from '../utils';
import { TClinic } from 'services/user/userTypes';
import { useWatch } from 'antd/es/form/Form';
import { MetaOptionsInput } from 'components/meta-options-input/meta-options-input';
import { EFormName } from 'hooks/useMetaOptions';
import { NiceAntModal } from 'components/nice-components/nice-ant-modal/nice-ant-modal';
import { PermissionCheck } from 'components/permission-check/permission-check';

const { Option } = Select;

const selectFilter = [
  { label: 'Dentist name', value: 1 },
  { label: 'Practice name', value: 2 },
  { label: 'Group', value: 3 },
  { label: '32Co custom audience', value: 4 },
];

interface IEditPracticeProps {
  fetchPractice?: any;
  practiceId?: string;
  disabled?: boolean;
  clinic?: TClinic;
}

export const PracticeForm: FC<any> = NiceModal.create<IEditPracticeProps>(
  ({ clinic, fetchPractice, practiceId, disabled }) => {
    const { data: scannersOptions, isLoading: isLoadingScanners } = useGetScannersQuery({});

    const { data: current, isLoading } = useGetPracticeByIdQuery({ id: practiceId || '' }, { skip: !practiceId });
    const [createPractice] = useCreatePracticeMutation();

    const modal = useModal();
    const [form] = Form.useForm();
    const [practiceBtnLoading, setPracticeBtnLoading] = useState(false);

    const [logo1, setLogo1] = useState('');
    const [logo2, setLogo2] = useState('');
    const [logoFile1, setLogoFile1] = useState<any>(null);
    const [logoFile2, setLogoFile2] = useState<any>(null);

    const [updatePractice] = useUpdatePracticeMutation();
    const [getSignedUrl] = useGetSignedUrlMutation();

    const [showLogo1, setShowLogo1] = useState(true);
    const [showLogo2, setShowLogo2] = useState(true);
    const isScannerAvailable = useWatch('isScannerAvailable', form);

    const handleCancel = () => {
      modal.remove();
    };

    const onFinish = async (values: any) => {
      setPracticeBtnLoading(true);

      if (values && values.name === '' && values.address === '' && current) {
        return;
      }
      const logos = (
        await Promise.all([
          logoFile1 ? uploadLogo(logoFile1, getSignedUrl) : undefined,
          logoFile2 ? uploadLogo(logoFile2, getSignedUrl) : undefined,
        ])
      ).filter((logo) => logo?.url) as ILogo[];

      if (logoFile1 === null && current?.logos?.length && current?.logos?.length >= 1) {
        // eslint-disable-next-line prefer-destructuring
        logos[0] = { ...current.logos[0], type: current.logos[0].type || 'image/jpg' };
      }

      if (logoFile2 === null && current?.logos?.length === 2) {
        // eslint-disable-next-line prefer-destructuring
        logos[1] = { ...current.logos[0], type: current.logos[1].type || 'image/jpg' };
      }

      const dataToSend: Omit<IPracticeType, 'createdAt' | 'updatedAt' | 'id'> = {
        name: values.name,
        address: values.address,
        manager: values.manager,
        email: values.manager?.email,
        additionalEmail: values.additionalEmail,
        logos: logos.map((lg) => ({ ...lg, uploadedAt: new Date() })),
        phone:
          values.phone?.number && values.phone?.ext
            ? { ext: values.phone?.ext || '', number: values.phone?.number || '' }
            : undefined,
        isScannerAvailable: values?.isScannerAvailable || false,
        scanners: values.scanners,
        isOfferClearAligners: values?.offerClearAligners || false,
        taxId: values?.taxId || undefined,
        practiceFocused: values?.practiceFocused || undefined,
      };

      const actionName = practiceId ? 'Saved' : 'Created';
      try {
        if (practiceId) {
          await updatePractice({ id: practiceId, body: dataToSend }).unwrap();
          fetchPractice?.();
        } else {
          await createPractice({ body: dataToSend }).unwrap();
        }
        message.success(`Practice ${actionName} Successfully`);
        modal.remove();
      } catch {
        message.error(`Practice ${actionName} Failed`);
      } finally {
        setPracticeBtnLoading(false);
      }
    };

    const profileOnChange1 = () => {
      setShowLogo1(false);
    };
    const profileOnChange2 = () => {
      setShowLogo2(false);
    };
    const profileRequest1 = async ({ file, onSuccess }: any) => {
      onSuccess('ok');
      if (file) {
        setLogoFile1(file);
        getBase64(file, (imageUrl: any) => setLogo1(imageUrl));
      }
    };

    const profileRequest2 = async ({ file, onSuccess }: any) => {
      onSuccess('ok');
      if (file) {
        setLogoFile2(file);
        getBase64(file, (imageUrl: any) => setLogo2(imageUrl));
      }
    };

    const initialValue: Partial<IPracticeType> = practiceId
      ? {
          name: current?.name ? current.name : '',
          phone: current?.phone,
          address: current?.address,
          manager: current?.manager,
          isScannerAvailable: current?.isScannerAvailable,
          isOfferClearAligners: current?.isOfferClearAligners,
          additionalEmail: current?.additionalEmail || '',
          taxId: current?.taxId || '',
          scanners: current?.scanners,
          practiceFocused: current?.practiceFocused,
        }
      : clinic
      ? {
          name: clinic?.name || '',
          address: clinic?.address,
          manager: {
            email: clinic.email,
          },
          isScannerAvailable: clinic?.isScannerAvailable,
          scanners: clinic?.scanners?.map((scanner) => scanner.id),
          practiceFocused: clinic?.practiceFocused,
        }
      : {};

    return (
      <NiceAntModal
        width={800}
        visible={modal.visible}
        onCancel={handleCancel}
        footer={null}
        closable
        centered
        title={
          <div className="line-height-2 mt-1 flex items-center justify-center  text-xl font-bold">
            {disabled ? '' : practiceId ? 'Edit' : 'Create'} Practice
          </div>
        }
      >
        <Skeleton loading={isLoading || isLoadingScanners} active>
          <div className="h-[80vh] overflow-auto">
            <PermissionCheck resourceKey="practice" actionKey="edit" restrictionType="unActionable">
              <Form
                initialValues={initialValue}
                layout="vertical"
                form={form}
                name="register"
                onFinish={onFinish}
                disabled={disabled}
              >
                <div className="m-0 mx-2 grid grid-cols-12 gap-4 p-0">
                  <div className="col-span-12 md:col-span-6">
                    <Form.Item
                      name="name"
                      label="Clinic Name"
                      rules={[
                        {
                          required: true,
                          message: 'Please enter clinic name',
                        },
                      ]}
                    >
                      <Input placeholder="Add name" className="practice-input-container" />
                    </Form.Item>
                  </div>
                  <div className="col-span-12 md:col-span-6">
                    <Form.Item name="clinicGroup" label="Clinic Group">
                      <Select disabled className="practice-select-container" size="large" placeholder="Dropdown">
                        {selectFilter.map((list) => (
                          <Option key={list.value} value={list.value}>
                            {list.label}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                  <div className="col-span-12 md:col-span-6">
                    <Form.Item
                      label="Clinic Phone Number"
                      name="phone"
                      rules={[{ validator: (_, inputValue) => validMobileNumber(inputValue) }]}
                    >
                      <MobileNumberInput inputClassName="practice-input-container" disabled={disabled} />
                    </Form.Item>
                  </div>
                  <div className="col-span-12 md:col-span-6">
                    <Form.Item
                      name={['manager', 'name']}
                      label="Clinic Admin Name"
                      rules={[
                        {
                          required: true,
                          message: 'Please enter clinic admin name',
                        },
                        { max: 50, message: 'Should not exceed 50 characters' },
                      ]}
                    >
                      <Input placeholder="Add name" className="practice-input-container" />
                    </Form.Item>
                  </div>
                  <div className="col-span-12 md:col-span-6">
                    <AddressField
                      name={['address', 'address1']}
                      label="Practice Address"
                      rules={[
                        {
                          required: true,
                          message: 'Please enter practice address',
                        },
                      ]}
                      className="practice-input-container"
                      placeholder="Search from Google Maps"
                      setFieldValue={(address) =>
                        form.setFieldsValue({
                          address,
                        })
                      }
                      disabled={disabled}
                    />
                  </div>
                  <div className="col-span-12 md:col-span-6">
                    <Form.Item
                      name={['address', 'postCode']}
                      label="Zip Code"
                      rules={[
                        {
                          required: true,
                          message: 'Please enter Zip code',
                        },
                      ]}
                    >
                      <Input className="practice-input-container" />
                    </Form.Item>
                  </div>
                  <div className="col-span-12 md:col-span-6">
                    <Form.Item
                      name={['manager', 'email']}
                      label="Clinic Admin Email 1"
                      rules={[
                        {
                          required: true,
                          message: 'Please enter practice email',
                        },
                        { type: 'email', message: 'Please enter valid email address' },
                      ]}
                    >
                      <Input className="practice-input-container" />
                    </Form.Item>
                  </div>
                  <div className="col-span-12 md:col-span-6">
                    <Form.Item
                      rules={[{ type: 'email', message: 'Please enter valid email address' }]}
                      name={['additionalEmail']}
                      label="Clinic Admin Email 2(optional)"
                    >
                      <Input className="practice-input-container" />
                    </Form.Item>
                  </div>
                  <div className="col-span-12 md:col-span-6">
                    <Form.Item label="Do you have a scanner in the practice?" name="isScannerAvailable">
                      <Select
                        className="practice-select-container"
                        size="large"
                        options={YESNO}
                        placeholder="Select Option"
                      />
                    </Form.Item>
                  </div>

                  {isScannerAvailable && (
                    <div className="col-span-12 md:col-span-6">
                      <Form.Item
                        label="Scanner Type"
                        name="scanners"
                        rules={[{ required: isScannerAvailable, message: 'This field is required' }]}
                      >
                        <Select
                          className="practice-select-container2 h-auto"
                          options={scannersOptions}
                          mode="multiple"
                          placeholder="Select Option"
                          showArrow
                        />
                      </Form.Item>
                    </div>
                  )}
                  <div className="col-span-12 md:col-span-6">
                    <Form.Item name="taxId" label="Clinic Tax ID">
                      <Input className="practice-input-container" />
                    </Form.Item>
                  </div>
                  <div className="col-span-12">
                    <Form.Item
                      label="Does somebody in your practice already offer Clear Aligners?"
                      name="isOfferClearAligners"
                    >
                      <Select
                        className="practice-select-container"
                        size="large"
                        options={YESNO}
                        placeholder="Select Option"
                      />
                    </Form.Item>
                  </div>
                  <div className="col-span-12">
                    <Form.Item
                      label="Is the practice general or cosmetic focused?"
                      name="practiceFocused"
                      rules={[{ required: true, message: 'This field is required' }]}
                    >
                      <MetaOptionsInput
                        className="practice-select-container"
                        formName={EFormName.dentistRegistration}
                        optionKey="practiceFocused"
                        size="large"
                      />
                    </Form.Item>
                  </div>
                  <div className="col-span-12 md:col-span-6">
                    <div className="grid grid-cols-12 gap-4">
                      <div className="col-span-12 md:col-span-6">
                        <Form.Item name="Logo1" label="Logo 1">
                          <Upload
                            name="avatar"
                            showUploadList={false}
                            beforeUpload={beforeFileUploadImg5mb}
                            customRequest={profileRequest1}
                            onChange={profileOnChange1}
                          >
                            <img
                              alt=""
                              src={
                                showLogo1 &&
                                current?.logos?.length &&
                                current?.logos?.length >= 1 &&
                                checkURL(current.logos[0].url)
                                  ? current.logos[0].url
                                  : logo1 || UploadImg
                              }
                              style={{
                                width: '100px',
                                height: '100px',
                                boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                                borderRadius: '5px',
                              }}
                            />
                          </Upload>
                        </Form.Item>
                      </div>
                      <div className="col-span-12 md:col-span-6">
                        <Form.Item name="Logo2" label="Logo 2">
                          <Upload
                            name="avatar"
                            showUploadList={false}
                            beforeUpload={beforeFileUploadImg5mb}
                            customRequest={profileRequest2}
                            onChange={profileOnChange2}
                          >
                            <img
                              alt=""
                              src={
                                showLogo2 && current?.logos?.length === 2 && checkURL(current.logos[1].url)
                                  ? current.logos[1].url
                                  : logo2 || UploadImg
                              }
                              style={{
                                width: '100px',
                                height: '100px',
                                boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                                borderRadius: '5px',
                              }}
                            />
                          </Upload>
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                </div>

                {!disabled && (
                  <PermissionCheck
                    resourceKey="practice"
                    actionKey={practiceId ? 'edit' : 'create'}
                    restrictionType="unActionable"
                  >
                    <div className="flex items-center justify-end gap-4">
                      <Button onClick={handleCancel} type="default">
                        CANCEL
                      </Button>
                      <Button loading={practiceBtnLoading} htmlType="submit" type="primary">
                        {clinic ? 'ADD TO DATABASE' : practiceId ? 'UPDATE' : 'CREATE'}
                      </Button>
                    </div>
                  </PermissionCheck>
                )}
              </Form>
            </PermissionCheck>
          </div>
        </Skeleton>
      </NiceAntModal>
    );
  },
);
