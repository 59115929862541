import { FC } from 'react';
import { useTogglePriorityRequestMutation } from 'services/operations/operationsApi';
import { notification, Switch } from 'antd';
import { ISubmission } from 'interface/operations';
import { PermissionCheck } from 'components/permission-check/permission-check';

export const PriorityColumn: FC<{ record: ISubmission }> = ({ record }) => {
  const [togglePriority, { isLoading }] = useTogglePriorityRequestMutation();

  const onPriorityChange = async () => {
    try {
      await togglePriority({ id: record.id }).unwrap();
      notification.success({
        message: record.isPriority ? 'The case is no longer marked as a priority' : 'The case is marked as a priority',
      });
    } catch (error: any) {
      error.data.errors.forEach((e: { message: string }) =>
        notification.error({
          message: e.message,
        }),
      );
    }
  };
  return (
    <PermissionCheck resourceKey="operation" actionKey="edit" restrictionType="unActionable">
      <Switch disabled={isLoading} defaultChecked={record.isPriority} onChange={onPriorityChange} />
    </PermissionCheck>
  );
};
