import { FC } from 'react';
import { Button, notification, Popconfirm } from 'antd';
import { useArchiveSubmissionsMutation } from 'services/operations/operationsApi';
import { ButtonProps } from 'antd/lib/button/button';
import { DeleteOutlined } from '@ant-design/icons';
import { PermissionCheck } from 'components/permission-check/permission-check';

interface IArchiveSubmissionButtonProps extends ButtonProps {
  submissionId: string;
  onSuccess?: () => void;
}

export const ArchiveSubmissionButton: FC<IArchiveSubmissionButtonProps> = ({ submissionId, onSuccess, ...rest }) => {
  const [archiveSubmissions, { isLoading }] = useArchiveSubmissionsMutation();

  const onArchiveCaseClick = async () => {
    try {
      await archiveSubmissions({ caseIds: [submissionId] }).unwrap();
      notification.success({ message: 'Case was archived' });
      onSuccess?.();
    } catch (error: any) {
      notification.error({ message: 'Error' });
      console.log(error);
    }
  };

  return (
    <PermissionCheck resourceKey="operation" actionKey="delete">
      <Popconfirm
        title="Are you sure you want to archive this case?"
        placement="left"
        okText="Yes"
        cancelText="No"
        onConfirm={onArchiveCaseClick}
      >
        <Button type="text" shape="round" size="small" disabled={isLoading} icon={<DeleteOutlined />} {...rest} />
      </Popconfirm>
    </PermissionCheck>
  );
};
