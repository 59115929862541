import React, { Suspense } from 'react';
import { useGetListSubmissionsListingQuery, useGetListSubmissionsQuery } from 'services/operations/operationsApi';
import { Loading } from './components/loading';
import IRoute from './interface/IRoute';
import { Manufacturer } from './pages/third-parties/manufacturer/manufacturer';
import { PermissionCheck } from 'components/permission-check/permission-check';

const LazySigninPage = React.lazy(() => import('./pages/siginin/signin-page'));
const LazyForgotPasswordPage = React.lazy(() => import('./pages/forgot-password/forgot-password'));
const LazyResetPassword = React.lazy(() => import('./pages/reset-password/reset-password'));
const LazyDashboardPage = React.lazy(() => import('./pages/dashboard/dashboard-page'));
const LazyOperationsPage = React.lazy(() => import('./pages/operations/operations-page'));
const LazyInterestedPatientsPage = React.lazy(() => import('./pages/interested-patients/interested-patients-page'));
const LazyInterestedPatientDetailsPage = React.lazy(
  () => import('./pages/interested-patients/interested-patient-details-page/interested-patient-details-page'),
);
const LazyOperationDetailsPage = React.lazy(() => import('./pages/operation-details/operation-details-page'));
const LazyOperationsTreatmentDesignDetailPage = React.lazy(
  () => import('./pages/operation-details/treatment-design-details/treatment-design-details'),
);
const LazyDentistsPage = React.lazy(() => import('./pages/dentists/dentists-page'));
const LazyPracticesPage = React.lazy(() => import('./pages/practices/practices-page'));
const LazyBillsPage = React.lazy(() => import('./pages/bills/bills-page'));
const LazyPaymentsPage = React.lazy(() => import('./pages/payments/payments-page'));
const LazyDiscountOperationPage = React.lazy(() => import('./pages/discounts/discount.operations'));
const LazyDiscountsPage = React.lazy(() => import('./pages/discounts/discounts-list/discounts-list'));
const LazyDentistProfile = React.lazy(() => import('./pages/dentist-profile/dentist-profile'));
const LazyDesigner = React.lazy(() => import('./pages/third-parties/designer/designer'));
const LazySpecialist = React.lazy(() => import('./pages/third-parties/specialist/specialist'));
const LazySpecialistDetail = React.lazy(
  () => import('./pages/third-parties/specialist/specialist-detail/specialist-detail'),
);
const LazyTechnician = React.lazy(() => import('./pages/third-parties/technician/technician'));
const EducationContentPage = React.lazy(() => import('./pages/education-content/education-content'));
const EducationContentDetailPage = React.lazy(
  () => import('./pages/education-content/education-content-detail/education-content-detail'),
);
const LazyReleaseNotesPage = React.lazy(() => import('./pages/release-notes/release-notes'));
const LazyGroupsPage = React.lazy(() => import('./pages/groups/groups'));
const LazyCalculatorPage = React.lazy(() => import('./pages/calculator/calculator-page'));
const LazyUserSettingsPage = React.lazy(() => import('./pages/users/users'));
const LazyCorporatesPage = React.lazy(() => import('./pages/corporates/corporates-page'));
const LazyHolidaysPage = React.lazy(() => import('./pages/holidays/holidays'));
const LazyFulfillmentsPage = React.lazy(() => import('./pages/fulfillments/fulfillments-page'));
const LazyReplacementsPage = React.lazy(() => import('./pages/replacements/replacements-page'));
const LazyRetainersPage = React.lazy(() => import('./pages/retainers/retainers-page'));
const LazyCompletedTreatmentsPage = React.lazy(() => import('./pages/completed-treatment/completed-treatment'));
const LazyCaseStudiesPage = React.lazy(() => import('./pages/case-studies/case-studies'));
const LazyCaseStudyDetailsPage = React.lazy(
  () => import('./pages/case-studies/case-study-details-page/case-study-details-page'),
);

export const unProtectedRoutes: IRoute[] = [
  {
    path: '/signin',
    exact: true,
    name: 'Signin Page',
    key: 'signin_page',
    component: () => (
      <Suspense fallback={<Loading />}>
        <LazySigninPage />
      </Suspense>
    ),
  },
  {
    path: '/forgot-password',
    exact: true,
    name: 'Forgot Page',
    key: 'forgot_page',
    component: () => (
      <Suspense fallback={<Loading />}>
        <LazyForgotPasswordPage />
      </Suspense>
    ),
  },
  {
    path: '/reset-password',
    exact: true,
    name: 'Reset Page',
    key: 'reset_page',
    component: () => (
      <Suspense fallback={<Loading />}>
        <LazyResetPassword />
      </Suspense>
    ),
  },
];

export const protectedRoutes: IRoute[] = [
  {
    path: ['/', '/dashboard'] as any,
    exact: true,
    name: 'Dashboard Page',
    key: 'dashboard_page',
    component: () => (
      <PermissionCheck resourceKey="dashboard" actionKey="read" restrictionType="restrictionMessage">
        <Suspense fallback={<Loading />}>
          <LazyDashboardPage />
        </Suspense>
      </PermissionCheck>
    ),
  },
  {
    path: '/operations',
    exact: true,
    name: 'Operations',
    key: 'operations',
    component: () => (
      <PermissionCheck resourceKey="operation" actionKey="read" restrictionType="restrictionMessage">
        <Suspense fallback={<Loading />}>
          <LazyOperationsPage submissionsQuery={useGetListSubmissionsQuery} />
        </Suspense>
      </PermissionCheck>
    ),
  },
  {
    path: '/interested-patients',
    exact: true,
    name: 'Interested Patients',
    key: 'interested-patients',
    component: () => (
      <PermissionCheck resourceKey="operation" actionKey="read" restrictionType="restrictionMessage">
        <Suspense fallback={<Loading />}>
          <LazyInterestedPatientsPage />
        </Suspense>
      </PermissionCheck>
    ),
  },
  {
    path: '/interested-patients/:id',
    exact: true,
    name: 'Interested Patient Details',
    key: 'interested-patient-details',
    component: () => (
      <PermissionCheck resourceKey="operation" actionKey="read" restrictionType="restrictionMessage">
        <Suspense fallback={<Loading />}>
          <LazyInterestedPatientDetailsPage />
        </Suspense>
      </PermissionCheck>
    ),
  },
  {
    path: '/completed-treatments',
    exact: true,
    name: 'Completed Treatments',
    key: 'completed-treatments',
    component: () => (
      <PermissionCheck resourceKey="completed_treatment" actionKey="read" restrictionType="restrictionMessage">
        <Suspense fallback={<Loading />}>
          <LazyCompletedTreatmentsPage />
        </Suspense>
      </PermissionCheck>
    ),
  },
  {
    path: '/case-studies',
    exact: true,
    name: 'Case Studies',
    key: 'case-studies',
    component: () => (
      <PermissionCheck resourceKey="operation" actionKey="read" restrictionType="restrictionMessage">
        <Suspense fallback={<Loading />}>
          <LazyCaseStudiesPage />
        </Suspense>
      </PermissionCheck>
    ),
  },
  {
    path: '/case-studies/:id',
    exact: true,
    name: 'Case Study Details',
    key: 'case-study-details',
    component: () => (
      <PermissionCheck resourceKey="operation" actionKey="read" restrictionType="restrictionMessage">
        <Suspense fallback={<Loading />}>
          <LazyCaseStudyDetailsPage />
        </Suspense>
      </PermissionCheck>
    ),
  },
  {
    path: '/operations/:submissionId',
    exact: true,
    name: 'Operation_Details',
    key: 'operation_details',
    component: () => (
      <PermissionCheck resourceKey="operation_submission" actionKey="read" restrictionType="restrictionMessage">
        <Suspense fallback={<Loading />}>
          <LazyOperationDetailsPage />
        </Suspense>
      </PermissionCheck>
    ),
  },
  {
    path: '/replacements',
    exact: true,
    name: 'Replacements',
    key: 'replacements',
    component: () => (
      <PermissionCheck resourceKey="replacement" actionKey="read" restrictionType="restrictionMessage">
        <Suspense fallback={<Loading />}>
          <LazyReplacementsPage />
        </Suspense>
      </PermissionCheck>
    ),
  },
  {
    path: '/retainers',
    exact: true,
    name: 'Retainers',
    key: 'retainers',
    component: () => (
      <PermissionCheck resourceKey="retainer" actionKey="read" restrictionType="restrictionMessage">
        <Suspense fallback={<Loading />}>
          <LazyRetainersPage />
        </Suspense>
      </PermissionCheck>
    ),
  },
  {
    path: '/operations/:submissionId/design/:designId',
    exact: true,
    name: 'Operations_Treatment_Detail_Design',
    key: 'operations_treatment_detail_design',
    component: () => (
      <PermissionCheck resourceKey="operation_treatment_design" actionKey="read" restrictionType="restrictionMessage">
        <Suspense fallback={<Loading />}>
          <LazyOperationsTreatmentDesignDetailPage />
        </Suspense>
      </PermissionCheck>
    ),
  },
  {
    path: '/operations/case-listing/:submissionTarget',
    exact: true,
    name: 'Case_Listing',
    key: 'case_listing',
    component: () => (
      <Suspense fallback={<Loading />}>
        <LazyOperationsPage submissionsQuery={useGetListSubmissionsListingQuery} />
      </Suspense>
    ),
  },
  {
    path: '/dentists',
    exact: true,
    name: 'Dentists Page',
    key: 'dentists_page',
    component: () => (
      <PermissionCheck resourceKey="dentist" actionKey="read" restrictionType="restrictionMessage">
        <Suspense fallback={<Loading />}>
          <LazyDentistsPage />
        </Suspense>
      </PermissionCheck>
    ),
  },
  {
    path: '/dentists/:id',
    exact: true,
    name: 'Dentist_Profile_Page',
    key: 'dentist_profile_page',
    component: () => (
      <PermissionCheck resourceKey="dentist" actionKey="read" restrictionType="restrictionMessage">
        <Suspense fallback={<Loading />}>
          <LazyDentistProfile />
        </Suspense>
      </PermissionCheck>
    ),
  },
  {
    path: '/corporates',
    exact: true,
    name: 'Corporates Page',
    key: 'corporates_page',
    component: () => (
      <PermissionCheck resourceKey="corporate" actionKey="read" restrictionType="restrictionMessage">
        <Suspense fallback={<Loading />}>
          <LazyCorporatesPage />
        </Suspense>
      </PermissionCheck>
    ),
  },
  {
    path: '/practices',
    exact: true,
    name: 'Pactices Page',
    key: 'pactices_page',
    component: () => (
      <PermissionCheck resourceKey="practice" actionKey="read" restrictionType="restrictionMessage">
        <Suspense fallback={<Loading />}>
          <LazyPracticesPage />
        </Suspense>
      </PermissionCheck>
    ),
  },
  {
    path: '/bills',
    exact: true,
    name: 'Bills_Page',
    key: 'bills_page',
    component: () => (
      <PermissionCheck resourceKey="outbound_bill" actionKey="read" restrictionType="restrictionMessage">
        <Suspense fallback={<Loading />}>
          <LazyBillsPage />
        </Suspense>
      </PermissionCheck>
    ),
  },
  {
    path: '/payments',
    exact: true,
    name: 'Payments_Page',
    key: 'payments_page',
    component: () => (
      <PermissionCheck resourceKey="payment" actionKey="read" restrictionType="restrictionMessage">
        <Suspense fallback={<Loading />}>
          <LazyPaymentsPage />
        </Suspense>
      </PermissionCheck>
    ),
  },
  {
    path: '/discounts',
    exact: true,
    name: 'Discounts_Page',
    key: 'discount_page',
    component: () => (
      <PermissionCheck resourceKey="discount" actionKey="read" restrictionType="restrictionMessage">
        <Suspense fallback={<Loading />}>
          <LazyDiscountsPage />
        </Suspense>
      </PermissionCheck>
    ),
  },
  {
    path: '/discounts/add',
    exact: true,
    name: 'Create_Discount_Page',
    key: 'create_discount_page',
    component: () => (
      <PermissionCheck resourceKey="discount" actionKey="create" restrictionType="restrictionMessage">
        <Suspense fallback={<Loading />}>
          <LazyDiscountOperationPage />
        </Suspense>
      </PermissionCheck>
    ),
  },
  {
    path: '/discounts/:discountId',
    exact: true,
    name: 'Edit_Discount_Page',
    key: 'edit_discount_page',
    component: () => (
      <PermissionCheck resourceKey="discount" actionKey="read" restrictionType="restrictionMessage">
        <Suspense fallback={<Loading />}>
          <LazyDiscountOperationPage />
        </Suspense>
      </PermissionCheck>
    ),
  },
  {
    path: '/designer',
    exact: true,
    name: 'third-party-designer',
    key: 'third-party-designer',
    component: () => (
      <PermissionCheck resourceKey="third_party_designer" actionKey="read" restrictionType="restrictionMessage">
        <Suspense fallback={<Loading />}>
          <LazyDesigner />
        </Suspense>
      </PermissionCheck>
    ),
  },
  {
    path: '/manufacturer',
    exact: false,
    name: 'third-party-manufacturer',
    key: 'third-party-manufacturer',
    component: () => (
      <PermissionCheck resourceKey="third_party_manufacturer" actionKey="read" restrictionType="restrictionMessage">
        <Manufacturer />
      </PermissionCheck>
    ),
  },
  {
    path: '/specialist',
    exact: true,
    name: 'third-party-specialist',
    key: 'third-party-specialist',
    component: () => (
      <PermissionCheck resourceKey="third_party_specialist" actionKey="read" restrictionType="restrictionMessage">
        <Suspense fallback={<Loading />}>
          <LazySpecialist />
        </Suspense>
      </PermissionCheck>
    ),
  },
  {
    path: '/specialist/:userId',
    exact: true,
    name: 'specialist_profile_page',
    key: 'specialist_profile_page',
    component: () => (
      <Suspense fallback={<Loading />}>
        <LazySpecialistDetail />
      </Suspense>
    ),
  },
  {
    path: '/technician',
    exact: true,
    name: 'third-party-technician',
    key: 'third-party-technician',
    component: () => (
      <PermissionCheck resourceKey="third_party_technician" actionKey="read" restrictionType="restrictionMessage">
        <Suspense fallback={<Loading />}>
          <LazyTechnician />
        </Suspense>
      </PermissionCheck>
    ),
  },
  {
    path: '/education-content',
    exact: true,
    name: 'Education Content',
    key: 'education-content',
    component: () => (
      <PermissionCheck resourceKey="education_content" actionKey="read" restrictionType="restrictionMessage">
        <Suspense fallback={<Loading />}>
          <EducationContentPage />
        </Suspense>
      </PermissionCheck>
    ),
  },
  {
    path: '/education-content/content/:contentId',
    exact: true,
    name: 'Education Content - Detailed',
    key: 'education-content-detailed',
    component: () => (
      <PermissionCheck resourceKey="education_content" actionKey="read" restrictionType="restrictionMessage">
        <Suspense fallback={<Loading />}>
          <EducationContentDetailPage />
        </Suspense>
      </PermissionCheck>
    ),
  },
  {
    path: '/release-notes',
    exact: true,
    name: 'Release notes',
    key: 'release-notes',
    component: () => (
      <PermissionCheck resourceKey="release_note" actionKey="read" restrictionType="restrictionMessage">
        <Suspense fallback={<Loading />}>
          <LazyReleaseNotesPage />
        </Suspense>
      </PermissionCheck>
    ),
  },
  {
    path: '/groups',
    exact: false,
    name: 'Groups',
    key: 'groups',
    component: () => (
      <PermissionCheck resourceKey="group" actionKey="read" restrictionType="restrictionMessage">
        <Suspense fallback={<Loading />}>
          <LazyGroupsPage />
        </Suspense>
      </PermissionCheck>
    ),
  },
  {
    path: '/calculator',
    exact: false,
    name: 'Calculator',
    key: 'calculator',
    component: () => (
      <Suspense fallback={<Loading />}>
        <LazyCalculatorPage />
      </Suspense>
    ),
  },
  {
    path: '/user-settings',
    exact: false,
    name: 'User Settings',
    key: 'user-settings',
    component: () => (
      <PermissionCheck resourceKey="user" actionKey="read" restrictionType="restrictionMessage">
        <Suspense fallback={<Loading />}>
          <LazyUserSettingsPage />
        </Suspense>
      </PermissionCheck>
    ),
  },
  {
    path: '/holidays',
    exact: false,
    name: 'Holidays',
    key: 'holidays',
    component: () => (
      <PermissionCheck resourceKey="holiday" actionKey="read" restrictionType="restrictionMessage">
        <Suspense fallback={<Loading />}>
          <LazyHolidaysPage />
        </Suspense>
      </PermissionCheck>
    ),
  },
  {
    path: '/fulfillments',
    exact: false,
    name: 'Fulfillments',
    key: 'fulfillments',
    component: () => (
      <PermissionCheck resourceKey="fulfillment" actionKey="read" restrictionType="restrictionMessage">
        <Suspense fallback={<Loading />}>
          <LazyFulfillmentsPage />
        </Suspense>
      </PermissionCheck>
    ),
  },
];
