/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TChatRoom } from 'components/chat/types';
import { TMessage } from 'components/chat/services/types';

export type TFrom = 'loadMore' | 'loadRecent' | 'client' | 'justSent';
export type TRoomMessages = {
  hasMoreMessages: boolean;
  room: TChatRoom;
  messages?: TMessage[];
  lastId?: string;
  currentId?: string;
  from: TFrom;
  total: number;
};

export type TLoadedRooms = Record<string, TRoomMessages>;
export type TSafeCheckObject = {
  timeoutId: number;
  msgValue: string;
};

type TChatState = {
  totalUnreadCount: number;
  showChat: boolean;
  activeRoomId?: string;
  rooms: TChatRoom[];
  loadedRooms: TLoadedRooms;
  isOnMessageReady: boolean;
  safeCheckObject?: TSafeCheckObject;
};

const INITIAL_STATE: TChatState = {
  totalUnreadCount: 0,
  showChat: false,
  activeRoomId: undefined,
  rooms: [],
  loadedRooms: {},
  isOnMessageReady: false,
  safeCheckObject: undefined,
};

const chatSlice = createSlice({
  name: 'chat',
  initialState: INITIAL_STATE,
  reducers: {
    toggleChat: (state, { payload }: PayloadAction<boolean | undefined>) => {
      // set active room id
      if (payload === undefined) {
        state.showChat = !state.showChat;
      } else {
        state.showChat = payload;
      }
    },
    joinRoom: (state, { payload }: PayloadAction<string | undefined>) => {
      state.activeRoomId = payload;

      if (payload) {
        if (!state.loadedRooms[payload]) {
          const foundRoom = state.rooms.find((r) => r.id === payload);

          if (foundRoom) {
            state.loadedRooms[payload] = {
              room: foundRoom,
              from: 'loadMore',
              total: 0,
              hasMoreMessages: true,
            };
          } else {
            state = { ...INITIAL_STATE, showChat: state.showChat };
          }
        }
      }
    },
    setRooms: (state, { payload: { rooms } }: PayloadAction<{ rooms: TChatRoom[] }>) => {
      state.rooms = rooms;
    },
    loadJoinedRoomMessages: (
      state,
      {
        payload: { messages, from, total, activeRoomId },
      }: PayloadAction<{ messages: TMessage[]; from: TFrom; total: number; activeRoomId?: string }>,
    ) => {
      if (!state.activeRoomId || state.activeRoomId !== activeRoomId) return;

      const loadedRoom = { ...state.loadedRooms[state.activeRoomId] };

      const lastMessageId = messages ? messages[messages.length - 1]?.id : undefined;
      const firstMessageId = messages ? messages[0]?.id : undefined;

      // check duplicated messages
      if (lastMessageId && loadedRoom?.messages?.[loadedRoom.messages.length - 1]?.id === lastMessageId) {
        return;
      }

      loadedRoom.from = from;
      if (from === 'loadMore') {
        if (loadedRoom.messages) {
          loadedRoom.messages.push(...messages);
        } else {
          loadedRoom.messages = messages;
        }

        if (total > 0) {
          loadedRoom.lastId = lastMessageId;
        }
      } else {
        if (loadedRoom.messages) {
          loadedRoom.messages.unshift(...messages);
        } else {
          loadedRoom.messages = messages;
        }
        if (total > 0) {
          loadedRoom.currentId = firstMessageId;
        }
      }

      if (loadedRoom.hasMoreMessages === true) {
        loadedRoom.hasMoreMessages = total > loadedRoom.messages.length;
      }

      state.loadedRooms[state.activeRoomId] = {
        ...state.loadedRooms[state.activeRoomId],
        ...loadedRoom,
      };
    },
    handleMessageCome: (
      state,
      { payload: { message, userId } }: PayloadAction<{ message: TMessage; userId: string }>,
    ) => {
      const msgValue = JSON.stringify({
        attachments: message.attachments?.map((att) => att.url),
        chatRoom: message.chatRoom,
        message: message.message,
        to: message.to.id,
      });
      if (state.safeCheckObject && msgValue === state.safeCheckObject.msgValue) {
        clearTimeout(state.safeCheckObject.timeoutId);
        state.safeCheckObject = undefined;
      }

      const { chatRoom, from, id } = message;
      const receiverRoom = state.loadedRooms[chatRoom];

      if (receiverRoom?.messages && receiverRoom?.messages.findIndex((m) => m.id === id) > -1) {
        console.error(
          `[Chat] Duplicated handleMessageCome: When send message [${message.message}] to userName: [${message.to.fullName}], userId: [${message.to.id}]`,
        );
        return;
      }

      const roomIndex = state.rooms.findIndex((room) => room.id === chatRoom);

      if (roomIndex !== -1) {
        const room = state.rooms[roomIndex];
        const updatedRoom = {
          ...room,
          unreadMessageCount: from.id === userId ? room.unreadMessageCount : room.unreadMessageCount + 1,
        };
        state.rooms[roomIndex] = updatedRoom;

        if (state.loadedRooms[chatRoom]) {
          state.loadedRooms[chatRoom] = {
            ...state.loadedRooms[chatRoom],
            room: updatedRoom,
            messages: state.loadedRooms[chatRoom].messages
              ? [message, ...(state.loadedRooms[chatRoom].messages || [])]
              : [],
            from: from.id === userId ? 'justSent' : 'client',
          };
        }

        // Create a copy of the state.rooms array to trigger a re-render.
        const updatedRooms = [...state.rooms];
        state.rooms = updatedRooms;
      }
    },
    setRoomUnreadMessageCount: (state) => {
      if (!state.activeRoomId) return;

      const activeRoomIndex = state.rooms.findIndex((room: TChatRoom) => room.id === state.activeRoomId);
      if (activeRoomIndex === -1) return;
      state.rooms[activeRoomIndex].unreadMessageCount = 0;
      if (state.loadedRooms[state.activeRoomId] && state.loadedRooms[state.activeRoomId].room) {
        state.loadedRooms[state.activeRoomId].room.unreadMessageCount = 0;
      }
    },
    setUserOnline: (
      state,
      { payload: { roomId, isOnline } }: PayloadAction<{ roomId: string; isOnline?: boolean }>,
    ) => {
      const roomIndex = state.rooms.findIndex((room) => room.id === roomId);

      if (roomIndex !== -1) {
        state.rooms[roomIndex].member.isOnline = isOnline;
      }
    },
    setIsOnMessageReady: (state, { payload }: PayloadAction<boolean>) => {
      state.isOnMessageReady = payload;
    },
    setSafeCheckObject: (state, { payload }: PayloadAction<TSafeCheckObject>) => {
      if (state.safeCheckObject) {
        clearTimeout(state.safeCheckObject.timeoutId);
      }
      state.safeCheckObject = payload;
    },
    resetChatState: () => INITIAL_STATE,
    resetChatStateButKeepShowChat: (state) => ({ ...INITIAL_STATE, showChat: state.showChat }),
    handleOnDeletedMessage: (state, { payload: { message } }: PayloadAction<{ message: TMessage }>) => {
      const { chatRoom, id } = message;
      const receiverRoom = state.loadedRooms[chatRoom];

      // if find message in loadedRooms, update message status
      const messageIndex = receiverRoom?.messages?.findIndex((m) => m.id === id) ?? -1;

      if (messageIndex > -1 && state.loadedRooms[chatRoom]) {
        const updatedMessages = [...state.loadedRooms[chatRoom].messages!];
        updatedMessages[messageIndex] = { ...updatedMessages[messageIndex], ...message };
        state.loadedRooms[chatRoom] = {
          ...state.loadedRooms[chatRoom],
          messages: updatedMessages,
        };
      }
    },
  },
});

export const {
  toggleChat,
  joinRoom,
  setRooms,
  loadJoinedRoomMessages,
  handleMessageCome,
  setRoomUnreadMessageCount,
  setUserOnline,
  setIsOnMessageReady,
  setSafeCheckObject,
  resetChatState,
  resetChatStateButKeepShowChat,
  handleOnDeletedMessage,
} = chatSlice.actions;

export default chatSlice.reducer;
