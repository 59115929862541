import { combineReducers } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { api } from 'services/api';
import appReducer from './appSlice';
import authReducer from './authSlice';
import practicesReducer from './practicesSlice';
import discountsReducer from './discountsSlice';
import eduContentForDentistReducer from './eduContentForDentistSlice';
import chatReducer from '../components/chat/redux/chatSlice';
import socketReducer from './socketSlice';
import downloadProgressReducer from './downloadProgressSlice';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth'],
};

const reducers = combineReducers({
  [api.reducerPath]: api.reducer,
  app: appReducer,
  auth: authReducer,
  practices: practicesReducer,
  discounts: discountsReducer,
  eduContent: eduContentForDentistReducer,
  chat: chatReducer,
  socket: socketReducer,
  downloadProgress: downloadProgressReducer,
});

export const middlewares = [api.middleware];

export default persistReducer(persistConfig, reducers);
