import { s3Upload } from 'services/s3-api/endpoints';
import { message } from 'antd';
import { ILogo } from 'interface/practices';

interface ILogoFile {
  name: string;
  type: string;
}

export const uploadLogo = async (logo: ILogoFile, getSignedUrl: Function): Promise<ILogo | undefined> => {
  const fileData: any = {
    fileName: logo.name,
    mimeType: logo.type,
    folderName: 'practice',
  };
  try {
    const signedUrl = await getSignedUrl(fileData).unwrap();
    if (signedUrl) {
      await s3Upload(signedUrl.url, logo);
    }
    const imageUrl = signedUrl?.url?.split('?')[0];
    return {
      url: imageUrl,
      modelName: 'PRACTICE',
      name: 'logo1',
      type: logo?.type,
    };
  } catch (error) {
    message.error('Error on uploading logo');
    return undefined;
  }
};
