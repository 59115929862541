import { NiceSelect, INiceSelectProps } from 'components/nice-components/nice-select/nice-select';
import { EFormName, useMetaOption } from 'hooks/useMetaOptions';
import { FC, useCallback, useMemo } from 'react';
import { IMetaOption } from 'services/apiTypes';

export interface IMetaOptionsProps extends Omit<INiceSelectProps, 'options' | 'onBlur' | 'onChange' | 'onFocus'> {
  formName: EFormName;
  optionKey: string;
  onChange?: (e: any) => void;
  getFilteredOptions?: (options: IMetaOption[]) => IMetaOption[];
}

export const MetaOptionsInput: FC<IMetaOptionsProps> = (props) => {
  const { formName, optionKey, onChange, getFilteredOptions, ...rest } = props;
  const { data: optionData, getOptions } = useMetaOption(formName);

  const options = useMemo(() => {
    const optionList = getOptions(optionKey) || [];
    return getFilteredOptions ? getFilteredOptions(optionList) : optionList;
  }, [optionData, optionKey]);

  const handleChange = useCallback((value: string) => onChange?.(value ?? null), []);

  return (
    <NiceSelect
      className="min-w-[200px]"
      placeholder="Please Select"
      allowClear
      showArrow
      loading={!options.length}
      options={options}
      onChange={handleChange}
      {...rest}
    />
  );
};
