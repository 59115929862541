import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Button, Typography } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { getReadableFileSizeString } from './utils';
import { NiceAntModal } from 'components/nice-components/nice-ant-modal/nice-ant-modal';

interface IExtraOralModalPopUpProps {
  onClose?: () => void;
  onDelete?: () => void;
  onContinue?: () => void;
  files: any[];
}

export const FileSizeWarnPopUp = NiceModal.create<IExtraOralModalPopUpProps>(
  ({ onClose = () => {}, onDelete = () => {}, onContinue = () => {}, files = [] }) => {
    const modal = useModal();

    const closeModal = () => {
      modal.hide();
      modal.remove();
    };

    const handleDelete = () => {
      closeModal();
      onDelete();
    };

    const handleContinue = () => {
      closeModal();
      onContinue();
    };

    return (
      <NiceAntModal
        width={700}
        modal={modal}
        title={
          <>
            <ExclamationCircleOutlined />
            &nbsp;Please check your STLs
          </>
        }
        onCancel={() => {
          closeModal();
          onClose();
        }}
        style={{
          maxWidth: 600,
        }}
        footer={
          <div className="flex flex-row items-center justify-end gap-2">
            <Button size="large" onClick={handleDelete} style={{ width: 200 }}>
              Delete files
            </Button>

            <Button type="primary" size="large" onClick={handleContinue} style={{ width: 200 }}>
              Continue
            </Button>
          </div>
        }
      >
        <Typography.Paragraph>It appears that the following files are quite small</Typography.Paragraph>
        <ul>
          {files.map((file: any) => (
            <li>
              {file.name} {getReadableFileSizeString(file.size)}
            </li>
          ))}
        </ul>
        <Typography.Paragraph>Please check they are the correct STL files</Typography.Paragraph>
      </NiceAntModal>
    );
  },
);
