import {
  DashboardOutlined,
  DotChartOutlined,
  SettingOutlined,
  SolutionOutlined,
  DiffOutlined,
  DisconnectOutlined,
  FormOutlined,
  ReadOutlined,
  TeamOutlined,
  CalculatorOutlined,
  UserOutlined,
  PayCircleOutlined,
  UserAddOutlined,
  ShopOutlined,
  UsergroupAddOutlined,
  ApartmentOutlined,
  CalendarOutlined,
  DropboxOutlined,
  RetweetOutlined,
  SwapOutlined,
  FileDoneOutlined,
  CameraOutlined,
} from '@ant-design/icons';
import { tabLabelNavigation } from 'utils/ui';

export enum ResourceType {
  DASHBOARD = 'dashboard',
  OPERATION = 'operation',
  OPERATION_SUBMISSION = 'operation_submission',
  OPERATION_SUBMISSION_NOTE = 'operation_submission_note',
  OPERATION_SUBMISSION_INSTRUCTION = 'operation_submission_instruction',
  OPERATION_SUBMISSION_STL_REPORT = 'operation_submission_stl_report',
  OPERATION_SUBMISSION_NON_SUITABLE = 'operation_submission_non_suitable',
  OPERATION_TIMELINE = 'operation_timeline',
  OPERATION_ORDER_SUMMARY = 'operation_order_summary',
  OPERATION_TREATMENT_DESIGN = 'operation_treatment_design',
  OPERATION_TREATMENT_DESIGN_REVISION_REQUEST = 'operation_treatment_design_revision_request',
  OPERATION_TREATMENT_DESIGN_SKU = 'operation_treatment_design_sku',
  OPERATION_TREATMENT_DESIGN_ORDER = 'operation_treatment_design_order',
  OPERATION_TREATMENT_DESIGN_ADVICE = 'operation_treatment_design_advice',
  OPERATION_TREATMENT_CHECKINS = 'operation_treatment_checkins',
  DISCOUNT = 'discount',
  OUTBOUND_BILL = 'outbound_bill',
  PAYMENT = 'payment',
  DENTIST = 'dentist',
  CORPORATE = 'corporate',
  THIRD_PARTY_DESIGNER = 'third_party_designer',
  THIRD_PARTY_MANUFACTURER = 'third_party_manufacturer',
  THIRD_PARTY_MANUFACTURER_SKU = 'third_party_manufacturer_sku',
  THIRD_PARTY_SPECIALIST = 'third_party_specialist',
  THIRD_PARTY_TECHNICIAN = 'third_party_technician',
  PRACTICE = 'practice',
  HOLIDAY = 'holiday',
  EDUCATION_CONTENT = 'education_content',
  RELAESE_NOTE = 'release_note',
  GROUP = 'group',
  CALCULATOR_COMPLEXITY_CASE_OUTPUT = 'calculator_complexity_case_output',
  CALCULATOR_SPECIFIC_CASE_CALLOUT = 'calculator_specific_case_callout',
  CALCULATOR_PRICING_TABLE = 'calculator_pricing_table',
  USER = 'user',
  FULFILLMENT = 'fulfillment',
  REPLACEMENT = 'replacement',
  RETAINER = 'retainer',
  COMPLETED_TREATMENT = 'completed_treatment',
}

type TMenuSettingItem = {
  key: string;
  icon?: React.ReactNode;
  label: string | React.ReactNode;
  id: string;
  permissionkey?: ResourceType;
  children?: TMenuSettingItem[];
  subkey?: string;
};

const buildMenuItem = (menuItem: TMenuSettingItem): TMenuSettingItem => {
  const { children, label, ...rest } = menuItem;
  return {
    ...rest,
    label: tabLabelNavigation(label as string, rest.key === 'dashboard' ? '/' : `/${rest.key}`),
    ...(children ? { children: children.map((v) => buildMenuItem(v)) } : {}),
  };
};

export const MENU_SETTINGS: TMenuSettingItem[] = [
  {
    key: 'dashboard',
    icon: <DashboardOutlined />,
    label: 'Dashboard',
    id: 'sidebar-menu-dashboard',
    permissionkey: ResourceType.DASHBOARD,
  },
  {
    key: 'operations',
    icon: <SettingOutlined />,
    label: 'Operations',
    id: 'sidebar-menu-operations',
    permissionkey: ResourceType.OPERATION,
  },
  {
    key: 'interested-patients',
    icon: <SolutionOutlined />,
    label: 'Interested Patients',
    id: 'sidebar-menu-interested-patients',
    permissionkey: ResourceType.OPERATION,
  },
  {
    key: 'case-studies',
    icon: <CameraOutlined />,
    label: 'Case Studies',
    id: 'sidebar-menu-case-studies',
    permissionkey: ResourceType.OPERATION,
  },
  {
    key: 'completed-treatments',
    icon: <FileDoneOutlined />,
    label: 'Completed Treatments',
    id: 'sidebar-menu-completed-treatment',
    permissionkey: ResourceType.COMPLETED_TREATMENT,
  },
  {
    key: 'replacements',
    icon: <RetweetOutlined />,
    label: 'Replacements',
    id: 'sidebar-menu-replacements',
    permissionkey: ResourceType.REPLACEMENT,
  },
  {
    key: 'retainers',
    icon: <SwapOutlined />,
    label: 'Retainers',
    id: 'sidebar-menu-retainers',
    permissionkey: ResourceType.RETAINER,
  },
  {
    key: 'discounts',
    icon: <DisconnectOutlined />,
    label: 'Discounts',
    id: 'sidebar-menu-discounts',
    permissionkey: ResourceType.DISCOUNT,
    subkey: 'discount',
  },
  {
    key: 'bills',
    icon: <DiffOutlined />,
    label: 'Outbound Bills',
    id: 'sidebar-menu-bills',
    permissionkey: ResourceType.OUTBOUND_BILL,
  },
  {
    key: 'payments',
    icon: <PayCircleOutlined />,
    label: 'Payments',
    id: 'sidebar-menu-payments',
    permissionkey: ResourceType.PAYMENT,
  },
  {
    key: 'dentists',
    icon: <UserAddOutlined />,
    label: 'Dentists',
    id: 'sidebar-menu-dentists',
    permissionkey: ResourceType.DENTIST,
  },
  {
    key: 'corporates',
    icon: <ApartmentOutlined />,
    label: 'Corporates',
    id: 'sidebar-menu-corporates',
    permissionkey: ResourceType.CORPORATE,
  },
  {
    key: 'practices',
    icon: <UserOutlined />,
    label: 'Practices',
    id: 'sidebar-menu-practices',
    permissionkey: ResourceType.PRACTICE,
  },
  {
    key: 'holidays',
    icon: <CalendarOutlined />,
    label: 'Holidays',
    id: 'sidebar-menu-holidays',
    permissionkey: ResourceType.HOLIDAY,
  },
  {
    key: 'third-parties',
    icon: <ShopOutlined />,
    label: 'Third Parties',
    id: 'sidebar-menu-third-parties',
    children: [
      {
        key: 'designer',
        label: 'Designers',
        id: 'sidebar-menu-designer',
        permissionkey: ResourceType.THIRD_PARTY_DESIGNER,
      },
      {
        key: 'manufacturer',
        label: 'Manufacturers',
        id: 'sidebar-menu-manufacturer',
        permissionkey: ResourceType.THIRD_PARTY_MANUFACTURER,
      },
      {
        key: 'specialist',
        label: 'Specialists',
        id: 'sidebar-menu-specialist',
        permissionkey: ResourceType.THIRD_PARTY_SPECIALIST,
      },
      {
        key: 'technician',
        label: 'Technicians',
        id: 'sidebar-menu-technician',
        permissionkey: ResourceType.THIRD_PARTY_TECHNICIAN,
      },
    ],
  },
  {
    key: 'fulfillments',
    icon: <DropboxOutlined />,
    label: 'Fulfillments',
    id: 'sidebar-menu-fullfillments',
    permissionkey: ResourceType.FULFILLMENT,
  },
  {
    key: 'education-content',
    icon: <ReadOutlined />,
    label: 'Education Content',
    id: 'sidebar-menu-education-content',
    permissionkey: ResourceType.EDUCATION_CONTENT,
  },
  {
    key: 'release-notes',
    icon: <FormOutlined />,
    label: 'Release notes',
    id: 'sidebar-menu-release-notes',
    permissionkey: ResourceType.RELAESE_NOTE,
  },
  {
    key: 'groups',
    icon: <TeamOutlined />,
    label: 'Groups',
    id: 'sidebar-menu-groups',
    permissionkey: ResourceType.GROUP,
  },
  {
    key: 'calculator',
    icon: <CalculatorOutlined />,
    label: 'Calculator',
    id: 'sidebar-menu-calculator',
    permissionkey: ResourceType.CALCULATOR_COMPLEXITY_CASE_OUTPUT,
  },
  {
    key: 'user-settings',
    icon: <UsergroupAddOutlined />,
    label: 'Users',
    id: 'sidebar-menu-user-settings',
    permissionkey: ResourceType.USER,
  },
].map((v) => buildMenuItem(v));
