import { buildQueryString } from 'services/utils';
import { api, VERSION } from 'services/api';
import {
  EUserStatus,
  TAssignableRequest,
  TDentistTypeItem,
  TAssignableUser,
  TUsersResponse,
  TGetUsersRequest,
} from './userTypes';
import { TUserTypeLowerCase } from 'config/constants';
import { buildCommonSubmissionQuery } from 'services/operations/operationsApi';
import { TSubmissionsRequest, TSubmissionsResponse } from 'services/operations/operationsTypes';

const buildUserListProviderTags = (
  userType: TUserTypeLowerCase,
  result?: TUsersResponse,
): { type: 'Users'; id: string }[] =>
  result
    ? [
        ...result.docs.map(({ id }: any) => ({ type: 'Users' as const, id })),
        { type: 'Users', id: `${userType}'PARTIAL-LIST` },
      ]
    : [{ type: 'Users', id: `${userType}'PARTIAL-LIST` }];

export const userApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getUsers: builder.query<TUsersResponse, TGetUsersRequest>({
      query: ({ role, params }) => {
        const paramsString = buildQueryString(params);
        // return `/${role === USER_ROLES.DENTIST ? 'v2' : VERSION}/admin/users/${role}s?${paramsString}`;
        return `/${VERSION}/admin/users/${role}s?${paramsString}`;
      },
      providesTags: (result, _error, params) => buildUserListProviderTags(params.role, result),
    }),
    getUser: builder.query<TDentistTypeItem, { userId: string }>({
      query: ({ userId }) => `/${VERSION}/admin/users/${userId}`,
      providesTags: (_result, _error, params) => [{ type: 'Users', id: params.userId }],
    }),
    inviteDentist: builder.mutation<any, { ids: string[]; doNotSendEmail?: boolean }>({
      query(body) {
        return {
          url: `/${VERSION}/admin/users/invite-dentist`,
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: (_result, _error, params) => params.ids.map((id) => ({ type: 'Users', id })),
    }),
    getAssignableUser: builder.query<{ docs: TAssignableUser[]; total: number }, TAssignableRequest>({
      query({ page, perPage, submission, q, role, sort, order }) {
        const paramsString = buildQueryString({ page, perPage, submission, q, role, sort, order });

        return `/${VERSION}/admin/users/assignable?${paramsString}`;
      },
    }),
    updateUserData: builder.mutation<any, { userId: string; payload: any }>({
      query({ userId, payload }) {
        return {
          url: `/${VERSION}/admin/users/${userId}`,
          method: 'PUT',
          body: payload,
        };
      },
      invalidatesTags: (_result, _error, params) => [{ type: 'Users', id: params.userId }],
    }),
    updateUserStatus: builder.mutation<void, { id: string; status: EUserStatus | 'ACTIVE' }>({
      query: ({ id, status }) => ({
        url: `/${VERSION}/admin/users/${id}/update-status`,
        method: 'PUT',
        body: { status },
      }),
      invalidatesTags: (_result, _error, params) => [{ type: 'Users', id: params.id }],
    }),
    getUserSubmissions: builder.query<TSubmissionsResponse, TSubmissionsRequest>({
      query: ({ userId, ...restParams }) => {
        const queryString = buildCommonSubmissionQuery(restParams);
        return `/${VERSION}/admin/users/${userId}/submissions?${queryString}`;
      },
    }),
  }),
});

export const {
  useGetUsersQuery,
  useGetUserQuery,
  useInviteDentistMutation,
  useGetAssignableUserQuery,
  useUpdateUserDataMutation,
  useUpdateUserStatusMutation,
  useGetUserSubmissionsQuery,
} = userApi;
