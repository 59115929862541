import { Result, Tooltip } from 'antd';
import { TooltipPlacement } from 'antd/lib/tooltip';
import { useAllowPermission } from 'hooks/useAllowPermission';
import { IRoleOption, TPermissionResourceKey } from 'pages/users/utils';
import { FC } from 'react';

interface IProps {
  children: any;
  resourceKey: TPermissionResourceKey;
  actionKey: keyof IRoleOption;
  restrictionType?: 'hidden' | 'unActionable' | 'restrictionMessage';
  tooltipPlacement?: TooltipPlacement | undefined;
  userWhiteList?: string[];
}

export const PermissionCheck: FC<IProps> = ({
  resourceKey,
  actionKey,
  children,
  restrictionType = 'hidden',
  tooltipPlacement,
  userWhiteList,
}) => {
  const [isAllow] = useAllowPermission({ resourceKey, actionKeys: [actionKey], userWhiteList });

  if (isAllow) return children || null;

  if (restrictionType === 'unActionable')
    return (
      <Tooltip title="You are not allowed" placement={tooltipPlacement}>
        <span className="block cursor-not-allowed">
          <span className="pointer-events-none">{children}</span>
        </span>
      </Tooltip>
    );

  if (restrictionType === 'restrictionMessage')
    return <Result status="403" title="403" subTitle="Sorry, you are not authorized to access this page." />;
  return null;
};
