import mixpanel from 'mixpanel-browser';
import { EEventNames } from './events';

export const initMixpanel = () => {
  if (process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN) {
    mixpanel.init(
      process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN,
      {
        debug: true,
        persistence: 'localStorage',
        ignore_dnt: true,
      },
      'mixpanel',
    );
  }
};

export const trackEvent = (eventName: EEventNames, properties = {}) => {
  mixpanel.track(eventName, properties);
};

export const trackTimeEvent = (eventName: EEventNames) => {
  mixpanel.time_event(eventName);
};

export const identifyUser = (userId: string, properties = {}) => {
  mixpanel.identify(userId);
  mixpanel.people.set(properties);
};

export const trackPageView = (pageName: string) => {
  mixpanel.track_pageview({ page: pageName });
};

export const endUserSession = () => {
  mixpanel.reset();
};
