import './dashboard-layout.styles.less';
import { Avatar, Dropdown, Layout, Menu, MenuProps, Spin, Tooltip, Typography } from 'antd';
import {
  DoubleRightOutlined,
  DoubleLeftOutlined,
  LogoutOutlined,
  UserOutlined,
  UnlockOutlined,
} from '@ant-design/icons';
import { FC, useEffect, useState, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ReactComponent as MidLogoSvg } from 'assets/images/layout/mid-logo.svg';
import { ReactComponent as SmallLogoSvg } from 'assets/images/layout/small-logo.svg';
import { useAuth } from 'hooks/useAuth';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { usePendo } from 'hooks/usePendo';
import socketService from 'services/socket';
import { setIsConnected } from 'redux/socketSlice';
import { MENU_SETTINGS } from './utils';
import { isArray } from 'lodash';
import { IAllowPermissions } from 'services/auth/authTypes';
import { DownloadProgress } from './download-progress/download-progress';
import classNames from 'classnames';
import NiceModal from '@ebay/nice-modal-react';
import { ResetPasswordModal } from 'pages/users/user-role-permission/reset-password-modal';

const { Header, Sider, Content } = Layout;

type TMenuItem = Required<MenuProps>['items'][number];

export const DashboardLayout: FC<{ children: any }> = ({ children }) => {
  const { user, userLogout, isUserLogoutLoading, isSuperAdmin } = useAuth();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { progressList, display } = useAppSelector((state) => state.downloadProgress);
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(false);
  usePendo(user);

  const menuItemsWithPermissions = useMemo(() => {
    if (!Array.isArray(user?.permissions?.allow)) {
      return [];
    }

    return MENU_SETTINGS.map((menuItem) => {
      const allowedPermissions = user?.permissions?.allow;

      if (menuItem.children) {
        const childrenWithPermissions = menuItem.children.filter(
          (child) =>
            isArray(allowedPermissions) &&
            allowedPermissions?.some((permission: 'admin' | IAllowPermissions) => {
              if (isSuperAdmin) {
                return true;
              }
              if (typeof permission === 'string') {
                return false;
              }
              return (
                permission.resource === child.permissionkey &&
                Array.isArray(permission.actions) &&
                permission.actions.includes('read')
              );
            }),
        );

        return childrenWithPermissions.length > 0 ? { ...menuItem, children: childrenWithPermissions } : null;
      }

      const hasPermission =
        isArray(allowedPermissions) &&
        allowedPermissions?.some((permission: 'admin' | IAllowPermissions) => {
          if (isSuperAdmin) {
            return true;
          }
          if (typeof permission === 'string') {
            return false;
          }
          return (
            permission.resource === menuItem.permissionkey &&
            Array.isArray(permission.actions) &&
            permission.actions.includes('read')
          );
        });

      return hasPermission ? menuItem : null;
    }).filter(Boolean);
  }, [user?.permissions?.allow]);

  const [activeMenu, setActiveMenu] = useState(menuItemsWithPermissions[0]);

  useEffect(() => {
    if (history.location.pathname === '/signin' || history.location.pathname === '/') {
      // redirect to the first available menu item
      if (menuItemsWithPermissions?.length > 0) {
        const { children: permissionChildren, key } = menuItemsWithPermissions[0] || {};
        const targetMenuPath = permissionChildren ? permissionChildren?.[0]?.key : key;
        history.replace(`/${targetMenuPath}`);
      }
    }
  }, [menuItemsWithPermissions]);

  const toggleSidebar = () => {
    setCollapsed((c) => !c);
  };

  const connectSocket = async () => {
    if (!user?.id) return;

    await socketService
      .connect(user?.id)
      .then((socket) => {
        console.log('socket connected', socket);
        dispatch(setIsConnected(true));
      })
      .catch((err) => {
        console.log('Error: ', err);
      });
  };

  const disconnectSocket = () => {
    console.log('socket disconnected');
    dispatch(setIsConnected(false));
    socketService.disconnect();
  };

  useEffect(() => {
    if (user?.id) {
      connectSocket();

      socketService.socket?.on('connect', () => {
        console.log('connected called');
        dispatch(setIsConnected(true));
      });

      socketService.socket?.on('disconnect', () => {
        console.log('disconnected called');
        dispatch(setIsConnected(false));
      });

      window.addEventListener('online', connectSocket);
      window.addEventListener('offline', disconnectSocket);

      // clear the listerens
      return () => {
        disconnectSocket();
        window.removeEventListener('online', connectSocket);
        window.removeEventListener('offline', disconnectSocket);
        socketService.socket?.off('connected');
        socketService.socket?.off('disconnected');
      };
    }
    return () => {};
  }, [user?.id]);

  useEffect(() => {
    const path = location.pathname;
    let actMenu = menuItemsWithPermissions[0];

    menuItemsWithPermissions.forEach((menu) => {
      if (menu?.children) {
        const findActMenu = menu?.children?.find(
          (child) => path.includes(child.key) || (menu.subkey && path.includes(menu.subkey)),
        );
        if (findActMenu) actMenu = findActMenu as any;
      } else if (menu && path.includes(menu.key)) actMenu = menu;
    });
    setActiveMenu(actMenu);
  }, [location]);

  return (
    <Layout className="layout custom-navbar">
      <Sider
        className="sidebar z-10 !w-[212px] overflow-hidden border-0 border-r border-solid border-gray-200"
        trigger={null}
        collapsible
        collapsed={collapsed}
      >
        <div className="mb-2 mt-4 flex justify-center">
          {collapsed ? <SmallLogoSvg height="36px" /> : <MidLogoSvg height="36px" />}
        </div>
        <div className="mt-6">
          {activeMenu && (
            <Menu
              theme="light"
              mode="inline"
              defaultSelectedKeys={[activeMenu.key]}
              selectedKeys={[activeMenu.key]}
              items={menuItemsWithPermissions as TMenuItem[]}
              className="border-0 text-gray-500"
            />
          )}
        </div>

        <div
          className="fixed bottom-0 left-0 cursor-pointer items-center justify-end p-4 text-lg md:flex"
          onClick={toggleSidebar}
          style={{
            width: '60px',
            left: collapsed ? 20 : 140,
            transition: 'width 0.2s',
          }}
        >
          {collapsed ? <DoubleRightOutlined /> : <DoubleLeftOutlined />}
        </div>
      </Sider>
      <Layout className={`site-layout ${collapsed ? 'side-nav-collapsed' : ''}`}>
        <Header
          className="app-header fixed border-0 border-b border-r-0 border-solid border-gray-200 !bg-white !px-7"
          style={{ zIndex: 999 }}
        >
          <div className="flex items-center justify-between">
            <span className="text m-0 text-lg font-semibold">{activeMenu?.label}</span>
            <div>
              <div className="flex items-center gap-2">
                <Dropdown
                  menu={{
                    items: [
                      {
                        key: 'menu',
                        label: (
                          <div className="w-32" id="logout-button" onClick={userLogout}>
                            Logout
                          </div>
                        ),
                        icon: isUserLogoutLoading ? <Spin /> : <LogoutOutlined />,
                      },
                      {
                        key: 'profile',
                        label: (
                          <div
                            className="w-32"
                            id="logout-button"
                            onClick={() =>
                              NiceModal.show(ResetPasswordModal, {
                                roleId: user?.id || '',
                                visible: true,
                                isProfileResetPassword: true,
                              })
                            }
                          >
                            Reset Password
                          </div>
                        ),
                        icon: <UnlockOutlined />,
                      },
                    ],
                  }}
                  trigger={['click']}
                  placement="bottom"
                >
                  <div className="profile cursor-pointer">
                    <Avatar size="default" src={user?.profilePic} className="border border-solid border-slate-400">
                      <Avatar icon={<UserOutlined />} />
                    </Avatar>
                  </div>
                </Dropdown>
                <Tooltip title={`${user?.firstName} ${user?.lastName}`}>
                  <Typography.Text className="w-20 truncate text-ellipsis capitalize">{`${user?.firstName} ${user?.lastName}`}</Typography.Text>
                </Tooltip>
              </div>
            </div>
          </div>
        </Header>
        <Content className="content-section mt-15 bg-white">{children}</Content>
        {progressList.length > 0 && (
          <div className={classNames(display ? '' : 'hidden')}>
            <DownloadProgress />
          </div>
        )}
      </Layout>
    </Layout>
  );
};
