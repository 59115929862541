import { useHistory } from 'react-router-dom';
import { logout, selectCurrentUser } from 'redux/authSlice';
import { api } from 'services/api';
import { useSignOutMutation } from 'services/auth/authApi';
import { useAppDispatch, useAppSelector } from './store';

export const useAuth = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectCurrentUser);
  const [signOut, { isLoading: isSignOutLoading }] = useSignOutMutation();

  const userLogout = async () => {
    try {
      await signOut().unwrap();
    } finally {
      dispatch(logout());
      history.push('');
      dispatch(api.util.resetApiState());
    }
  };

  const isSuperAdmin: boolean = user?.permissions?.allow.some((allowItem: any) => allowItem === 'admin') ?? false;

  return {
    userLogout,
    isUserLogoutLoading: isSignOutLoading,
    isSuperAdmin,
    user,
  };
};
