/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { TRootState } from './store';
import { IUser } from 'services/auth/authTypes';

type TAuthState = {
  user: IUser | null;
};

const slice = createSlice({
  name: 'auth',
  initialState: { user: null } as TAuthState,
  reducers: {
    setCredentials: (state, { payload: { user } }: PayloadAction<{ user: IUser }>) => {
      state.user = user;
    },
    updateUserData: (state, { payload: user }: PayloadAction<IUser>) => {
      state.user = user;
    },
    logout: (state) => {
      state.user = null;
    },
  },
});

export const { setCredentials, updateUserData, logout } = slice.actions;

export default slice.reducer;

export const selectCurrentUser = (state: TRootState) => state.auth.user;
