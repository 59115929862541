import { Input, Space, Button, InputRef } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import { FC, ReactNode, RefObject } from 'react';
import Highlighter from 'react-highlight-words';
import { ITableColumnProperties } from 'hooks/useTableSettings';

export const getColumnSearchProps = <T,>({
  searchInputRef,
  value,
  wrapper: Wrapper,
  searchPlaceHolder = 'Search',
}: {
  searchInputRef: RefObject<InputRef>;
  value?: string;
  wrapper?: FC<{ text: string; record: T; children: ReactNode }>;
  searchPlaceHolder?: string;
}): Partial<ITableColumnProperties<T>> => {
  return {
    filteredValue: value ? [value] : [],
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInputRef}
          placeholder={searchPlaceHolder}
          value={selectedKeys[0] || ''}
          onChange={(e) => {
            const inputValue = e.target.value.replace(/[^\w\s-]/gi, '');
            setSelectedKeys(inputValue ? [inputValue] : []);
          }}
          onPressEnter={() => confirm()}
          className="mb-2 block"
        />
        <Space>
          <Button
            disabled={!value}
            onClick={() => {
              clearFilters?.();
              confirm();
            }}
            className="w-20"
            size="small"
          >
            Reset
          </Button>
          <Button
            type="primary"
            onClick={() => confirm()}
            icon={<SearchOutlined />}
            disabled={!selectedKeys[0]}
            size="small"
          >
            Search
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined className={classNames('p-2 text-lg', filtered && 'text-primaryColor')} />
    ),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInputRef.current?.select(), 100);
      }
    },
    render: (text, record) => {
      const highlighterElm = (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={value ? [value] : []}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      );

      return Wrapper ? (
        <Wrapper text={text} record={record}>
          {highlighterElm}
        </Wrapper>
      ) : (
        highlighterElm
      );
    },
  };
};
