import { Tooltip, Badge } from 'antd';
import classNames from 'classnames';

interface IProps {
  user: any;
  color?: string;
  textColor?: string;
  onClick?: () => any;
}

const ColumnUserList = ({ user, color, textColor, onClick }: IProps) =>
  user ? (
    <Badge size="default" className="w-full">
      <div
        className={classNames('flex items-center justify-center', { 'cursor-pointer': onClick })}
        style={{
          height: '2rem',
          backgroundColor: color,
          color: textColor,
          borderRadius: '2rem',
          textOverflow: 'ellipsis',
        }}
        onClick={onClick}
      >
        <Tooltip className="format px-4 capitalize" title={user}>
          {user}
        </Tooltip>
      </div>
    </Badge>
  ) : null;

export default ColumnUserList;
