import { Switch, Route } from 'react-router-dom';
import PrivateRoute from './components/PrivateRoute';
import UnProtectedRoute from './components/UnProtectedRoute';
import { DashboardLayout } from './layouts/dashboard/dashboard-layout';
import { protectedRoutes, unProtectedRoutes } from './routes';
import { NotFound } from './components/not-found/not-found';

const App = () => (
  <Switch>
    {unProtectedRoutes.map((route) => (
      <UnProtectedRoute
        key={route.key}
        path={route.path}
        exact={route.exact}
        name={route.name}
        component={route.component}
      />
    ))}
    <Route path={protectedRoutes.map((route) => route.path)}>
      <DashboardLayout>
        {protectedRoutes.map((route) => (
          <PrivateRoute
            key={route.key}
            path={route.path}
            exact={route.exact}
            name={route.name}
            component={route.component}
          />
        ))}
      </DashboardLayout>
    </Route>
    <Route path="*" component={NotFound} />
  </Switch>
);

export default App;
