import { useEffect, FC } from 'react';
import { Form, AutoComplete, FormItemProps } from 'antd';
import usePlacesAutocomplete, { getGeocode, getLatLng, getZipCode } from 'use-places-autocomplete';
import { IAddress } from 'interface/practices';

interface IAddressFieldProps extends Omit<FormItemProps, 'validateTrigger'> {
  setFieldValue: (address: IAddress) => void;
  placeholder?: string;
  disabled?: boolean;
  initValue?: string;
}

export const AddressField: FC<IAddressFieldProps> = ({
  setFieldValue,
  rules,
  label,
  name,
  placeholder,
  className,
  disabled,
  initValue,
}) => {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {},
    debounce: 300,
  });

  useEffect(() => {
    if (initValue && !value) {
      setValue(initValue, false);
    }
  }, [initValue]);

  const handleAddressChange = (val: any) => {
    setValue(val);
  };

  const handleAddressSelect = ({ description }: any) => {
    clearSuggestions();
    getGeocode({ address: description })
      .then(async (results) => {
        const latLng = await getLatLng(results[0]);
        const zipCode = await getZipCode(results[0], false);
        return { ...latLng, zipCode };
      })
      .then(({ lat, lng, zipCode }) => {
        const address: IAddress = {
          address1: description,
          coordinates: [lat, lng],
          postCode: zipCode,
        };

        setFieldValue(address);
      })
      .catch((error) => {
        console.log('😱 Error: ', error);
      });
  };

  return (
    <Form.Item name={name} label={label} rules={rules}>
      <AutoComplete
        className={className}
        value={value}
        onChange={handleAddressChange}
        disabled={!ready || disabled}
        placeholder={placeholder}
      >
        {status === 'OK' &&
          data.map((suggestion) => {
            const {
              place_id,
              structured_formatting: { main_text, secondary_text },
            } = suggestion;
            return (
              <AutoComplete.Option
                className="places-options"
                key={place_id}
                onClick={() => handleAddressSelect(suggestion)}
                value={`${main_text} ${secondary_text || ''}`}
              >
                {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
                <li key={place_id} onClick={() => handleAddressSelect(suggestion)}>
                  <strong>{main_text}</strong> <small>{secondary_text}</small>
                </li>
              </AutoComplete.Option>
            );
          })}
      </AutoComplete>
    </Form.Item>
  );
};
