/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-no-undef */
import { Select, SelectProps, Tooltip } from 'antd';
import { FC, useCallback, useMemo } from 'react';
import { DisplayValueType } from 'rc-select/lib/BaseSelect';

export interface INiceSelectProps extends Omit<SelectProps, 'onBlur' | 'onChange' | 'onFocus'> {
  onChange?: (e: any) => void;
  hasNAOption?: boolean;
  hasSelectAll?: boolean;
}

const NA_DEFAULT_VALUE: any = 'NA_DEFAULT_VALUE';
const SELECT_ALL_DEFAULT_VALUE = 'SELECT_ALL_DEFAULT_VALUE';

export const NiceSelect: FC<INiceSelectProps> = ({
  options,
  onChange,
  hasNAOption = false,
  hasSelectAll = false,
  ...rest
}) => {
  const presentOptions = useMemo(
    () => [
      ...(hasNAOption ? [{ label: 'N/A', value: NA_DEFAULT_VALUE }] : []),
      ...(hasSelectAll ? [{ label: 'Select All', value: SELECT_ALL_DEFAULT_VALUE }] : []),
      ...(options || []),
    ],
    [options, hasNAOption, hasSelectAll],
  );

  const presentValue = useMemo(
    () => (hasNAOption && rest.value?.length === 0 ? [NA_DEFAULT_VALUE] : rest.value),
    [rest.value, hasNAOption],
  );

  const handleChange = useCallback(
    (newValue) => {
      if (rest.mode === 'multiple') {
        const lastSelectValue = (newValue || []).at(-1);
        switch (lastSelectValue) {
          case SELECT_ALL_DEFAULT_VALUE:
            onChange?.(options?.map((item) => item.value));
            break;
          case NA_DEFAULT_VALUE:
            onChange?.([]);
            break;
          default:
            onChange?.(
              newValue?.filter((item: string) => ![SELECT_ALL_DEFAULT_VALUE, NA_DEFAULT_VALUE].includes(item)) ?? null,
            );
            break;
        }
      } else {
        onChange?.(newValue);
      }
    },
    [hasNAOption, hasSelectAll, options],
  );

  return (
    <Select
      placeholder="Please Select"
      allowClear
      showArrow
      loading={!options?.length}
      options={presentOptions}
      onChange={handleChange}
      maxTagCount="responsive"
      maxTagPlaceholder={(omittedValues) => (
        <Tooltip
          title={omittedValues?.map((v: DisplayValueType) => (
            <div>{v.label}</div>
          ))}
        >
          +{omittedValues?.length}...
        </Tooltip>
      )}
      {...rest}
      value={presentValue}
    />
  );
};
