import { APP_ENV } from 'config/constants';
import { useEffect } from 'react';
import { IUser } from 'services/auth/authTypes';

export const usePendo = (user: IUser | null) => {
  useEffect(() => {
    if (user?.id && APP_ENV === 'prod') {
      const { id, email, ...restInfo } = user || {};
      (window as any).pendo?.initialize?.({
        visitor: {
          id: user.id,
          email: user.email,
          full_name: `${user.firstName} ${user.lastName}`,
          env: APP_ENV,
          emailObject: email,
          ...restInfo,
        },

        account: {
          id: user.id,
          name: user.fullName,
        },
      });
    }
  }, [user?.id]);
};
