import cn from 'classnames';
import { FC } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import './styles.less';
import { IMobileNumberInputValue } from './utils';

interface IProps {
  value?: IMobileNumberInputValue;
  onChange?: (value: IMobileNumberInputValue) => void;
  numberPlaceholder?: string;
  inputClassName?: string;
  disabled?: boolean;
  typeMode?: 'string' | 'number';
}

export const MobileNumberInput: FC<IProps> = (props) => {
  const {
    numberPlaceholder = 'Enter phone number',
    value,
    onChange,
    inputClassName = '',
    disabled,
    typeMode = 'string',
  } = props;

  const inputValue = value?.ext ? `${value.ext.replace('+', '')}${value.number || ''}` : null;

  const handleChange = (phone: string, country: { dialCode: string }) => {
    let ext: string | undefined;
    let number: string | number | undefined;
    if (country?.dialCode && phone?.length < country?.dialCode?.length) {
      // clear country once user hit backspace dial code
      ext = undefined;
      number = undefined;
    } else {
      ext = `+${country?.dialCode}`;
      number = `+${phone}`.replace(ext, '');
    }

    if (number && typeMode === 'number') {
      number = parseInt(number, 10);
    }
    onChange?.({ ext, number });
  };

  return (
    <PhoneInput
      containerClass={cn('ant-input mobile-number-input-container', inputClassName)}
      inputClass="mobile-number-input ant-input"
      buttonClass="mobile-number-button"
      dropdownClass="mobile-number-dropdown"
      value={inputValue}
      onChange={handleChange}
      placeholder={numberPlaceholder}
      searchPlaceholder="Search country"
      enableSearch
      disabled={disabled}
    />
  );
};
