/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { TRootState } from './store';

type TDentistsState = {
  page: number | undefined;
  perPage: number | undefined;
  searchStr: string;
  assignRecord?: any;
  sort?: string | undefined;
  order?: string | undefined;
};

const initState: TDentistsState = { page: 1, perPage: 10, searchStr: '', assignRecord: {}, sort: '', order: '' };

const slice = createSlice({
  name: 'practices',
  initialState: initState,
  reducers: {
    setPginationData: (
      state,
      { payload: { page, perPage } }: PayloadAction<{ page: number | undefined; perPage: number | undefined }>,
    ) => {
      state.page = page;
      state.perPage = perPage;
    },
    setSearchStr: (state, { payload: { q } }: PayloadAction<{ q: string }>) => {
      state.page = 1;
      state.searchStr = q;
    },
    reset: () => initState,
    setFilters: (
      state,
      { payload: { sort, order } }: PayloadAction<{ sort: string | undefined; order: string | undefined }>,
    ) => {
      state.sort = sort;
      state.order = order;
    },
  },
});

export const { setPginationData, setSearchStr, reset, setFilters } = slice.actions;

export default slice.reducer;

export const selectCurrentPage = (state: TRootState) => state.practices.page;
export const selectPageSize = (state: TRootState) => state.practices.perPage;
export const selectSearchStr = (state: TRootState) => state.practices.searchStr;
export const setPracticesFilters = (state: TRootState) => state.practices.sort;
export const selectOrder = (state: TRootState) => state.practices.order;
