import { Badge, Image } from 'antd';
import NotificationIcon from 'assets/images/admin/Notification.svg';
import { ISubmission } from 'interface/operations';
import { FC } from 'react';
import { useHistory } from 'react-router-dom';

export const NotificationsColumn: FC<{ record: ISubmission; path?: string }> = ({ record, path }) => {
  const history = useHistory();

  const handleNotificationClick = async () => {
    if (record?.id) {
      history?.push(path || `/operations/${record.id}?open=message_box`);
    }
  };

  if (!record?.newMessageAttentionRequired) {
    return null;
  }

  return (
    <div className="mt-1 flex cursor-pointer items-center justify-center" onClick={handleNotificationClick}>
      <Badge className="mt-2" size="small" dot>
        <Image preview={false} width={20} src={NotificationIcon} />
      </Badge>
    </div>
  );
};
