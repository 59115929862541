import { ITableParams } from 'interface/common';

const { REACT_APP_ENV, REACT_APP_HUBSPOT_PORTAL_ID } = process.env;

export enum EUserRoles {
  DENTIST = 'DENTIST',
  DESIGNER = 'DESIGNER',
  SPECIALIST = 'SPECIALIST',
  MANUFACTURER = 'MANUFACTURER',
  ADMIN = 'ADMIN',
  TECHNICIAN = 'TECHNICIAN',
}

export const USER_ROLES = {
  DENTIST: 'dentist',
  DESIGNER: 'designer',
  SPECIALIST: 'specialist',
  MANUFACTURER: 'manufacturer',
  ADMIN: 'admin',
  TECHNICIAN: 'technician',
} as const;

type TUserKeys = keyof typeof USER_ROLES;
export type TUserTypeLowerCase = (typeof USER_ROLES)[TUserKeys];

export const APP_ENV = REACT_APP_ENV || 'dev';

export const API_STATUS_CODES = {
  BAD_REQUEST: 400,
  SUCCESS: 200,
  FORBIDDEN: 403,
};

export const hubspotNotesURL = (hubspotContactId: string) =>
  `https://app-eu1.hubspot.com/contacts/${REACT_APP_HUBSPOT_PORTAL_ID}/record/0-1/${hubspotContactId}`;

type TStatusConfig = Record<
  string,
  {
    bgColor?: string;
    textColor: string;
    borderColor?: string;
    text: string;
  }
>;

const COMMON_TREATMENT_DESIGN_STATUSES: TStatusConfig = {
  APPROVED: {
    bgColor: '#F6FFED',
    textColor: '#52C41A',
    borderColor: '#B7EB8F',
    text: 'Approved',
  },
  REVISION_REQUESTED: {
    bgColor: '#F9F0FF',
    textColor: '#722ED1',
    borderColor: '#D3ADF7',
    text: 'Revision Requested',
  },
  NO_FURTHER_ACTION: {
    bgColor: '#dddddd80',
    textColor: '#6a6868',
    borderColor: '#555770',
    text: 'No Further Action',
  },
  EXPIRED: {
    bgColor: '#8465FC',
    textColor: '#8465FC',
    borderColor: '#8465FC',
    text: 'Expired',
  },
  REVIEW_DESIGN: {
    textColor: '#3F69FF',
    text: 'Review Design',
  },
};

export const DESIGNER_TREATMENT_DESIGN_STATUSES: TStatusConfig = {
  WAITING_APPROVAL: {
    textColor: '#3F69FF',
    text: 'Awaiting Approval',
  },
  APPROVED: {
    textColor: '#49DCB2',
    text: 'Approved',
  },
  EXPIRED: {
    textColor: '#8465FC',
    text: 'Expired',
  },
  REVISION_REQUESTED: {
    textColor: '#00B1FF',
    text: 'Modification Requested',
  },
  NO_FURTHER_ACTION: {
    textColor: '#8465FC',
    text: 'No Further Action',
  },
};

export const ADMIN_TREATMENT_DESIGN_STATUSES: TStatusConfig = {
  ...COMMON_TREATMENT_DESIGN_STATUSES,
  WAITING_APPROVAL: {
    bgColor: '#F0F5FF',
    textColor: '#2F54EB',
    borderColor: '#ADC6FF',
    text: 'Pending Approval',
  },
};

export const SPECIALIST_TREATMENT_DESIGN_STATUSES: TStatusConfig = {
  ...COMMON_TREATMENT_DESIGN_STATUSES,
  WAITING_APPROVAL: {
    bgColor: '#F0F5FF',
    textColor: '#2F54EB',
    borderColor: '#ADC6FF',
    text: 'Pending Advice',
  },
};

export const DENTIST_TREATMENT_DESIGN_STATUSES: TStatusConfig = {
  ...COMMON_TREATMENT_DESIGN_STATUSES,
  WAITING_APPROVAL: {
    bgColor: '#FFF2E8',
    textColor: '#FA541C',
    borderColor: '#FFBB96',
    text: 'Review Design',
  },
};

export const UK_PHONE_NUMBER_REGEX =
  /\s*(([+](\s?\d)([-\s]?\d)|0)?(\s?\d)([-\s]?\d){9}|[(](\s?\d)([-\s]?\d)+\s*[)]([-\s]?\d)+)\s*/;
export const US_PHONE_NUMBER_REGEX = /^\(?([0-9]{3})\)?[-.●]?([0-9]{3})[-.●]?([0-9]{4})$/;
export const IN_PHONE_NUMBER_REGEX = /^[6-9]{1}[0-9]{9}$/;
export const TUR_PHONE_NUMBER_REGEX =
  /\s*(([+](\s?\d)([-\s]?\d)|0)?(\s?\d)([-\s]?\d){9}|[(](\s?\d)([-\s]?\d)+\s*[)]([-\s]?\d)+)\s*/;

export const YESNO = [
  {
    label: 'Yes',
    value: true,
  },
  {
    label: 'No',
    value: false,
  },
];

export const YES_NO_PREFER_NOT = [
  {
    label: 'Prefer not to',
    value: false,
  },
  {
    label: 'Yes, if required',
    value: true,
  },
];

export const LOWER_DENTAL_CENTER_LINE_OF_CHIN = [
  {
    value: 'coincident',
    label: 'Coincident',
  },
  {
    value: 'shifted_to_left',
    label: 'Shifted to left',
  },
  {
    value: 'shifted_to_right',
    label: 'Shifted to right',
  },
  {
    value: 'not_sure',
    label: 'Not Sure',
  },
];

export const OVERJET_OVERBITE_OR_ANTERIOR = [
  {
    value: 'overbite',
    label: 'Overbite',
  },
  {
    value: 'AOB',
    label: 'AOB',
  },
  {
    value: 'edge_to_edge',
    label: 'Edge to Edge',
  },
  {
    value: 'not_sure',
    label: 'Not Sure',
  },
];

export const OVERJET_INCISOR_RELATIONSHIP = [
  {
    value: 'Class I',
    label: 'Class I',
  },
  {
    value: 'Class II Division I',
    label: 'Class II Division I',
  },
  {
    value: 'Class II Division II',
    label: 'Class II Division II',
  },
  {
    value: 'Class III',
    label: 'Class III',
  },
  {
    value: 'not_sure',
    label: 'Not Sure',
  },
];

export const MOLAR_RELATIONS = [
  {
    value: 'Class I',
    label: 'Class I',
  },
  {
    value: 'Class II',
    label: 'Class II',
  },
  {
    value: 'Class III',
    label: 'Class III',
  },
  {
    value: 'not_sure',
    label: 'Not Sure',
  },
];

export const TXNOBJECTIVE_OPTIONS = [
  {
    label: 'Relief of crowding',
    value: 'Relief of crowding',
  },
  {
    label: 'Levelling',
    value: 'Levelling',
  },
  {
    label: 'Correction of Incisor relationship',
    value: 'Correction of Incisor relationship',
  },
  {
    label: 'Correction of molar relationship',
    value: 'Correction of molar relationship',
  },
  {
    label: 'Maxillary arch expansion',
    value: 'Maxillary arch expansion',
  },
  {
    label: 'Correction of open bite',
    value: 'Correction of open bite',
  },
  {
    label: 'Space closure',
    value: 'Space closure',
  },
  {
    label: 'Facilitate restorative treatment',
    value: 'Facilitate restorative treatment',
  },
];

export const DENTAL_MIDLINE_OPTIONS = [
  {
    label: 'Accept resulting midline after alignment',
    value: 'Accept resulting midline after alignment',
  },
  {
    label: 'Maintain existing (may involve IPR)',
    value: 'Maintain existing (may involve IPR)',
  },
  {
    label: 'Improve (may involve IPR)',
    value: 'Improve (may involve IPR)',
  },
];

export const INCISOR_RELATIONSHIP_OVERJET_OPTIONS = [
  {
    label: 'Accept resulting overjet after alignment',
    value: 'Accept resulting overjet after alignment',
  },
  {
    label: 'Improve overjet (may involve IPR)',
    value: 'Improve overjet (may involve IPR)',
  },
  {
    label: 'Maintain existing overjet (may involve IPR)',
    value: 'Maintain existing overjet (may involve IPR)',
  },
];

export const INCISOR_RELATIONSHIP_OVERBITE_OPTIONS = [
  {
    label: 'Accept resulting overbite after alignment',
    value: 'Accept resulting overbite after alignment',
  },
  {
    label: 'Improve overbite / open bite',
    value: 'Improve overbite / open bite',
  },
  {
    label: 'Maintain existing overbite',
    value: 'Maintain existing overbite',
  },
];

export const ARCH_COORDINATION_OPTIONS = [
  {
    label: 'Maintain',
    value: 'Maintain',
  },
  {
    label: 'Correct',
    value: 'Correct',
  },
];

export const ANTEROPOSTERIOR_RELATIONSHIP_OPTIONS = [
  {
    label: 'Maintain existing',
    value: 'Maintain existing',
  },
  {
    label: 'Improve canine relationships only',
    value: 'Improve canine relationships only',
  },
  {
    label: 'Improve molar relationships and canine relationships',
    value: 'Improve molar relationships and canine relationships',
  },
];

export const RESOLUTION_UPPER_ARCH_CROWDING = [
  {
    label: 'Anterior IPR',
    value: 'Anterior IPR',
  },
  {
    label: 'Posterior IPR',
    value: 'Posterior IPR',
  },
  {
    label: 'Arch Expansion',
    value: 'Arch Expansion',
  },
  {
    label: 'Proclination',
    value: 'Proclination',
  },
];
export const RESOLUTION_LOWER_ARCH_CROWDING = [
  {
    label: 'Anterior IPR',
    value: 'Anterior IPR',
  },
  {
    label: 'Posterior IPR',
    value: 'Posterior IPR',
  },
  {
    label: 'Arch Expansion',
    value: 'Arch Expansion',
  },
  {
    label: 'Proclination',
    value: 'Proclination',
  },
];

export const LOWER_DENTAL_CENTER_LINE_OF_FACE = [
  {
    value: 'coincident',
    label: 'Coincident',
  },
  {
    value: 'lower_to_left',
    label: 'Lower to the left of upper',
  },
  {
    value: 'lower_to_right',
    label: 'Lower to the right of upper',
  },
  {
    value: 'not_sure',
    label: 'Not Sure',
  },
];

export const UPPER_DENTAL_CENTER_LINE_OF_FACE = [
  {
    value: 'coincident',
    label: 'Coincident',
  },
  {
    value: 'shifted_to_left',
    label: 'Shifted to left',
  },
  {
    value: 'shifted_to_right',
    label: 'Shifted to right',
  },
  {
    value: 'not_sure',
    label: 'Not Sure',
  },
];

export const UPLOAD_IMAGE_SECTIONS = [
  {
    title: 'Extra-Oral orthodontic photographs',
    name: 'extraOralImages',
    key: '1',
    minItems: 4,
  },
  {
    title: 'Intra-Oral orthodontic photographs',
    name: 'intraOralImages',
    key: '2',
    minItems: 4,
  },
  {
    title: 'Radiographs',
    name: 'radioGraphImgs',
    key: '3',
    minItems: 1,
  },
];

export const ADDITIONAL_FEATURES = [
  {
    label: 'Overcorrection',
    value: 'Overcorrection',
  },
  {
    label: 'Button cut outs',
    value: 'Button cut outs',
  },
  {
    label: 'Passive aligners',
    value: 'Passive aligners',
  },
];

export const TOOTH_OPTIONS = [
  {
    label: '18 / UR8',
    value: '18/UR8',
  },
  {
    label: '17 / UR7',
    value: '17/UR7',
  },
  {
    label: '16 / UR6',
    value: '16/UR6',
  },
  {
    label: '15 / UR5',
    value: '15/UR5',
  },
  {
    label: '14 / UR4',
    value: '14/UR4',
  },
  {
    label: '13 / UR3',
    value: '13/UR3',
  },
  {
    label: '12 / UR2',
    value: '12/UR2',
  },
  {
    label: '11 / UR1',
    value: '11/UR1',
  },
  {
    label: '21 / UL1',
    value: '21/UL1',
  },
  {
    label: '22 / UL2',
    value: '22/UL2',
  },
  {
    label: '23 / UL3',
    value: '23/UL3',
  },
  {
    label: '24 / UL4',
    value: '24/UL4',
  },
  {
    label: '25 / UL5',
    value: '25/UL5',
  },
  {
    label: '26 / UL6',
    value: '26/UL6',
  },
  {
    label: '27 / UL7',
    value: '27/UL7',
  },
  {
    label: '28 / UL8',
    value: '28/UL8',
  },
  {
    label: '38 / LL8',
    value: '38/LL8',
  },
  {
    label: '37 / LL7',
    value: '37/LL7',
  },
  {
    label: '36 / LL6',
    value: '36/LL6',
  },
  {
    label: '35 / LL5',
    value: '35/LL5',
  },
  {
    label: '34 / LL4',
    value: '34/LL4',
  },
  {
    label: '33 / LL3',
    value: '33/LL3',
  },
  {
    label: '32 / LL2',
    value: '32/LL2',
  },
  {
    label: '31 / LL1',
    value: '31/LL1',
  },
  {
    label: '41 / LR1',
    value: '41/LR1',
  },
  {
    label: '42 / LR2',
    value: '42/LR2',
  },
  {
    label: '43 / LR3',
    value: '43/LR3',
  },
  {
    label: '44 / LR4',
    value: '44/LR4',
  },
  {
    label: '45 / LR5',
    value: '45/LR5',
  },
  {
    label: '46 / LR6',
    value: '46/LR6',
  },
  {
    label: '47 / LR7',
    value: '47/LR7',
  },
  {
    label: '48 / LR8',
    value: '48/LR8',
  },
];

export const INIT_TABLE_PARAMS: ITableParams = { page: '1', perPage: '10', sort: '', order: '' };

export enum ECaseSubmissionStatus {
  DRAFT = 'DRAFT',
  SUBMITTED = 'SUBMITTED',
}

export enum ESubmissionStatus {
  ARCHIVED = 'ARCHIVED',
  DRAFT = 'DRAFT',
  AWAITING_FILES = 'AWAITING_FILES',
  SUBMITTED = 'SUBMITTED',
  AWAITING_DESIGNER_ACCEPTANCE = 'AWAITING_DESIGNER_ACCEPTANCE',
  AWAITING_STL_VERIFICATION = 'AWAITING_STL_VERIFICATION',
  AWAITING_SPECIALIST_ACCEPTANCE = 'AWAITING_SPECIALIST_ACCEPTANCE',
  AWAITING_INSTRUCTION = 'AWAITING_INSTRUCTION',
  ON_HOLD = 'ON_HOLD',
  NOT_SUITABLE_PENDING = 'NOT_SUITABLE_PENDING',
  AWAITING_DESIGN = 'AWAITING_DESIGN',
  AWAITING_ADVICE = 'AWAITING_ADVICE',
  REVIEW_DESIGN = 'REVIEW_DESIGN',
  AWAITING_DENTIST_APPROVAL = 'AWAITING_DENTIST_APPROVAL',
  APPROVED = 'APPROVED',
  REVIEW_STL_FILES = 'REVIEW_STL_FILES',
  WITH_MANUFACTURER = 'WITH_MANUFACTURER',
  WITH_32CO = 'WITH_32CO',
  IN_TREATMENT = 'IN_TREATMENT',
  COMPLETED = 'COMPLETED',
  NOT_SUITABLE = 'NOT_SUITABLE',
  EXPIRED = 'EXPIRED',
}

export enum EServiceType {
  DUO = 'DUO',
  SOLO = 'SOLO',
}

export enum ESubmissionPlanTypes {
  INITIAL = 'INITIAL',
  REFINEMENT = 'REFINEMENT',
}

export enum ESpecialistSubmissionStatuses {
  NEW_SUBMISSION = 'NEW_SUBMISSION',
  ON_HOLD = 'ON_HOLD',
  IN_PROGRESS = 'IN_PROGRESS',
  AWAITING_APPROVAL = 'AWAITING_APPROVAL',
  APPROVED = 'APPROVED',
  EXPIRED = 'EXPIRED',
  ARCHIVED = 'ARCHIVED',
  NOT_SUITABLE = 'NOT_SUITABLE',
  NOT_SUITABLE_PENDING = 'NOT_SUITABLE_PENDING',
  MODIFICATION_REQUESTED = 'MODIFICATION_REQUESTED',
}

export enum EDentistSubmissionStatuses {
  DRAFT = 'DRAFT',
  SUBMITTED = 'SUBMITTED',
  REVIEW_DESIGN = 'REVIEW_DESIGN',
  APPROVED = 'APPROVED',
  IN_TREATMENT = 'IN_TREATMENT',
  COMPLETED = 'COMPLETED',
  EXPIRED = 'EXPIRED',
  NOT_SUITABLE = 'NOT_SUITABLE',
  ARCHIVED = 'ARCHIVED',
}

export enum EDesignerSubmissionStatuses {
  PENDING_ACCEPTANCE = 'PENDING_ACCEPTANCE',
  VERIFY_STL_FILES = 'VERIFY_STL_FILES',
  AWAITING_INSTRUCTIONS = 'AWAITING_INSTRUCTIONS',
  SUBMIT_DESIGN = 'SUBMIT_DESIGN',
  AWAITING_DESIGN_APPROVAL = 'AWAITING_DESIGN_APPROVAL',
  REVISION_REQUESTED = 'REVISION_REQUESTED',
  SUBMIT_STL_FILES = 'SUBMIT_STL_FILES',
  COMPLETED = 'COMPLETED',
  ARCHIVED = 'ARCHIVED',
  EXPIRED = 'EXPIRED',
}

export enum ETreatmentDesignStatuses {
  AWAITING_DESIGN_APPROVAL = 'AWAITING_DESIGN_APPROVAL',
  APPROVED = 'APPROVED',
  REVISION_REQUESTED = 'REVISION_REQUESTED',
  NO_FURTHER_ACTION = 'NO_FURTHER_ACTION',
  REVIEW_DESIGN = 'REVIEW_DESIGN',
}

export enum EManufacturerSubmissionStatuses {
  NEW_ORDER = 'NEW_ORDER',
  IN_PROGRESS = 'IN_PROGRESS',
  SHIPPED = 'SHIPPED',
}

export enum EEducationContentAssignmentStatuses {
  ASSIGNED = 'ASSIGNED',
  VIEWED = 'VIEWED',
  COMPLETED = 'COMPLETED',
  BOOKMARKED = 'BOOKMARKED',
  ARCHIVED = 'ARCHIVED',
  UNARCHIVED = 'UNARCHIVED',
}

export enum ESkuStatuses {
  LIVE = 'LIVE',
  ARCHIVED = 'ARCHIVED',
}

export enum EDiscountStatus {
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
  USED = 'USED',
}

export enum EDiscountSchemes {
  REFERRAL = 'REFERRAL',
  GENERAL = 'GENERAL',
}

export enum EDiscountTypes {
  CASH = 'CASH',
  PERCENT = 'PERCENT',
}

export enum EDiscountValidityTypes {
  FIXED_NUMBER = 'FIXED_NUMBER',
  FIXED_PERIOD = 'FIXED_PERIOD',
  FOREVER = 'FOREVER',
}

export enum EPaymentStatus {
  PAID = 'PAID',
  UNPAID = 'UNPAID',
}

export enum ESTLDownloadStatues {
  NOT_STARTED = 'Not Started',
  PENDING = 'Pending',
  DOWNLOADING_STLS = 'Downloading STls',
  CREATING_ARCHIVE = 'Creating Archive',
  ARCHIVE_UPLOADING = 'Archive Uploading',
  COMPLETED = 'Completed',
  CANCEL = 'Cancel',
  ERROR = 'Error',
}
