import { buildQueryString } from 'services/utils';
import { api, VERSION } from 'services/api';
import {
  ICoAdminUsersListResponse,
  IUpdatePermissions,
  ICreateCoAdminRequest,
  IDeleteCoAdminRequest,
  ICoAdminUsersListRequest,
} from './coAdminTypes';
import { IUser } from 'services/auth/authTypes';

export const coAdminApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllCoAdmins: builder.query<ICoAdminUsersListResponse, ICoAdminUsersListRequest>({
      query: (params) => {
        const paramsString = buildQueryString(params);

        return `/${VERSION}/admin/coadmins?${paramsString}`;
      },
      providesTags: [{ type: 'RoleSettings', id: 'LIST' }],
    }),
    getCoAdminById: builder.query<IUser, { id: string }>({
      query: ({ id }) => `/${VERSION}/admin/coadmins/${id}`,
      providesTags: (_result, _error, params) => [{ type: 'RoleSettings', id: params.id }],
    }),
    updateCoAdminPermissions: builder.mutation<IUser, { id: string; payload: IUpdatePermissions }>({
      query({ id, payload }) {
        return {
          url: `/${VERSION}/admin/coadmins/${id}/permission`,
          method: 'PUT',
          body: payload,
        };
      },
      invalidatesTags: [{ type: 'RoleSettings', id: 'LIST' }],
    }),
    createCoAdmin: builder.mutation<any, ICreateCoAdminRequest>({
      query({ ...payload }) {
        return {
          url: `/${VERSION}/admin/coadmins`,
          method: 'POST',
          body: payload,
        };
      },
      invalidatesTags: [{ type: 'RoleSettings', id: 'LIST' }],
    }),
    resetPasswordCoAdmin: builder.mutation<any, { id: string; payload: { password: string } }>({
      query({ id, payload }) {
        return {
          url: `/${VERSION}/admin/coadmins/${id}/password/reset`,
          method: 'POST',
          body: payload,
        };
      },
      invalidatesTags: [{ type: 'RoleSettings', id: 'LIST' }],
    }),
    deleteCoAdmins: builder.mutation<any, IDeleteCoAdminRequest>({
      query({ ...payload }) {
        return {
          url: `/${VERSION}/admin/coadmins/delete`,
          method: 'DELETE',
          body: payload,
        };
      },
      invalidatesTags: [{ type: 'RoleSettings', id: 'LIST' }],
    }),
    updateCoAdminProfile: builder.mutation<void, { id: string; payload: Partial<ICreateCoAdminRequest> }>({
      query({ id, payload }) {
        return {
          url: `/${VERSION}/admin/coadmins/${id}/profile`,
          method: 'PUT',
          body: payload,
        };
      },
      invalidatesTags: (_result, _error, params) => [{ type: 'RoleSettings', id: params.id }],
    }),
    unlockCoAdminProfile: builder.mutation<void, { id: string }>({
      query({ id }) {
        return {
          url: `/${VERSION}/admin/coadmins/${id}/unblock`,
          method: 'PUT',
        };
      },
      invalidatesTags: (_result, _error, params) => [{ type: 'RoleSettings', id: params.id }],
    }),
    resetPasswordProfileCoAdmin: builder.mutation<
      void,
      { id: string; payload: { newPassword: string; currentPassword: string } }
    >({
      query({ id, payload }) {
        return {
          url: `/${VERSION}/admin/coadmins/${id}/profile/password`,
          method: 'PUT',
          body: payload,
        };
      },
    }),
  }),
});

export const {
  useGetAllCoAdminsQuery,
  useGetCoAdminByIdQuery,
  useUpdateCoAdminPermissionsMutation,
  useCreateCoAdminMutation,
  useResetPasswordCoAdminMutation,
  useDeleteCoAdminsMutation,
  useUpdateCoAdminProfileMutation,
  useUnlockCoAdminProfileMutation,
  useResetPasswordProfileCoAdminMutation,
} = coAdminApi;
