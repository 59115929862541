import moment, { Moment } from 'moment';
import { message } from 'antd';

export const DEFAULT_DATE_FORMAT = 'DD MMM YYYY';

export const checkURL = (url: string) => url?.match(/\.(jpeg|jpg|gif|png)$/) != null;

export const getBase64 = (img: any, callback: any) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
};

export const beforeFileUploadImg5mb = (file: any): boolean => {
  const supportedFileType = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg';
  if (!supportedFileType) {
    message.error('You can only upload JPG/PNG/JPEG file!', 5);
    return false;
  }

  const fileSize = file.size / 1024 / 1024 < 5;
  if (!fileSize) {
    message.error('Image must smaller than 5MB!', 5);
    return false;
  }
  return true;
};

export const beforeFileUploadImg = (file: any): boolean => {
  const supportedFileType = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg';
  if (!supportedFileType) {
    message.error('You can only upload JPG/PNG/JPEG file!', 5);
    return false;
  }

  return true;
};

export const formatDate = (date: string | undefined, fr: string = DEFAULT_DATE_FORMAT) => moment(date).format(fr);

export const formatToUtcDate = (date: string | undefined, fr: string = DEFAULT_DATE_FORMAT) =>
  moment(date).utc().format(fr);

export const searchParams = (url: string, key: string): string | null => {
  try {
    const params = new URL(url)?.searchParams;
    return params?.get(key);
  } catch {
    return '';
  }
};

export const getDateStringWithoutTime = (date: Moment | Date | string) => moment(date).toISOString().split('T')[0];

export const camelCaseToText = (text: string) => {
  const result = text.replace(/([A-Z])/g, ' $1');
  const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
  return finalResult;
};

export const duration = (date: string) => {
  const S = 1000;
  const M = 60 * S;
  const H = 60 * M;
  const D = 24 * H;
  const W = 7 * D;

  const d = new Date().valueOf() - new Date(date).valueOf();

  const weekdays = Math.floor(d / W);
  const days = Math.floor(d / D);
  const hours = Math.floor((d % D) / H);
  const minutes = Math.floor((d % H) / M);
  const seconds = Math.floor((d % M) / S);
  const milliseconds = d % S;

  return { weekdays, days, hours, minutes, seconds, milliseconds };
};

export function capitalize(s: string) {
  if (!s) return s;
  return s[0].toUpperCase() + s.slice(1);
}

export const validateFromDate = async (fromDate: string, toDate: string, initialValue: string | Moment) => {
  if (fromDate && toDate && moment(fromDate).isSameOrAfter(toDate)) {
    return Promise.reject(new Error('From Date must be less than or equal To Date'));
  }
  if (fromDate && !moment(fromDate).isSame(initialValue) && moment(fromDate).isBefore(moment().startOf('day'))) {
    return Promise.reject(new Error('From Date must be greater than or equal today'));
  }

  return Promise.resolve();
};

export const validateToDate = async (toDate: string, initialValue: string | Moment) => {
  if (toDate && !moment(toDate).isSame(initialValue) && moment(toDate).isSameOrBefore(moment().startOf('day'))) {
    return Promise.reject(new Error('From Date must be greater than or equal today'));
  }

  return Promise.resolve();
};

export const getAutoSavedDiffTime = (lastAutoSavedTime: string) => {
  const savedTime = moment(lastAutoSavedTime);
  if (savedTime.isSame(moment(), 'day')) return `today at ${savedTime.format('HH:mm')}`;
  // return moment.duration()
  const difference = moment().diff(savedTime);
  const durationDiff = moment.duration(Math.abs(difference));
  const days = durationDiff.days();
  return `${days < 1 ? 1 : days} ${days > 1 ? 'days' : 'day'} ago`;
};
